import util from '@dbiqe/glu-core/src/util';
import format from 'system/utilities/format';
import dateUtil from 'common/util/dateUtil';
import locale from '@glu/locale';
import operatorUtil from 'system/gridOverride/utilities/operators';
import filterTranslationUtil from 'common/util/viewFilterValueTranslation';
import constants from 'common/dynamicPages/api/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';

export default {

    CONFIG: {
        UNSUPPORTED_TYPES: [
            17,
            100,
        ],
    },

    /**
     * @param {array} filters - array of filter models
     * @return {array} filter param objects for saved view service
     */
    createFilterParams(filters) {
        return util.map(filters, filterModel => this.convertFilterModelToParam(filterModel));
    },

    processFilterDisplayFromParams(filterParams, filterFieldModels, functionCode) {
        /*
         * HACK: will need to do manual UI value translations first (temporary)
         * then create display data
         */
        return new Promise(((resolve) => {
            this.transformFilterParams(filterParams, filterFieldModels, functionCode)
                .then((filterData) => {
                    resolve(this.createFilterDisplayObjfromData(filterData, filterFieldModels));
                });
        }));
    },

    /**
     * @param {array} filterData - array of parsed filter param objects
     * @param {array} filterFieldModels - array of available filter fields
     *  corresponding to filter params
     * @return {array} filter data objects to show filters applied by saved view
     */
    createFilterDisplayObjfromData(filterData, filterFieldModels) {
        return util.map(filterData, (parsedFilter) => {
            const filterField = util.find(
                filterFieldModels,
                field => field.get('fieldName') === parsedFilter.fieldCode || field.get('searchField') === parsedFilter.fieldCode,
            );

            return this.convertFilterToDisplayObj(parsedFilter, filterField);
        });
    },

    /*
     * -------------------------------------- /
     * //  CREATING FILTER SERVICE PARAMS
     * /  -------------------------------------
     */

    /**
     * @param {Model} filterModel
     * @return {Object} Single Filter Param object
     */
    convertFilterModelToParam(filterModel) {
        const fieldCode = filterModel.get('field');
        const filterType = filterModel.get('type');
        const staticFilter = filterModel.get('staticFilter');
        const type = constants.FILTER_TYPES[filterType];
        let equality = filterModel.get('equality') || 'CONTAINS';
        const value = filterModel.get('value');
        const labelValue = filterModel.get('label');
        const overrideFieldCode = filterModel.get('overrideSearchField');

        if (filterModel.get('type') === 'typeahead') {
            equality = 'EQ';
        }
        /*
         * values need to be in this order when sent to service as a filter string
         *
         * (comment below was originally written by a dev on
         * why we need to pass in fieldCode twice)
         * HACK: wrapper FilterFields does not have
         * the passed in filter field in the list,
         * default to field passed in if not found in the list.
         */
        let paramValue;
        if (overrideFieldCode && overrideFieldCode !== fieldCode) {
            paramValue = util.flatten([
                overrideFieldCode, overrideFieldCode, type, equality, labelValue,
            ]);
        } else {
            paramValue = util.flatten([fieldCode, fieldCode, type, equality, value]);
        }
        paramValue = paramValue.join('^');

        return {
            paramName: 'Filters',
            paramValue,
            paramLabelValue: labelValue,
            staticFilter,
        };
    },

    parseFilterParams(filterParams) {
        return util.map(
            filterParams,
            filter => this.parseFilterParamString(
                filter.paramValue,
                filter.paramLabelValue,
                filter.staticFilter,
            ),
        );
    },

    /**
     * @param {string} filterParam
     * @param {string} filterParamLabelValue
     * @return {object} parsed filter Param data
     * Parses in filterParam string sent by service and returns as object with key-value pairs
     */
    parseFilterParamString(filterParam, filterParamLabelValue, staticFilter) {
        const [fieldCode, fieldName, typeVal, equality, ...rest] = filterParam.split('^');
        // filters can have multiple values (CIBC) or not, so default to an array
        let primary;
        let secondaryValue;
        // IN and NOTIN use a different format for the values
        if (['IN', 'NOTIN'].includes(equality)) {
            primary = rest;
        } else {
            [primary, secondaryValue] = rest;
            primary = (primary.indexOf(',') !== -1) ? primary.split(',').map(a => a.trim()) : [primary];
        }
        // typeVal is returned as a number, a number can correlate to multiple types
        const typeMatches = this.mapTypeValtoKey(parseInt(typeVal, 10));
        return {
            fieldCode: fieldCode.toUpperCase(),
            fieldName,
            type: (typeMatches.length === 1)
                || this.shouldBeOfTypeEnum(fieldCode, fieldName) ? typeMatches[0] : typeVal,
            equality,
            primaryValues: primary,
            secondaryValue,
            labelValue: filterParamLabelValue,
            staticFilter,
        };
    },

    /**
     * Determine any special CSS class to be applied to the field label
     * @param {string} fieldCode - field id
     * @return {string} CSS class to be applied to field label
     */
    getFieldLabelClass(fieldCode) {
        let labelClass;
        switch (fieldCode) {
        case 'TRANCODECLASS':
        case 'SITEUSERID':
            labelClass = 'label-uppercase';
            break;
        case 'SITEURL':
            labelClass = 'label-lowercase';
            break;
        case 'ACCOUNTFILTER':
        case 'ACCOUNTNUMBER':
        case 'ACCOUNT_NUMBER':
        case 'ACCOUNT_NUM':
        case 'ACCOUNTNUM':
        case 'ACCOUNT_NUMBER_DISP':
        case 'ACCOUNTNUMBER_DISP':
        case 'CMB_DEBIT_ACCOUNT_NUMBER':
        case 'CMB_BENE_ACCOUNT':
        case 'CMB_ACCOUNT_PREFERENCE':
        case 'CREDITOR_ACCOUNT':
        case 'DEBIT_ACCOUNT_NUMBER':
        case 'USERGROUP':
            labelClass = '';
            break;
        default:
            labelClass = 'ViewFilter-label';
        }
        return labelClass;
    },

    /*
     * ------------------------------------------ /
     * //  CONVERTING FILTER PARAMS to DISPLAY OBJ
     * /  -----------------------------------------
     */

    /**
     * @name shouldDisplayLabel
     * @description determines if the filter field's value or label should be displayed
     * @param {Model} filterField
     */
    shouldDisplayLabel(filterField) {
        return filterField.get('type') === 'typeahead' || filterField.get('type') === 'masked';
    },

    /**
     * @name getMaskedValue
     * @description returns the masked value
     * @param {object} data
     */
    getMaskedValue(data) {
        const value = data.primaryValues[0];
        return constants.MASKEDEXCLUDEVALUES.includes(value.toUpperCase())
            ? value : maskValue(data.primaryValues[0], getMaskingProperties());
    },

    /**
     * @param {object} parameterData
     * @param {Model} filterField
     * Takes in parsed filter data and the corresponding filterField's model to create
     * an object that contains content needed to display the filters to the user
     */
    convertFilterToDisplayObj(parameterData, filterField) {
        const paramData = { ...parameterData };
        const isMasked = filterField && filterField.get('type') === 'masked';
        if (isMasked) {
            paramData.labelValue = this.getMaskedValue(paramData);
        }
        const label = filterField
            && this.shouldDisplayLabel(filterField)
            && paramData.labelValue !== undefined
            ? paramData.labelValue
            : this.formattoCamelCase(this.createDefaultFilterLabel(paramData));

        const self = this;

        const { equality } = paramData;
        const fieldCode = filterField &&
            filterField.get('overrideSearchField') === 'BANKNAME' &&
            equality === 'EQ'
            ? filterField.get('searchField')
            : paramData.fieldCode;

        // create label
        const displayObj = {
            title: this.formattoCamelCase((filterField && !paramData.overrideFieldName)
                ? filterField.get('title')
                : this.getLocaleforFieldName(paramData.overrideFieldName
                    || paramData.fieldName)),
            fieldCode,
            label,
            labelClass: self.getFieldLabelClass(fieldCode),
            staticFilter: parameterData.staticFilter,
        };

        /*
         * type check prioritizes the filterfield models
         * value over the paramData for more specificity
         */
        let typeCheck = (filterField) ? filterField.get('type') : paramData.type;
        // schedule /dates
        typeCheck = (filterField === undefined && fieldCode === 'SCHEDULED') ? 'date' : typeCheck;
        // account numbers
        const typeaheadFields = ['ACCOUNTFILTER', 'CUST_REF', 'DESCRIPTION'];
        typeCheck = typeaheadFields.includes(fieldCode) ? 'typeahead' : typeCheck;
        // amount
        typeCheck = fieldCode === 'AMOUNT' ? 'amount' : typeCheck;
        typeCheck = fieldCode === 'ACCTPOSPAYTYPE' ? 'enum' : typeCheck;
        // label text for NOTIN when required
        let notInText = '';

        // parse values based on the filterfiled type
        switch (typeCheck) {
        case 'date':
        case 'gmtdate':
            displayObj.label = this.createFilterLabelforDate(paramData);
            break;

        case 'typeahead':
            if (fieldCode === 'STATUS' && equality === 'NOTIN') {
                notInText = locale.get('common.isnot');
            }
            if (!['ACCOUNT_NUMBER', 'ACCOUNTNUMBER', 'ACCOUNT_NUM'].includes(fieldCode)) {
                displayObj.label = `${notInText} ${paramData.primaryValues.join(', ')}`;
            }
            break;

        case 'enum':
            displayObj.label = this.formattoCamelCase(this.createFilterLabelforEnum(
                paramData,
                filterField,
            ));
            break;

        case 'amount':
            displayObj.label = this.createFilterLabelforAmount(paramData);
            break;
            // boolean types appear as 1 or 0
        case 1:
        case 0:
        case '1':
        case '0':
            displayObj.label = this.createFilterLabelForBoolean(paramData);
            break;
        default:
            break;
        }
        return displayObj;
    },

    formattoCamelCase(w) {
        /*
         * Certain words need to be considered as not changing case (e.g. SFTP)
         */
        const wordsToSkip = ['SFTP', 'ABA'];
        let temp = w.split(' ');
        temp = temp.map((part) => {
            if (wordsToSkip.includes(part)) {
                return part;
            }
            const partTemp = part.toLowerCase().trim();
            return partTemp.substr(0, 1).toUpperCase() + partTemp.substr(1);
        });
        return temp.join(' ');
    },

    /**
     * @param {string} fieldName
     * @return {string}
     * For filters that we don't use on the UI, the service may return
     * its own label or will return
     * a locale code as the Field name.
     * Will the locale for a fieldname if it exists,
     * otherwise will return back the fieldName
     */
    getLocaleforFieldName(fieldName) {
        return locale.getDefault(fieldName, fieldName);
    },

    /**
     * @param {number} typeVal
     * @return {array} type Name matches
     * Attempt to map a typeVal to an existing set of type enums
     * NOTE: Since the data is configured in a way where multiple
     * type enums can have the same val,
     * an array of matches is returned (will need to discuss)
     */
    mapTypeValtoKey(typeVal) {
        return util.filter(
            Object.keys(constants.FILTER_TYPES),
            typeKey => constants.FILTER_TYPES[typeKey] === typeVal,
        );
    },

    /**
     * @param {object} paramData
     * @return {string} filter label
     * Creates a filter's display label for most general cases
     */
    createDefaultFilterLabel(paramData) {
        const operator = operatorUtil.convert4Label(paramData.equality);
        // eslint-disable-next-line prefer-destructuring
        const primaryValue = paramData.primaryValues;
        // eslint-disable-next-line prefer-destructuring
        const secondaryValue = paramData.secondaryValue;

        // if a secondary value is given (usually for BETWEEN CASES)
        if (secondaryValue) {
            return `${operator} ${primaryValue[0]} - ${secondaryValue}`;
        }
        return `${operator} ${primaryValue.join(', ')}`;
    },

    /**
     * @param {object} paramData
     * @return {string} filter label
     * Creates a filter's display label for date filters
     */
    createFilterLabelforDate(paramData) {
        const operator = operatorUtil.convert4Label(paramData.equality);
        // eslint-disable-next-line prefer-destructuring
        const equality = paramData.equality;
        // eslint-disable-next-line prefer-destructuring
        const primaryValue = paramData.primaryValues;
        // eslint-disable-next-line prefer-destructuring
        const secondaryValue = paramData.secondaryValue;

        let label;

        const dateRangeCode = util.chain(primaryValue)
            .map(val => dateUtil.getDateCodes()[val] || val)
            .compact()
            .value();

        if (dateRangeCode.length && paramData.primaryValues
            && paramData.primaryValues.includes(secondaryValue)) {
            // eslint-disable-next-line prefer-destructuring
            label = dateRangeCode.join(', '); // [0];
            // Check for future dates to show operator
            if (equality === 'GTEQ') {
                label = `${operator} ${label}`;
            }
        } else if (equality === 'BETWEEN') {
            if (dateRangeCode.length) {
                label = `${operator} ${dateRangeCode[0]} - ${dateUtil.getDateCodes()[secondaryValue]
                    || secondaryValue}`;
            } else {
                label = `${operator} ${primaryValue[0]} - ${secondaryValue}`;
            }
        } else if (dateRangeCode.length) {
            if (paramData.fieldCode === 'LAST_ACTION_TIME') {
                ([label] = dateRangeCode);
            } else {
                label = `${operator} ${dateRangeCode[0]}`;
            }
        } else {
            label = `${operator} ${primaryValue.join(', ')}`;
        }

        return label;
    },

    /**
     * @param {object} paramData
     * @param {Model} filterField
     * @return {string} filter label
     * Creates a filter's display label for enum filters
     */
    createFilterLabelforEnum(paramData, filterField) {
        const operator = operatorUtil.convert4Label(paramData.equality);
        let primaryValue = paramData.primaryValues;

        /*
         * search through enum Collection to find a label for each value
         * (default to val if enum is not found)
         */
        if (filterField && filterField.get('serverSideEnum')) {
            const serverEnums = filterField.get('enumHash').models;
            const enumValue = util.map(primaryValue, (val) => {
                const enumModel = util.findWhere(serverEnums, {
                    id: val,
                });
                return (enumModel) ? enumModel.get('label') : val;
            });

            primaryValue = enumValue || primaryValue;
        }

        return `${operator} ${primaryValue.join(', ')}`;
    },

    /**
     * @param {object} paramData
     * @return {string} filter label
     * Creates a filter's display label for amount filters
     */
    createFilterLabelforAmount(paramData) {
        const operator = operatorUtil.convert4Label(paramData.equality);
        // eslint-disable-next-line prefer-destructuring
        const equality = paramData.equality;
        let primaryValue = paramData.primaryValues;
        // eslint-disable-next-line prefer-destructuring
        const secondaryValue = paramData.secondaryValue;

        /*
         * '0,0.00' is the default format used in the formatNumber utility function.
         * this addition is purely to allow extra decimals to appear for interest rates
         * while still leveraging the features of an "amount" filter.
         */
        const numberOfDecimals =
            paramData.fieldCode === 'EXCHANGE_RATE'
                ? 6
                : parseInt(
                    serverConfigParams.get('BAILoansInterestDecimalPlaces') ||
                    5,
                    10,
                );
        const numberFormat =
            paramData.fieldCode === 'INTERESTRATE' ||
                paramData.fieldCode === 'EXCHANGE_RATE'
                ? `0,0.${'0'.repeat(numberOfDecimals)}`
                : '0,0.00';

        primaryValue = util.map(primaryValue, val => format.formatNumber(val, numberFormat));

        if (equality === 'BETWEEN') {
            return `${operator} ${primaryValue[0]} - ${format.formatNumber(secondaryValue, numberFormat)}`;
        }

        return `${operator} ${primaryValue.join(', ')}`;
    },

    /**
     * Creates a filter's display label for boolean types
     * @param {object} paramData
     * @returns {string} filter label
     */
    createFilterLabelForBoolean(paramData) {
        if (paramData.fieldCode === 'ACTIVE') {
            return paramData.primaryValues.join(', ');
        }
        const operator = operatorUtil.convertToHumanReadable(paramData.equality);
        const value = paramData.primaryValues && paramData.primaryValues[0] === '1'
            ? locale.get('common.true') : locale.get('common.false');
        return `${operator} ${value}`;
    },

    /*
     * ------------------------------------------ /
     *  HACK for CIBC related work (NH-73403)
     *  Managing the Filter Fields
     *  (TEMPORARY SOLUTION TO SOLVE ALL OF THIS ON UI)
     * /  -----------------------------------------
     */

    /**
     * @param {number} type
     * @return {boolean}
     * Check if type of filter can be displayed or not
     */
    isFilterTypeSupported(type) {
        return this.CONFIG.UNSUPPORTED_TYPES.indexOf(parseInt(type, 10)) === -1;
    },

    /**
     * @param {object} item - filter item
     * @return {boolean}
     * Checks to filter items to see if the outlier Status filter
     * has only an 'MF' value or not (will not display filter at all if so)
     */
    isSupportedStatus(item) {
        return !(item.primaryValues.indexOf('MF') > -1 && item.primaryValues.length === 1);
    },

    /**
     * @param {string} functionCode
     * @param {array} filters
     * @return {array}
     * Removes unsupported filters that won't be shown, and returns an array without them
     */
    removeUnsupportedFilters(functionCode, filters) {
        return util.filter(filters, (filter) => {
            const isSupportedType = this.isFilterTypeSupported(filter.type);

            if (filter.fieldCode === 'PRODUCT' && filter?.primaryValues?.includes('CLM')) {
                return false;
            }
            if (filter.fieldCode === 'USERGROUP' && functionCode === 'REQUEST') {
                return false;
            }
            if (filter.fieldCode === 'CURRENCYCODE' && filter.primaryValues.join() === 'X') {
                return false;
            }
            if (filter.fieldCode === 'REPORT_FLOAT') {
                return false;
            }
            if (filter.fieldCode !== 'STATUS') {
                return isSupportedType;
            }
            return isSupportedType && this.isSupportedStatus(filter);
        });
    },

    /**
     * @param {array} availableFilterFields
     * @returns {string}
     * Attempts to get the Status filter field idientifier
     * for the current view out of its
     * available filters for the user.
     * This is done because of the inconsistency of status
     * filters among different view
     * All status filters apply the same filter but have
     * different identifiers depending on the view
     */
    getCommonStatusFieldIdentifier(availableFilterFields) {
        const availableStatusFilter = util.find(availableFilterFields, filterField => filterField.get('field').indexOf('STATUS') !== -1);
        return (availableStatusFilter) ? availableStatusFilter.get('fieldName') : 'STATUS_DESCRIPTION';
    },

    /**
     * @param {array} filters
     * @param {string} commonStatusIdentifier
     * Will merge the outlier 'Status' filter with the common DGB 'STATUS_DESCRIPTION' filter
     * since they represent the same filter, and return the filters array with the new merged
     * statuses.
     */
    mergeStatusFilters(filters, commonStatusIdentifier) {
        const statusFilter = util.findWhere(filters, {
            fieldCode: 'STATUS',
        });
        // Keep track of the status badge position
        const statusFilterIndex = filters.indexOf(statusFilter);
        const commonStatusFilter = util.findWhere(filters, {
            fieldCode: commonStatusIdentifier,
        });

        /**
         * Make sure that the different value of equality are honored for filters
         */
        // eslint-disable-next-line max-len
        if (commonStatusFilter && statusFilter.equality !== commonStatusFilter.equality) {
            statusFilter.primaryValues[0] = this.createDefaultFilterLabel(statusFilter);
        }

        const mergedStatus = util.extend(commonStatusFilter || statusFilter, {
            fieldCode: commonStatusIdentifier,
            primaryValues: util.union(
                statusFilter.primaryValues,
                (commonStatusFilter) ? commonStatusFilter.primaryValues : [],
            ),
        });

        const newFilters = util.without(filters, statusFilter, commonStatusFilter);
        newFilters.splice(statusFilterIndex, 0, mergedStatus);
        return newFilters;
    },

    /**
     * HACK
     * @param {array} filters
     * @param {array} filterFieldModels
     * @param {string} functionCode
     * @return {Promise}
     * Will hard translate filter values not from DGB
     * to be displayed with proper text
     * May require fetching a collection of reference values
     * if the outlier 'Status' filter is available
     */
    preTranslateFilterValues(filterFieldModels, functionCode, filters) {
        // Check for Status code and fetch the data if needed.
        const hasStatusCodes = util.some(filters, item => item.fieldCode === 'STATUS');
        const hasMessageState = util.some(filters, item => item.fieldCode === 'MESSAGE_STATE');
        if (hasMessageState) {
            return filterTranslationUtil
                .fetchStatusCodes(constants.TRANSLATION_INQUIRY_ID.messages)
                .then(refCollection => Promise.resolve(filterTranslationUtil
                    .hardTranslateFilters(filters, refCollection, functionCode)));
        }
        if (hasStatusCodes) {
            return filterTranslationUtil
                .fetchStatusCodes(constants.TRANSLATION_INQUIRY_ID.status)
                .then((refCollection) => {
                    // hard map non-ui filters
                    let translatedData = filterTranslationUtil
                        .hardTranslateFilters(filters, refCollection, functionCode);
                    const commonStatusIdentifier = (
                        this.getCommonStatusFieldIdentifier(filterFieldModels));

                    // consolidate discrepancy between STATUS filters
                    translatedData = this.mergeStatusFilters(
                        translatedData,
                        commonStatusIdentifier,
                    );
                    // return pretranslated values (handling non-ui filters)
                    return translatedData;
                });
        }
        return Promise.resolve(filterTranslationUtil
            .hardTranslateFilters(filters, null, functionCode));
    },

    /**
     * @param {array} filterParams
     * @param {array} filterFieldModels
     * @param {string} functionCode
     * @return {array}
     * Takes in Filter Data from the service and performs multiple operations of data-massaging
     * and hard translation so that they can be displayed properly on the grid header
     */
    transformFilterParams(filterParams, filterFieldModels, functionCode) {
        const transformers = [
            this.parseFilterParams.bind(this),
            this.removeUnsupportedFilters.bind(this, functionCode),
            this.preTranslateFilterValues.bind(this, filterFieldModels, functionCode),
        ];

        return util.reduce(
            transformers,
            (acc, transform) => acc.then(data => transform(data)),
            Promise.resolve(filterParams),
        );
    },

    /**
     * Use this method if the type of fieldCode and fieldName combination is enum
     * @param {string} fieldCode
     * @param {string} fieldName
     * @return {boolean}
     */
    shouldBeOfTypeEnum(fieldCode, fieldName) {
        const enumTypeValues = [{
            fieldCode: 'PRODUCT',
            fieldName: 'Product Code',
        },
        {
            fieldCode: 'CURRENCY_CODE',
            fieldName: 'CURRENCY_CODE',
        }];
        return !!util.findWhere(enumTypeValues, { fieldCode, fieldName });
    },

    /**
     * Use this method to remove any redundant filters
     * @param {array} filters
     * @return {array} redundant free filters
     */
    removeRedundantFilters(filters) {
        const redundantPairs = [
            /**
             * Deals with account filter and account number. Account number being sent
             * from the grid and the filter api is returning account filter instead
             */
            ['ACCOUNTFILTER', 'ACCOUNTNUMBER'],
        ];
        let updatedFilters = filters;
        redundantPairs.forEach((pair) => {
            const redundant1 = util.findWhere(filters, { fieldCode: pair[0] });
            const redundant2 = util.findWhere(filters, { fieldCode: pair[1] });
            if (redundant1 && redundant2) {
                redundant1.label = redundant2.label;
                redundant1.title = redundant2.title;
                updatedFilters = updatedFilters.reduce((acc, i) => {
                    let filter = i;
                    if (filter.fieldCode === pair[0]) {
                        filter = redundant1;
                    }
                    if (filter.fieldCode === pair[1]) {
                        return acc;
                    }
                    acc.push(filter);
                    return acc;
                }, []);
            }
        });
        return updatedFilters;
    },
};
