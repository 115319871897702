{{#unless hide}}
    {{#unless noData}}
        {{#unless notVisibleItem}}
            {{#if showGroups}}
                <li class="divider"></li>
                {{#if name}}
                    <li id="{{id}}-{{cid}}" class="{{#if child}}group-header {{else}}selectable{{/if}} js-group-header {{#if selected}}on selected{{/if}}" role="menuitem">
                        {{#unless child}}
                            {{#if multiSelect}}
                                <input type="checkbox" value="{{[id]}}" {{#if ../selected}}checked{{/if}} aria-label="{{name}}"/>
                            {{/if}}
                        {{/unless}}
                        <a href="#" data-value="{{id}}" class="{{#unless multiSelect}}single-selection{{/unless}}"><span {{#if highlight}}class="highlight"{{/if}}>{{name}}</span></a>
                    </li>
                {{/if}}
                {{#each child}}
                    <li id="{{id}}-{{../cid}}" data-value="{{id}}" class="selectable {{#if selected}}on selected {{/if}}" role="menuitem">
                        {{#if ../multiSelect}}
                            <input type="checkbox" value="{{id}}" {{#if ../selected}}checked{{/if}} aria-label="{{name}}"/>
                        {{/if}}
                        <a href="#" data-value="{{id}}" class="{{#unless ../multiSelect}}single-selection{{/unless}}"><span {{#if highlight}}class="highlight"{{/if}}>{{name}}</span></a>
                    </li>
                {{/each}}
            {{else}}
                {{#if multiSelect}}
                    {{#unless tableView}}
                        <input type="checkbox" value="{{id}}" {{#if selected}}checked{{/if}} aria-label="{{name}}"/>
                    {{else}}
                        <td><input type="checkbox" value="{{id}}" {{#if selected}}checked{{/if}} aria-label="{{name}}"/></td>
                    {{/unless}}
                {{/if}}
                {{#if tableView}}
                    {{#each name}}
                        <td class="data-cell">{{this}}</td>
                    {{/each}}
                {{else}}
                    <a href="#" data-value="{{id}}" {{#unless multiSelect}}class="single-selection"{{/unless}}><span {{#if highlight}}class="highlight"{{/if}}>{{name}}</span></a>
                {{/if}}
            {{/if}}
        {{/unless}}
    {{/unless}}
{{/unless}}
