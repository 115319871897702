import { actionTypes } from './actions';

export default (state = {}, action) => {
    switch (action.type) {
    case actionTypes.SAVE_PAGE_STATE:
        return {
            ...state,
            [action.payload.pageKey]: { ...action.payload.state },
        };
    case actionTypes.RESET_PAGE_STATE: {
        const newState = { ...state };
        delete newState[action.payload.pageKey];
        return {
            ...newState,
        };
    }
    default:
        return state;
    }
};
