import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';
import { appendScript } from './util';

const TagManager = {
    login_complete: false,
    preAuth() {
        const account = serverConfigParams.get('TealiumAccount');
        const profile = serverConfigParams.get('TealiumProfile');
        const environment = serverConfigParams.get('TealiumEnvironment');

        if (!account || !profile || !environment) {
            return;
        }
        appendScript(
            `//tags.tiqcdn.com/utag/${account}/${profile}/${environment}/utag.js`,
            {
                async: true,
                onLoad: () => {
                    /*
                     * Since this is an SPA and view events are disabled in Tealium,
                     * trigger the first view manually without any data, ie. user data
                     */
                    window.utag?.view?.();
                },
            },
        );
    },

    postAuth() {
        window.utag?.view?.({
            market_segment: userInfo.get('marketSegment'),
            name: userInfo.get('name'),
            permanent_user_id: userInfo.get('permanentUserId'),
            tealium_event: 'user_login',
            tenant_id: userInfo.get('tenantId'),
            user_group: userInfo.get('group'),
            user_id: userInfo.get('id'),
            user_token: userInfo.get('token'),
        });
        TagManager.login_complete = true;
    },

    routeChange() {
        if (!TagManager.login_complete) {
            return;
        }
        window.utag?.view?.({
            market_segment: userInfo.get('marketSegment'),
            name: userInfo.get('name'),
            permanent_user_id: userInfo.get('permanentUserId'),
            tenant_id: userInfo.get('tenantId'),
            user_group: userInfo.get('group'),
            user_id: userInfo.get('id'),
        });
    },
};

export default TagManager;
