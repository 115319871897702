export default {
// Blues
'surfiGreen':                                  '#117788',
'blueStone':                                   '#006666',

'blueLagoon':                                  '#007C8C',
'astronautBlue':                               '#004964',
'surfGreen':                                   '#107788',
'blackSqueeze':                                '#ebf4f8',

// Greens
'coconutCream':                                '#f0f8d5',
'sprout':                                      '#cadcad',
'primrose':                                    '#def0a8',
'sushi':                                       '#88bd40',
'oliveDrab':                                   '#66a326',
'drabOlive':                                   '#4c7a1c',

'bilbao':                                      '#417514',
'verdunGreen':                                 '#1D4900',
'surfieGreen':                                 '#107788',

// Oranges
'jaffa':                                       '#f0853e',
'orangeRoughy':                                '#c65b14',
'fire':                                        '#b34801',
'paarl':                                       '#965327',

// Reds
'cardinal':                                    '#CC2233',

// Grays
'wildSand':                                    '#F5F5F5',
'gray':                                        '#8C8C8C',
'doveGray':                                    '#6F6F6F',
'mercury':                                     '#E8E8E8',
'birdGray':                                    '#707070',
'dustGray':                                    '#979797',
'boulder':                                     '#767676',
'mineShaft':                                   '#333333',
'gallery':                                     '#EDEDED',
'white':                                       '#FFFFFF',
};