<section class="source-section">

    <h3 class="source-section-header">
        <span class="source-section-header-text">{{sourceListHeader}}</span>
        <button class="btn btn-tertiary source-list-search-filter-toggle" id="source-list-search-filter-toggle-{{cid}}">
            {{icon "search" filter}}
        </button>
        <label class="sr-only" for="source-list-search-filter-{{cid}}">{{filter}}</label>
        <input type="text" size="20" id="source-list-search-filter-{{cid}}" class="form-control source-list-search-filter">
    </h3>

    <fieldset>
        <legend class="sr-only">List of Selected Items</legend>
        <div data-region="sourceListRegion"></div>
    </fieldset>

    <div class="source-list-controls">
        {{icon action="move-selected-items" name="logout" text=moveSelectedItems textVisible=true className="btn btn-tertiary" }}
        {{icon action="move-all-items" name="logout" text=moveAllItems textVisible=true className="btn btn-tertiary" }}
        {{icon action="clear-source-selection" name="remove-columns" text=clearSourceSelection textVisible=true className="btn btn-tertiary" }}
    </div>
</section>

<section class="destination-section target-section">
    <h3 class="target-section-header target-section-header-text">{{targetListHeader}}</h3>

    <fieldset class="no-bottom-padding">
        <legend class="sr-only">List of Checkboxes</legend>
        <div data-region="targetListRegion"></div>
    </fieldset>

    <div class="target-list-controls">
        <div class="source-list-controls">
            {{icon action="remove-selected-items" name="close" text=removeSelectedItems textVisible=true className="btn btn-tertiary" }}
            {{icon action="remove-all-items" name="close" text=removeAllItems textVisible=true className="btn btn-tertiary" }}
            {{icon action="clear-target-selection" name="remove-columns" text=clearTargetSelection textVisible=true className="btn btn-tertiary" }}
        </div>
    </div>
</section>
