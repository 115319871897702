import Model from '@dbiqe/glu-core/src/model';

const CacheModel = Model.extend({
    remove(key, options) {
        let localKey = key;
        if (options && Object.prototype.hasOwnProperty.call(options, 'context')) {
            localKey = this.makeKeyFromContext(localKey, options.context);
        }

        if (this.has(localKey)) {
            const value = this.get(localKey);
            this.unset(localKey);
            return value;
        }
        return null;
    },

    makeKeyFromContext(key, contextObject) {
        return (`${contextObject.productCode}-${contextObject.functionCode}.${key}`);
    },
});

const CacheModelInstance = new CacheModel();
export default CacheModelInstance;
