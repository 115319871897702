var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " range-input ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression, alias6=container.lambda;

  return "<form class=\"form-inline amount-filter\">\n    <div class=\"search-input-wrapper\">\n        <div class=\"region-field-equality\"></div>\n    </div>\n    <div class=\"search-input-wrapper search-operand-container "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.isOpBetween : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <div class=\"search-operand\">\n            <input type=\"text\"\n                   id=\"field-value-"
    + alias5(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                   name=\"field-value\"\n                   value=\""
    + alias5(alias6(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1["0"] : stack1), depth0))
    + "\"\n                   data-bind=\"model\"\n                   class=\"form-control\"\n                   data-hook=\"getAmountInputField\"\n            >\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"field-value\"></span>\n        </div>\n        <div class=\"search-operand\">\n            <input type=\"text\" id=\"field-value2-"
    + alias5(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\" name=\"field-value2\" data-bind=\"model\" class=\"form-control\"\n                   value=\""
    + alias5(alias6(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1["1"] : stack1), depth0))
    + "\" placeholder=\""
    + alias5(helpers.locale.call(alias2,"filter.between.end",{"name":"locale","hash":{},"data":data}))
    + "\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"field-value2\"></span>\n        </div>\n        <button type=\"button\" class=\"close\"  data-control=\"removeFilter\"><span class=\"icon-close\"></span></button>\n    </div>\n    <button type=\"submit\" name=\"submit\" class=\"btn btn-secondary\" disabled>"
    + alias5(helpers.locale.call(alias2,"apply",{"name":"locale","hash":{},"data":data}))
    + "</button>\n    <button type=\"button\" name=\"clear\" class=\"btn btn-tertiary btn-clear-filter hidden\">"
    + alias5(helpers.locale.call(alias2,"clear",{"name":"locale","hash":{},"data":data}))
    + "</button>\n</form>\n";
},"useData":true});