import { request, generateUrl } from '@dbiqe/common';
import { getState, setPremiumAchNewVendorProddingFlag, setPremiumAchPreferences } from 'common/util/reduxUtil';
import systemConfig from 'system/configuration';

import { getVendors } from 'components/VendorAssignmentPremiumACH/services';

const TYPECODES = {
    CASH_CONCENTRATION_DISBURSEMENT: 'BDACHCCD',
    CASH_DISBURSEMENT: 'BDACHCD',
    CORPORATE_VENDOR_PAYMENT: 'BDACHCVP',
    NACHA_FILE_IMPORT_CASH_CONCENTRATION_DISBURSEMENT: 'BDNFICCD',
    PREMIUM_ACH: 'BDACHPRM',
};

const setPreferences = (name, value) => {
    setPremiumAchPreferences({
        [name]: value,
    });
};
const getPreferences = (name) => {
    const { premiumAchPreferences } = getState();
    return premiumAchPreferences[name];
};
const premiumAchEntitlementRequest = functionCode => (request.post(
    generateUrl('/accessService/hasAccess'),
    {
        actionMode: 'SELECT',
        functionCode,
        productCode: 'USACH',
        typeCode: TYPECODES.PREMIUM_ACH,
    },
));
const loadPremiumAchEntitlements = () => {
    const vendorsAbortController = new AbortController();
    const isAdmin = systemConfig.isAdmin();
    const entitlementsPromise = Promise.all([
        premiumAchEntitlementRequest('BATCH'),
        premiumAchEntitlementRequest('BHTMPL'),
        isAdmin ? Promise.resolve() : getVendors({ signal: vendorsAbortController.signal }),
    ]);
    entitlementsPromise.then(([paymentResponse, templateResponse, vendors = []]) => {
        const isEntitled = paymentResponse?.data || templateResponse?.data;
        setPreferences('isPremiumAchEntitled', isEntitled);
        if (!isEntitled || !vendors.length) {
            setPreferences('hasCompletedEnrollment', false);
            return;
        }
        /*
         * There is currently not an API to understand if a company has completed enrollment.
         * We can go off of these rules which confirm that they have:
         * 1. The company is entitled for Premium ACH
         * 2. There is at least 1 active vendor
         * Placed this in the initial request for entitlements to keep traffic down to a minimum.
         */
        const hasActiveVendors = vendors.some(({ status }) => status === 'ACTIVE');
        setPreferences('hasCompletedEnrollment', hasActiveVendors);
    }).catch((e) => {
        console.log('Error Fetching Premium ACH entitlements', e);
    });
};
const entitlementsForACHPaymentsRequest = typeCode => (request.post(
    generateUrl('/accessService/hasAccess'),
    {
        actionMode: 'SELECT',
        functionCode: 'BATCH',
        productCode: 'USACH',
        typeCode,
    },
));
const loadUserEntitlementsForACHPaymentTypes = () => {
    const entitlementsPromise = Promise.all([
        entitlementsForACHPaymentsRequest(TYPECODES.CASH_CONCENTRATION_DISBURSEMENT),
        entitlementsForACHPaymentsRequest(TYPECODES.CASH_DISBURSEMENT),
        entitlementsForACHPaymentsRequest(TYPECODES.CORPORATE_VENDOR_PAYMENT),
        // eslint-disable-next-line max-len
        entitlementsForACHPaymentsRequest(TYPECODES.NACHA_FILE_IMPORT_CASH_CONCENTRATION_DISBURSEMENT),
    ]);
    entitlementsPromise.then((resp) => {
        setPreferences('isUserEntitledForACHPaymentTypes', resp[0]?.data || resp[1]?.data || resp[2]?.data || resp[3]?.data);
    }).catch((e) => {
        console.log('Error Fetching user entitlements for ACH payments', e);
    });
};
const isCompanyEligibleForPremiumACH = () => {
    const premiumACHEligibilityPromise = request.get(generateUrl('/premiumACH/customerVendorOpportunity'));
    premiumACHEligibilityPromise.then((resp) => {
        setPreferences('isEligibleForPremiumACH', !!resp.data?.vendors?.length);
    }).catch((e) => {
        console.log('Error Fetching customer vendor opportunity', e);
    });
};
const hasNewPremiumAchVendors = () => {
    const payload = { userAction: 'LOGON' };
    return request.post(generateUrl('premiumach/vendor/getLatest'), payload)
        .then(({ data: { proddingNeeded } }) => setPremiumAchNewVendorProddingFlag(proddingNeeded))
        .catch(console.log);
};

export default {
    setPremiumAchPreferences: setPreferences,
    getPremiumAchPreferences: getPreferences,
    hasNewPremiumAchVendors,
    isCompanyEligibleForPremiumACH,
    loadPremiumAchEntitlements,
    loadUserEntitlementsForACHPaymentTypes,
};
