//import palette from '../palette';

export default {
    components: {
        header: {
            header: {
                background: '#fff',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }
        }
    }
};