var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " data-bind=\"model\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return " autocomplete=\"off\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return " name=\""
    + container.escapeExpression(((helper = (helper = helpers.nameAttr || (depth0 != null ? depth0.nameAttr : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nameAttr","hash":{},"data":data}) : helper)))
    + "\"\n    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<label for=\""
    + alias5(((helper = (helper = helpers.dateElemId || (depth0 != null ? depth0.dateElemId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"dateElemId","hash":{},"data":data}) : helper)))
    + "\" class=\"datepicker-label\">"
    + alias5(((helper = (helper = helpers.labelText || (depth0 != null ? depth0.labelText : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"labelText","hash":{},"data":data}) : helper)))
    + "</label>\n<input type=\"text\" size=\"20\" "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.modelBind : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " class=\""
    + alias5(((helper = (helper = helpers.dateElemClassName || (depth0 != null ? depth0.dateElemClassName : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"dateElemClassName","hash":{},"data":data}) : helper)))
    + "\"\n    id=\""
    + alias5(((helper = (helper = helpers.dateElemId || (depth0 != null ? depth0.dateElemId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"dateElemId","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers.unless.call(alias2,(depth0 != null ? depth0.autocomplete : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.nameAttr : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n<span class=\"ui-datepicker-trigger icon-calendar\" id=\""
    + alias5(((helper = (helper = helpers.dateElemIconId || (depth0 != null ? depth0.dateElemIconId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"dateElemIconId","hash":{},"data":data}) : helper)))
    + "\"></span>\n<div class=\"date-error help-block\"></div>\n";
},"useData":true});