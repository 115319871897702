import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { setStoreInstance } from '@dbiqe/common';
import getStore from './store';

export const reduxStore = getStore();
setStoreInstance(reduxStore); // this is needed for MFA

const StoreProvider = ({ children }) => (
    <Provider store={reduxStore}>
        {children}
    </Provider>
);

StoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default StoreProvider;
