import TableFooterLayout from 'no-override!@glu/grid/src/footer/tableFooterLayout';
import util from 'underscore';
import $ from 'jquery';

export default TableFooterLayout.extend({
    initialize() {
        TableFooterLayout.prototype.initialize.call(this);
        this.listenTo(this.grid, {
            'grid:contentRendered': () => {
                /*
                 * NH-179424 When scrolling modals, need to update the
                 * footer position of the grid inside, so that the footer
                 * does not remain stuck at the bottom of the modal.
                 */
                const $modalDialog = this.getModal('.modal-dialog');
                if ($modalDialog.length) {
                    $modalDialog.find('.modal-body').on('scroll', () => this.updateFooterPosition());
                }
                this.updateFooterPosition();
            },
            'grid:columnWidthUpdated': this.handleGridContentRendered,
            'grid:columnResizeComplete': () => {
                if (this.stickyScrollOptions?.visible) {
                    this.updateStickyScroll();
                }
            },
        });
    },

    /**
     * Find the closest modal, based on the modalSelector, to the tbody
     * @param {string} modalSelector
     * @returns
     */
    getModal(modalSelector) {
        const $tableBody = this.grid.$el.find('tbody');
        return $tableBody.closest(modalSelector);
    },

    /**
     * Replaces the call to prototype.updateFooterPosition
     *  to resolve the change to the output of $.fn.height()
     *  when there are no matching elements.
     */
    updateFooterPositionFixed() {
        if (this.isClosed || !this.hasStickyFooter) {
            return;
        }

        const $tableBody = this.grid.$('tbody[aria-live="polite"]');
        const $footer = this.$el;

        const footerHeight = $footer[0].offsetHeight;
        const { bottom, top } = $tableBody[0].getBoundingClientRect();
        const firstRowHeight = $tableBody.find('tr').height() || 0;
        const tableBottom = bottom + footerHeight;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        const tableOutsideViewport = top + footerHeight + firstRowHeight > windowHeight;
        const tableBottomInViewport = tableBottom < windowHeight;

        if (tableOutsideViewport || tableBottomInViewport) {
            $footer.removeClass('sticky-footer');
            this.grid.$el.css('padding-bottom', this.initialGridBottomPadding);
            this.hideStickyScroll();
            return;
        }

        this.updateStickyScroll();
        $footer.addClass('sticky-footer');
        this.grid.$el.css('padding-bottom', footerHeight);
    },

    /**
     * call the parent function (actually fixed method above for jQ 3+)
     * update sticky footer position in modals with scrolling content
     * @method updateFooterPosition
     */
    updateFooterPosition() {
        this.updateFooterPositionFixed();

        const $modalDialog = this.getModal('.modal-dialog');
        const $reactModalDialog = this.getModal('[class^="card-"]');
        const $stickyFooter = $modalDialog.length ? $modalDialog.find('.sticky-footer')
            : $reactModalDialog.find('.sticky-footer');
        const isInModal = $modalDialog.length || $reactModalDialog.length;

        if (!this.isClosed && this.hasStickyFooter && isInModal) {
            const modalFooterHeight = $modalDialog.length ?
                $modalDialog.find('.modal-footer').innerHeight()
                : $reactModalDialog.find('.footer').innerHeight();

            let bottom;
            if ($modalDialog.length) {
                const modalBottomOffset = window.innerHeight - ($modalDialog[0].offsetTop
                    + $modalDialog[0].offsetHeight);
                bottom = modalFooterHeight + modalBottomOffset;
            } else {
                // in a react modal, just account for controls area
                bottom = 85;
            }
            $stickyFooter.css({
                bottom,
            });
        }
    },

    addStickyScrollEvents() {
        util.extend(this.stickyScrollOptions, {
            eventHandlers: [{
                $el: this.$stickyScrollBar,
                handlers: {
                    'scroll.sticky': (e) => {
                        if (this.stickyScrollOptions?.visible) {
                            this.syncTableWithStickyScroll($(e.target));
                        }
                    },
                },
            }, {
                $el: this.stickyScrollOptions.$container,
                handlers: {
                    'scroll.sticky': (e) => {
                        this.syncStickyScrollBarWithTable($(e.target));
                    },
                    'focusin.sticky': () => {
                        util.defer(() => {
                            this.syncStickyScrollBarWithTable(this.stickyScrollOptions.$container);
                        });
                    },
                },
            }],
        });

        this.stickyScrollOptions.eventHandlers.forEach((item) => {
            Object.entries(item.handlers).forEach(([event, callback]) => {
                item.$el.on(`${event}.${this.grid.cid}`, callback);
            });
        });
    },
});
