import Controller from '@dbiqe/glu-core/src/controller';
import util from '@dbiqe/glu-core/src/util';

export default Controller.extend({
    mutationDelay: 1000,
    mutationDecay: 200,
    mutationMaxDelay: 10000,
    mutationMinDelay: 500,

    initialize(options) {
        this.mutationDelay = options.mutationDelay || this.mutationDelay;
        this.mutationDecay = options.mutationDecay || this.mutationDecay;
        this.mutationMaxDelay = options.mutationMaxDelay || this.mutationMaxDelay;

        this.options = util.extend({
            subtree: true,
            childList: true,
            attributes: true,
            attributeFilter: ['class'],
        }, options);

        this.mutationSupport = !(typeof window.MutationObserver === 'undefined');
    },

    observe(el) {
        this.el = el;
        if (this.mutationSupport) {
            this.bindMutationObserver();
        } else {
            this.mutationSnapshot = el.innerHTML;
            this.startMutationPoller();
        }
        this.disconnected = false;
    },

    disconnect() {
        if (this.disconnected) {
            return true;
        }
        if (this.mutationSupport) {
            this.mutationObserver.disconnect();
        } else {
            clearTimeout(this.mutationTimer);
        }
        this.disconnected = true;
        return false;
    },

    bindMutationObserver() {
        this.mutationObserver = new window.MutationObserver((mutations) => {
            this.trigger('change', this.el, mutations);
        });

        this.mutationObserver.observe(this.el, this.options);
    },

    startMutationPoller() {
        clearTimeout(this.mutationTimer);
        this.mutationTimer = setTimeout(() => {
            const html = this.el.innerHTML;
            if (this.disconnected) {
                return true;
            }
            if (this.mutationSnapshot !== html) {
                this.trigger('change', this.el);
                this.mutationDelay -= this.mutationDecay;
                if (this.mutationDelay < this.mutationMinDelay) {
                    this.mutationDelay = this.mutationMinDelay;
                }
            } else {
                this.mutationDelay += this.mutationDecay;
                if (this.mutationDelay > this.mutationMaxDelay) {
                    this.mutationDelay = this.mutationMaxDelay;
                }
            }
            this.mutationSnapshot = html;
            this.startMutationPoller();
            return false;
        }, this.mutationDelay);
    },

});
