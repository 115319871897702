import AmountSearch from 'no-override!@glu/grid/src/filter/amountSearch';
import FieldSearch from '@glu/grid/src/filter/fieldSearch';
import locale from '@glu/locale';
import format from '@dbiqe/glu-core/src/templateHelpers';
import util from '@dbiqe/glu-core/src/util';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import tmpl from './amountSearch.hbs';

const operatorMap = {
    EQ: '=',
    LT: '<',
    LTEQ: '<=',
    GT: '>',
    GTEQ: '>=',
    BETWEEN: 'in between',
};

export default AmountSearch.extend({
    template: tmpl,

    ui: util.extend(AmountSearch.prototype.ui, {
        $amountInputField: '[data-hook="getAmountInputField"]',
    }),

    handleOpSelection(ids) {
        const operator = ids[0];
        const isBetween = operator === 'BETWEEN';

        this.ui.$operandContainer.toggleClass('range-input', isBetween);
        this.ui.$fieldValue.attr(
            'placeholder',
            isBetween ? locale.get('filter.between.start') : '',
        );
    },

    onRender() {
        /*
         * Don't call amountSearch's onRender, call the super because
         * we don't want to include this.ui.$focusField.maskAmount();
         */
        FieldSearch.prototype.onRender.call(this);
        this.showDropdown();

        /**
         * NH-46604 - Fix behavior for reopening badges in AmountSearch.
         * This override (and relevant common.js changes)
         * can be removed when GLU-1165 is available after Glu 1.8.41
         * Forcibly update the input values or the maskAmount eats them.
         * INFO: This is known issue with the input mask, regardless of version
         */
        this.ui.$focusField.each(function () {
            if (this.value) {
                this.value = format.amount(this.value);
            }
        });
        const selectedOption = this.model.get('fieldName');

        // NH-191195 allow interest rate to have up to 5 decimal places in the mask
        const numberOfDecimals =
            selectedOption === 'EXCHANGE_RATE'
                ? 6
                : parseInt(
                    serverConfigParams.get('BAILoansInterestDecimalPlaces') ||
                          5,
                    10,
                );

        if (
            selectedOption === 'EXCHANGE_RATE' ||
            selectedOption === 'INTERESTRATE'
        ) {
            this.ui.$focusField.inputmask({
                alias: 'decimal',
                groupSeparator: ',',
                autoGroup: true,
                digits: numberOfDecimals,
                digitsOptional: true,
                placeholder: '',
                rightAlign: true,
            });
        } else {
            // NH-50132 Use consistent input mask the same as payments, etc.
            this.ui.$focusField.inputmask('number');
        }
    },

    buildFilter(value, equality, value2) {
        const filterOutput = {
            field: this.field,
            title: this.model.get('title'),
            type: this.model.get('type'),
        };

        switch (equality) {
        case 'BETWEEN':
            filterOutput.equality = equality;
            filterOutput.label = `${operatorMap[equality]} ${value} - ${value2}`;
            filterOutput.value = [
                format.removeNumberFormat(value),
                format.removeNumberFormat(value2),
            ];
            break;

        default:
            filterOutput.equality = equality || 'EQ';
            filterOutput.label = `${operatorMap[equality]} ${value}`;
            /**
                 * NH-129418. For client side only filtering (not the case with most of our grids
                 * that leverage ListView), there is a bug in @glu/grid caused by the following
                 * value being contained in an array. If you look at the source code for this file,
                 * the `this.filter` method being bound on line 71 attempts to compare
                 * filterOutput.value directly against `model.get(field)`. This will never work if
                 * the value is in an array.
                 */
            filterOutput.value = format.removeNumberFormat(value);
            break;
        }

        filterOutput.filter = this.filter.bind(
            this,
            this.field,
            filterOutput.value,
            equality,
        );
        return filterOutput;
    },
});
