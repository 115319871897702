/**
 * IdentityGuard nearly doubles startup time
 *  so it is disabled by default and will need
 *  to be enabled for specific customers.
 */

import adobeAnalytics from 'system/interfaces/adobeAnalytics';
import bioCatch from 'system/interfaces/bioCatch';
import cfrm from 'system/interfaces/cfrm';
import dynatrace from 'system/interfaces/dynatrace';
import elasticUserMonitoring from 'system/interfaces/elasticUserMonitoring';
import identityGuard from 'system/interfaces/identityGuard';
import liveEngage from 'system/interfaces/liveEngage';
import pendo from 'system/interfaces/pendo';
import snowChat from 'system/interfaces/snowChat';
import tagManager from 'system/interfaces/tagManager';
import transmit from 'system/interfaces/transmit';
import trusteerPinPoint from 'system/interfaces/trusteerPinpoint';
import trusteerRapport from 'system/interfaces/trusteerRapport';
import walkMe from 'system/interfaces/walkMe';

const modules = [
    adobeAnalytics,
    bioCatch,
    cfrm,
    dynatrace,
    elasticUserMonitoring,
    identityGuard,
    liveEngage,
    pendo,
    snowChat,
    tagManager,
    transmit,
    trusteerPinPoint,
    trusteerRapport,
    walkMe,
];

export default {
    getModules: () => modules,
};
