import glu from '@dbiqe/glu-core/src/glu';
import http from 'no-override!@dbiqe/glu-core/src/http';
import { appBus } from '@dbiqe/glu-core';
import util from '@dbiqe/glu-core/src/util';
import cookie from 'system/cookie';
import store from '@glu/store';
import localStore from 'common/util/localStore/localStore';

let lastRequestTimer = false;

/*
 * Update localStore with last request time on key DBIQ_HTTP_TIME
 * Throttled to every ten seconds to avoid many fast updates.
 */
const updateLastRequestTime = util.throttle(() => {
    if (lastRequestTimer) {
        localStore.set('DBIQ_HTTP_TIME', Date.now());
    }
}, 10000);

export default util.extend(
    {},
    http,
    {
        statusCodeHandlers: {
            400: function(result) {
                let response;
                try {
                    response = JSON.parse(result.responseText);
                } catch (e) {
                    response = result.responseText;
                }
                appBus.trigger('http:400', response);
            },

            401: function(result) {
                const isMaintenanceActive = (result.getResponseHeader('liveMaintenanceActive') === '1');
                if (result.getResponseHeader('sloUrl') && !isMaintenanceActive) {
                    // redirect logout
                    let logoutUrl = result.getResponseHeader('sloUrl');
                    if (logoutUrl.indexOf(window.location.protocol) !== 0) {
                        logoutUrl = `${window.location.protocol}//${logoutUrl}`;
                    }
                    window.location.href = logoutUrl;
                } else if (result.getResponseHeader('cxp-proxy') && !isMaintenanceActive) {
                    return;
                } else if (!util.isEmpty(result.responseJSON) && !util.isEmpty(result.responseJSON.ssoFailedLoginUrl) && !isMaintenanceActive) {
                    window.location.href = result.responseJSON.ssoFailedLoginUrl;
                } else {
                    appBus.trigger(
                        'http:401',
                        {
                            returnFragment: glu.history.fragment,
                        },
                    );
                }
            },

            403: function(xhr) {
                if (xhr.getResponseHeader('cxp-proxy')) {
                    return;
                }
                appBus.trigger('http:403', xhr);
            },

            404: function() {
                appBus.trigger('http:404');
            },

            422: function(result) {
                let response = result.responseJSON;
                if (util.isEmpty(response)) {
                    try {
                        response = JSON.parse(result.responseText);
                    } catch (e) {
                        response = result.responseText;
                    }
                }
                appBus.trigger('http:422', response);
            },

            500: function() {
                appBus.trigger('http:500');
            },

            // service unavailable
            503: function() {
                appBus.trigger('http:503');
            },

            504: function() {
                appBus.trigger('http:504');
            },
        },

        /**
         * @method handleMFAChallenge
         * @param {XMLHttpRequest} jqXHR
         * @param {object} request
         * @param {string} [requestMethod]
         * Will handle triggering the MFA challenge if an http request returns a certain error
         * in this case we are listening to a `422` error code
         */
        handleMFAChallenge(jqXHR, request, requestMethod) {
            const response = jqXHR.responseJSON;
            if (response.token) {
                cookie.set('x-csrf', response.token);
            }

            appBus.trigger(
                'mfa:challenge:start',
                {
                    request,
                    response,
                    challengedAction: response.challengedAction,
                    message: response.message,
                    locale: response.locale,
                    requestMethod: requestMethod || 'post',
                    challengeType: response.challengeType,
                },
            );
        },

        post(url, data, success, error, options) {
            let originalErrorCallbackList = error;
            if (util.isFunction(error)) {
                originalErrorCallbackList = [error];
            } else if (Array.isArray(error)) {
                originalErrorCallbackList = error;
            } else {
                originalErrorCallbackList = [];
            }

            const urlBeforeRequestPathName = document.location.pathname;

            const hijackedError = (jqXHR, textStatus, errorThrown) => {
                if (document.location.pathname !== urlBeforeRequestPathName) {
                    return;
                }
                if (jqXHR.getResponseHeader('challenge-state') === 'required') {

                    this.handleMFAChallenge(
                        jqXHR,
                        {
                            url,
                            data,
                            success,
                            error,
                            options,
                        },
                    );

                } else {
                    for (let i = 0; i < originalErrorCallbackList.length; i++) {
                        originalErrorCallbackList[i](jqXHR, textStatus, errorThrown);
                    }
                }
            };

            const wrappedSuccess = function() {
                if (document.location.pathname !== urlBeforeRequestPathName) {
                    return;
                }
                if(arguments[0]?.challengedAction && arguments[0]?.warningMessage){
                    appBus.trigger('mfa:challenge:alertOnCancel:error', arguments[0].warningMessage);
                    return;
                }
                if (util.isFunction(success)) {
                    success.apply(null, arguments);
                }
            };

            updateLastRequestTime();
            options = this.complete(options, url);
            return this.request('POST', url, data, wrappedSuccess, hijackedError, options);
        },

        get(url, success, error, options) {
            const urlBeforeRequestPathName = document.location.pathname;

            const wrappedSuccess = function() {
                if (document.location.pathname !== urlBeforeRequestPathName) {
                    return;
                }
                if (util.isFunction(success)) {
                    success.apply(null, arguments);
                }
            };

            const wrappedError = function(jqXHR) {
                if (document.location.pathname !== urlBeforeRequestPathName) {
                    return;
                }

                /*
                 * need to have a case to handle MFA here as well (for only 422 error code for user purposes)
                 * in a get case, it will mainly be only viable to user authentication calls
                 * if user is not able to login, then will just show login screen
                 */
                if (jqXHR.getResponseHeader('challenge-state') === 'required') {

                    this.handleMFAChallenge(
                        jqXHR,
                        {
                            url,
                            success,
                            error,
                            options,
                        },
                        'get',
                    );

                } else {
                    if (util.isFunction(error)) {
                        error.apply(null, arguments);
                    }
                }
            };

            updateLastRequestTime();
            options = this.complete(options, url);
            return this.request('GET', url, null, wrappedSuccess, wrappedError.bind(this), options);
        },

        complete(options, url) {
            if (!options) {
                options = {};
            }
            const complete = options.complete;
            options.complete = function(xhr) {
                const responseCode = xhr.status.toString();
                const logoffStatusCodes = store.get('forcedLogoffHttpStatusCodes');
                if (complete) {
                    complete.apply(this, arguments);
                }
                if (logoffStatusCodes && logoffStatusCodes.indexOf(responseCode) > -1) {
                    appBus.trigger('logout', this);
                }
                if (window.parent !== window){
                    appBus.trigger('http:complete:callback:invocation', url);
                }
            };
            return options;
        },

        /**
         * @method addRequestHeaderFromUrlParamName
         * @param {object} requestParamName - the name of the request parameter name to be added as a request header
         *
         * Add as a request header the request parameter name specified by requestParamName parameter.
         * The list of request parameter name is obtained from the window.url
         */
        addRequestHeaderFromUrlParamName(requestParamName) {
            const value = window.url;
            const token = value.split('?');
            const headerName = {};
            const self = this;
            let item;
            let paramList;
            if (token.length > 1) {
                paramList = token[1].split('&');
                util.find(paramList, parameter => {
                    item = parameter.split('=');
                    if (item.length === 2 && item[0] === requestParamName) {
                        headerName[requestParamName] = item[1];
                        self.setRequestHeaders(headerName);
                    }
                });
            }
        },
        setLastRequestTimer(enable = true) {
            lastRequestTimer = enable;
        },
    },
);
