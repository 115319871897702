import { $, Model, util } from '@dbiqe/glu-core';
import RowImageCell from '@glu/grid/src/cell/imageCell';

export default RowImageCell.extend({

    /**
     * @returns {string}
     */
    className() {
        return this.options.className;
    },

    /**
     * @param {Object} options
     */
    initialize(options) {
        const model = this.getImageModel(options.model, options.column);

        this.options = options;
        this.parentModel = options.model;
        this.column = options.column;
        this.model = model;

        RowImageCell.prototype.initialize.call(this, {
            ...options,
            model,
        });
        this.el.dataset.title = this.getDataTitle(this.parentModel);
    },

    /**
     * @param {Backbone.Model} model
     * @returns {jQuery}
     */
    getDataTitle(model) {
        const val = model.get('value');
        let dataTitle;
        if (model.get('safe') === false) {
            try {
                dataTitle = $(val).text();
            } catch (e) {
                dataTitle = val;
            }
        }
        return dataTitle;
    },

    /**
     * @param {Backbone.Model} parentModel
     * @param {Backbone.Model} column
     * @returns {Backbone.Model}
     */
    getImageModel(parentModel, column) {
        const imageData = parentModel.get(column.get('field'));
        // get our value and make sure it's an object
        if (imageData !== undefined && !util.isArray(imageData)) {
            // convert our object data into a model
            const model = new Model(imageData);
            const urlProperty = column.get('formatSrc');
            const url = util.isFunction(urlProperty)
                ? urlProperty(imageData, parentModel)
                : undefined;

            // if we have a formatted image path set, use this for an image.
            if (url !== undefined) {
                model.set('src', url);
            }

            return model;
        }

        // TODO - What should happen here? It is unhandled in the original code.
        return new Model();
    },
});
