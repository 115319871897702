import moment from 'moment';
import number from 'numeral';
import util from '@dbiqe/glu-core/src/util';
import Format from '@dbiqe/glu-core/src/templateHelpers';
import userInfo from 'etc/userInfo';
import Decimals from 'common/dynamicPages/api/decimals';

/**
 * Remove any unwanted characters like a dollar sign
 * @param {string} value - The formatted number
 * @returns {string}
 */
const sanitizeNumber = (value) => {
    const cleanupRegEx = new RegExp('[^-\\d, .]', 'g');
    return value.replace(cleanupRegEx, '').trim();
};

/*
 * Utility methods for displaying locale-dependent data.
 */
export default {
    /**
     * Convert a string that could be a localized number into a real
     * computer usable number.
     */
    stringToNumber(value) {
        return number().unformat(value);
    },

    /**
     * Format a numeric value using the supplied format string.
     *
     * @param value
     * @param format
     */
    formatNumber(value, format) {
        return Format.amount(value, format);
    },

    /**
     * Format a numeric value using the supplied currency code.
     *
     * @param value - numeric amount value
     * @param currency - three character currency code
     */
    formatNumberByCurrency(value, currency) {
        const format = Decimals.getCurrencyFormat(currency);
        return Format.amount(value, format);
    },

    /**
     * remove number formatting for current locale
     * @param value
     * @returns {*}
     */
    removeNumberFormat(value) {
        // TODO:replace this with the glu Format.removeNumberFormat(value) when available.
        return number().unformat(value);
    },

    /**
     * Removes all non ASCII characters from a string
     *
     * @param value
     */
    formatAllAscii(value) {
        return value.replace(/[\x00-\x1F]+/g, ''); // eslint-disable-line
    },

    isPrintableAsciiChar(charCode) {
        /*
         * allow backspace and delete characters in addition to the acceptable ascii
         * character range.
         */
        if (charCode === 8 || charCode === 9 || ((charCode > 31) && (charCode < 127))) {
            return true;
        }
        return false;
    },

    /**
     * Format a currency value using the supplied format string.
     * @param value
     */
    formatCurrency(value) {
        return Format.amount(value);
    },

    /**
     * NH-193785 - Fixing wrong indicative rate for wire International
     * @param {*} amount - currency amount
     * @returns - formatted currency depending on the locale
     */
    formatNumberForUserLocale(amount) {
        const thousands = userInfo.get('thousandsSeparator') || ',';
        const decimal = userInfo.get('decimalSeparator') || '.';
        // eslint-disable-next-line consistent-return
        return amount.replace(/[,.]/g, (val) => {
            if (val === ',') {
                return thousands;
            }
            if (val === '.') {
                return decimal;
            }
        });
    },

    /**
     * Remove formatting from number. Accepts optional separator strings.
     * @param {string} formattedNumber
     * @param {string} [thousands]
     * @param {string} [decimal]
     * @return {number}
     */
    unformatNumber(
        formattedNumber,
        thousands = userInfo.get('thousandsSeparator') || ',',
        decimal = userInfo.get('decimalSeparator') || '.',
    ) {
        // If we have a number, pass it on.
        if (typeof formattedNumber === 'number') {
            return formattedNumber;
        }
        // If it isn't a string, now, we're done.
        if (typeof formattedNumber !== 'string' || formattedNumber === 'NaN' || !formattedNumber) {
            return NaN;
        }

        if (formattedNumber === 'Infinity') {
            return Infinity;
        }

        // Clean up before creating a Regex, as "dot" is a special character.
        const firstPass = sanitizeNumber(formattedNumber);
        const validChars = new RegExp(`^-?[\\d${thousands}${decimal}]+$`);
        if (validChars.test(firstPass) === false) {
            return NaN;
        }
        const thousandsReg = new RegExp(thousands.replace(/\./g, '\\.'), 'g');
        const decimalReg = new RegExp(decimal.replace(/\./g, '\\.'), 'g');
        const clean = firstPass
            .replace(thousandsReg, '')
            .replace(decimalReg, '.');

        if (clean.split('.').length > 2) {
            // Issue with source or format does not match
            return NaN;
        }

        return parseFloat(clean);
    },

    /**
     * Options used in inputmask for currency
     */
    getCurrencyMaskOptions(autoUnmaskBool, hidePrefix) {
        let localAutoUnmaskBool = autoUnmaskBool;
        let prefix = userInfo.getCurrencySymbol();

        if (localAutoUnmaskBool === undefined) {
            localAutoUnmaskBool = true;
        }

        if (hidePrefix !== undefined && hidePrefix) {
            prefix = '';
        }

        return {
            radixPoint: userInfo.getRadixSeparator(),
            groupSeparator: userInfo.getThousandsSeparator(),
            digits: userInfo.getDecimalPlaces(),
            autoGroup: true,
            prefix,
            rightAlign: false,
            autoUnmask: localAutoUnmaskBool,
        };
    },

    /**
     * Format a date value using the supplied format string.
     *
     * @param value
     * @param format
     */
    formatDate(value, format) {
        return moment(value).format(format || userInfo.getDateFormat());
    },

    formatTime(value, format) {
        return moment(value).format(format || userInfo.getTimeFormat());
    },

    formatDateTime(value, format) {
        return moment(value).format(format || userInfo.getDateTimeFormat());
    },

    formatDateFromUserFormat(value, format) {
        const retFormat = format || userInfo.getDateFormat();
        let formattedDate = moment(value, userInfo.getDateFormat()).format(retFormat);

        /*
         * NH-36832: The rest service sends a date in a format invalid to the current
         * user (e.g. 2007-01-14)
         * moment attempts to interpret the date using the supplied format as the
         * rosetta stone (e.g. moment(value, userInfo.getDateFormat())
         * when the date string does not match the supplied format of the second
         * argument, moments declare the date invalid
         */
        if (formattedDate.indexOf && formattedDate.indexOf('Invalid') > -1) {
            /*
             * As of this defect, the rest service returns a date in the format below,
             * which may not match the format in the userInfo model
             */
            formattedDate = moment(util.isString(value) ? value.split(' ')[0] : value, 'YYYY-MM-DD');
            if (formattedDate.indexOf && formattedDate.indexOf('Invalid') > -1) {
                formattedDate = moment(util.isString(value) ? value.split(' ')[0] : value, userInfo.getDateFormat());
            }
            return formattedDate.format(retFormat);
        }
        return formattedDate;
    },

    /**
     * Return a formatter that can be used for displaying currencies in a grid.
     *
     * @returns {Function}
     */
    gridCurrencyFormat(format) {
        const self = this;
        return function (value) {
            return self.formatCurrency(value, format);
        };
    },

    /**
     * Return a formatter that can be used for displaying dates in a grid.
     *
     * @returns {Function}
     */
    gridDateFormat() {
        const self = this;
        return function (value) {
            let prettyDate = '';
            if (value !== '') {
                prettyDate = self.formatDate(value);
            }
            return prettyDate;
        };
    },

    /**
     * Return a formatter that can be used for displaying enums in a grid.
     *
     * @returns {Function}
     */
    gridEnumFormat() {
        return function (value) {
            return value;
        };
    },

    sanitizeNumber,

    /**
     * @method unescapeAmpersand
     * @param {string} text
     * @returns {string} unescaped text
     * Unescapes ampersand in text passed and returns it
     */
    unescapeAmpersand(text) {
        return text.replace(/&amp;/g, '&');
    },
};
