import React from 'react';
import PropTypes from 'prop-types';
import StoreProvider from 'components/StoreProvider/StoreProvider';
import Branding from '../../css/Branding/Branding';
/**
 * Any additional providers that should be applied application
 * wide should be added here.
 */
const RootProvider = ({
    baseTheme,
    children,
}) => (
    <StoreProvider>
        <Branding baseTheme={baseTheme}>
            {children}
        </Branding>
    </StoreProvider>
);

RootProvider.propTypes = {
    children: PropTypes.node.isRequired,
    baseTheme: PropTypes.oneOf(['ash', 'legacy']),
};

RootProvider.defaultProps = {
    baseTheme: 'legacy',
};

export default RootProvider;
