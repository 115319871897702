/**
 * Used by the NHUX application to coordinate the ChildFrameController to
 * register and broadcast resize events to
 * the portal that is containing it.
 */
import Controller from '@dbiqe/glu-core/src/controller';
import appBus from 'system/gluOverride/appBus';
import dialog from '@glu/dialog';
import jQuery from 'jquery';
import store from '@glu/store';
import util from 'underscore';
import { isDeepLinked } from 'common/util/deeplinkUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import PortalInterface from 'system/notifications/interfaces/portal';
import MutationObserver from 'common/util/mutationObserver';
import ChildFrameController from './childController';
import RedirectController from './redirectController';
import ResizePublishView from './resizePublishView';
import CacheController from './cacheController';
import OverlayAlignmentFactory from './overlayAlignmentFactory';

const MODAL_RESIZE_HEIGHT_MIN = 650;

export default Controller.extend({
    initialize(options) {
        this.setupRedirectController();

        if (!isDeepLinked()) {
            return;
        }

        jQuery('html').addClass('portal');

        // When we first load in an iframe, set "source" to the current href
        store.set('lastDeepLinkNavigationSource', window.location.href);

        this.childController = new ChildFrameController({
            domain: '*',
        });

        this.cache = new CacheController({
            domain: '*',
        });

        this.overlayAlignment = OverlayAlignmentFactory.create(this.childController);

        this.domObserver = new MutationObserver();

        this.domObserver.observe(jQuery(options.mutationSelector).get(0));

        this.view = new ResizePublishView({
            frameController: this.childController,
            domObserver: this.domObserver,
            el: 'html',
        });

        // Overload dialog to support resize action
        this.setupDialogResizeNotification();

        this.view.render();
        this.listenTo(this.childController, 'router:navigate', this.processNavigationRequest);
        this.listenTo(this.childController, 'keepSessionAlive', this.handleKeepSessionAlive);

        // listening to events emitted from http.js for response codes 500, 503 and 400
        this.listenTo(appBus, 'http:400 http:500 http:503', this.handleErrorWhenPageSessionIsInvalid);

        // listening for the event that is sent when navigating to a detail page
        this.listenTo(appBus, 'parentScrollTop', this.parentScrollTop);
    },

    handleNavigateRedirect(evt) {
        appBus.trigger('router:navigate:ssoStaticView', evt);
    },

    /**
     * @method processNavigationRequest
     *
     * @param {object} evt - event parameter
     *
     * Before navigating user to the requested route, handle any required clean up
     * such as closing a modal if it exists
     */
    processNavigationRequest(evt) {
        // if a modal is already opened, close it
        appBus.trigger('notification:child:modal:close');
        store.set('lastDeepLinkNavigationSource', evt.data.message);
        this.handleNavigateRoute(evt);
    },

    handleNavigateRoute(evt) {
        appBus.trigger('router:navigate:before', evt);
        appBus.trigger('router:navigate', evt.data.message, { trigger: true, newStack: true });
        appBus.trigger('router:navigate:after', evt);
    },

    handleKeepSessionAlive(evt) {
        appBus.trigger('keepSessionAlive', evt);
    },

    /**
     * Initializes the redirectController and creates a listener for the redirect
     * event triggered from index.html
     * @method setupRedirectController
     * @return {[void]}
     */
    setupRedirectController() {
        this.redirectController = new RedirectController({
            domain: '*',
        });

        this.listenTo(this.redirectController, 'redirect', this.handleNavigateRedirect);
    },

    /**
     * @method parentScrollTop
     *
     */
    parentScrollTop() {
        const delayValue = serverConfigParams.get('portalMoveToTopDelay');
        const portalMoveToTopEnabled = serverConfigParams.get('portalMoveToTopEnabled');
        let values;
        if (!util.isEmpty(portalMoveToTopEnabled) && portalMoveToTopEnabled.toLowerCase() === 'true') {
            values = {
                type: 'moveToTop',
                scrollY: 1,
            };
            // Add delay only if configured
            if (delayValue) {
                values.delay = delayValue;
            }
            PortalInterface.send(values);
        }
    },

    /**
     * @method handleErrorWhenPageSessionIsInvalid
     *
     * this method redirects the user to a configurable URL if the page session
     * is invalid.
     * This redirect is performed when the request to display a deeplink fails
     * due to an issue with the request
     * due to invalid syntax in the request, incomplete headers. The user is
     * redirected to another page instead of
     * letting the user see the [object object] error message
     */
    handleErrorWhenPageSessionIsInvalid() {
        let url;
        if (!store.get('userInfo')) {
            url = serverConfigParams.get('DeeplinkUserCallFailedUrl');
            if (url) {
                window.location.href = url;
            }
        }
    },

    setupDialogResizeNotification() {
        const originalShowPopup = dialog.showPopup.bind(dialog);
        let retObj;

        dialog.showPopup = (view) => {
            this.view.handleDomChange(MODAL_RESIZE_HEIGHT_MIN);
            retObj = originalShowPopup(view);
            if (window.parent !== window) {
                /*
                 * TODO: This appears redundant, now.
                 * this.childController.publish('positionModal');
                 */
            }
            return retObj;
        };
    },

    register() {
        if (this.childController) {
            this.childController.register();
        }
    },
});
