import { Controller } from 'backbone.marionette';
import { appBus } from '@dbiqe/glu-core';
import cache from 'system/utilities/cache';

export default Controller.extend({
    initialize(options) {
        this.options = options || {
            domain: '*',
        };
        this.listenTo(appBus, 'router:navigate:before', this.setData);
        this.listenTo(appBus, 'router:navigate:after', this.navigateAfterCallback);
        this.listenTo(appBus, 'notification:child:portalWindow-scrollY', (data) => {
            if (data.scrollY) {
                cache.set('current-workspace-scrollY', data.scrollY);
            }
        });
    },

    setData(evt) {
        if (evt && evt.data && evt.data.deeplink) {
            const newKey = Object.keys(evt.data.deeplink || {})
                .find(index => index !== 'context') || '';

            if (newKey && evt.data.deeplink[newKey]) {
                cache.set(cache.makeKeyFromContext(
                    newKey,
                    evt.data.deeplink.context,
                ), evt.data.deeplink[newKey]);
            }
        }
    },

    navigateAfterCallback() {

    },
});
