import { actionTypes } from './actions';

export default (state = {}, action) => {
    switch (action.type) {
    case actionTypes.SET_USER_PREFERENCES:
        return {
            ...state,
            ...action.payload.userPreferences,
        };
    default:
        return state;
    }
};
