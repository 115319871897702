export default {
    /*
     * Bank Accounts - 29090
     * Bank Accounts Current and Future - 29091
     * ACH - 29092
     * ACH C & F - 29093
     * Legacy - 29094
     * Legacy C & F - 29095
     * LockBox - 29096
     * Lockbox C&F - 29097
     * Inquiries >= 29094 do not need types as part of the request, just the
     * inquiry id
     */

    RISK_POSITIVE_PAY: 'EBMPPDEC',
    RISK_ACH_AUTH_RULE: 'EBMPPRUL',
    ABS_MESSAGE: 'MSGS',
    ABS_REQUEST: 'REQUEST',
    ABS_ACH: 'ACHWHINQ',
    CHECK_INQUIRY: 'CHECKINQ',
    ACH_CONTROLS_TOTAL: 'ACHCTTYP',

    PAYMENT_ENTITLEMENT_EXCLUSIONS: [
        'ACH',
    ],

    RESTRICTED_TEMPLATES_EXCLUSIONS: [
    ],

    REPORTING_ENTITLEMENT_EXLUSIONS: [
        'BTR',
        'LBX',
        'LGREP',
        'PAY',
        'RTP',
    ],

    ENTITY_TYPE: {
        LEGACY: 'legacy',
        LOCKBOX: 'lockbox',
        ACCOUNT: 'bank',
        ORIGINATOR: 'ach',
        LOCATION: 'location',
        TEMPLATE: 'template',
        BANK_WIDGET: 'bankWidget',
        TOA_LOCATIONS: 'TOA',
        PAYMENY_INITIATOR_GROUP_IDS: 'ControlTotal',
        ACH_WAREHOUSE: 'WAREHOUSE',
    },

    ENTITY_NAME: {
        LEGACY: 'reportid',
        LOCK: 'LOCKBOXFILTER',
        BANK: 'BankAccount',
        BANK_CR: 'BankAccountCR',
        ORIGINATOR: 'ORIGINATOR',
        LOCATION: 'CLIENTLOCATION',
        TEMPLATE: 'Template',
        BANK_WIDGET: 'EXTERNALWIDGET',
        TOA_LOCATIONS: 'TOALOCATION',
        PAYMENY_INITIATOR_GROUP_IDS: 'ControlTotal',
        ACH_WAREHOUSE: 'WAREHOUSE',
    },

    REQUEST_ENTITY: {
        BANK: 29090,
        BANK_CF: 29091,
        ACH: 29092,
        ACH_CF: 29093,
        LEGACY: 29094,
        LEGACY_CF: 29095,
        LOCK: 29096,
        LOCK_CF: 29097,
        LOCATION: 29120,
        LOCATION_CF: 29121,
        TEMPLATE: 29099,
        TEMPLATE_CF: 29088,
        BANK_WIDGET: 29123,
        BANK_WIDGET_CF: 29124,
        TOA_LOCATIONS: 29128,
        TOA_LOCATIONS_CF: 29129,
        PAYMENY_INITIATOR_GROUP_IDS: 29130,
        PAYMENY_INITIATOR_GROUP_IDS_CF: 29131,
        ACH_WAREHOUSE: 29132,
        ACH_WAREHOUSE_CF: 29133,
    },

    REQUEST_ENTITY_LOCALE_KEYS: {
        29090: 'CM.Account',
        29091: 'CM.Account',
        29092: 'uce.originator',
        29093: 'uce.originator',
        29094: 'uce.legacyReport',
        29095: 'uce.legacyReport',
        29096: 'uce.lockbox.filter',
        29097: 'uce.lockbox.filter',
        29120: 'uce.location',
        29121: 'uce.location',
        29099: 'uce.templates',
        29088: 'uce.templates',
        29123: 'uce.bankWidget',
        29124: 'uce.bankWidget',
        29130: 'risk.pigids.filter.label',
        29131: 'risk.pigids.filter.label',
        29132: 'risk.achwarehouse',
        29133: 'risk.achwarehouse',
        29128: 'uce.toa',
        29129: 'uce.toa',
    },

    TYPE_ONLY: [
        'REPORTS',
        'RISK',
        'ADMIN',
        'ALERTS',
    ],

    ESTATEMENT_HEADER_MATCHES: {
        ESTMENTMONTHLY: 'type.jrpt.report',
        ESTMENTWEEKLY: 'type.jrpt.report',
        ESTMENTQUARTER: 'type.jrpt.report',
    },

    // Lockbox is an oddball so reference which entitled action to use
    LOCKBOX: {
        TYPE: 'LBX_RPT',
        GROUP_ID: 'LBX',
    },

    LEGACY: {
        TYPE: '16010',
        GROUP: 'LGREP',
    },

    BANK_WIDGET: {
        TYPE: 'BNKWDGTS',
        GROUP: 'AdditionalBankServices',
    },

    PAYMENT_TYPE_PRODUCTS: [
        'WIRES',
        'TRANSFER',
        'LOANS',
        'ACH',
        'TRANSFERBANKACCOUNT',
        'TRANSFERBANKACCOUNTCR',
    ],
    PAYMENY_INITIATOR_GROUP_IDS: {
        ID: 'ACHControlTotals',
        GROUP: 'RISK',
    },
    RISK_POSITIVE_PAY_ID: 'ElectronicPaymentPostivePay',

    ACH_WAREHOUSE: {
        ID: 'ACHWarehouse',
        GROUP: 'USACH',
    },
};
