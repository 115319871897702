import PropTypes from 'prop-types';

export default {
  name: PropTypes.string.isRequired,

  value: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),

  onBlur: PropTypes.func,
  onChange: PropTypes.func,

  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,

  injectValidation: PropTypes.bool,

  registerInternalValidators: PropTypes.func
};
