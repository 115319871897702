{{#if hasLabel}}
  <label>{{options.label}}</label>
{{/if}}
<div class="flex-dropdown" role="combobox" aria-expanded="false" tabindex="0" {{#if options.dataValidate}}data-validate="{{options.dataValidate}}" {{/if}} {{#if options.dataQA}}data-qa="{{options.dataQA}}" {{/if}}>
  <div class="flex-wrapper">
    <span class="selection disabled {{#if options.clearBtn}} clear-style {{/if}}">
      <div class="selected"></div>
      <div class="line"></div>
      <span class="caret">
        {{icon "caret-down"}}
      </span>
    </span>
    <div class="list">
      {{#if options.filter}}
        <input type="text" class="form-control">
        <div class="filter-autocomplete hidden">
          <span class="filter-text text-nowrap"></span><span class="highlighted-autocomplete text-nowrap"></span>
          <span class="preselected-value text-nowrap"></span>
        </div>
      {{/if}}
      <div class="items"></div>
    </div>
  </div>
</div>
{{#if isHierarchical}}
  <span class="flex-dropdown-warning hide">Selection must be at lowest level of hierarchy</span>
{{/if}}
