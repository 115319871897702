/*
 * As part of 250 records epic there are some combos and workflows that fall outside of the
 * common approach to a combo (e.g. DOM manipulation to set values when there are no options,
 * replacing options, adding options, removing options, etc). When possible we will leverage
 * standard React practices to solve these issue. When necessary, this provides the
 * ability to exclude certain combos from leveraging the new UI component.
 */
import { actionTypes } from './actions';

export default (state = {}, action) => {
    switch (action.type) {
    case actionTypes.ADD_EXCLUDED_COMBOS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};
