import { nodeName } from 'jquery';
import palette from '../palette';

export default {
  text: palette.mineShaft,
  dayBorderRadius: 0,
  inputBorderRadius: 0,

  // Select month
  monthSelectTitle: {
    fontWeight: 'normal'
  },

  // Week numbers
  weekNumber: {
    fontSize: 14,
    color: palette.mineShaft,
  },

  dayNames: {
    fontSize: 10,
  },

  // Select day
  dateSelect: {
    background: palette.white,
    border: `1px solid ${palette.drabOlive}`,
    borderRadius: 0,
    padding: 10,
  },

  cellButtonSpan: {
    borderRadius: 0,
    fontSize: 14,
    height: 32,
    width: 32
  },

  cellButton: {
    height: 32,
    width: 32
  },

  emptyWeekDay: {
    display: 'none',
    height: 0
  },

  isToday: {
    color: palette.white,
    background: palette.oliveDrab,

    '&::before': {
      background: palette.oliveDrab,
      borderRadius: 0,
      color: palette.white,
      height: 32,
      width: 32
    }
  },
};