import Glu from '@dbiqe/glu-core/src/glu';
import $ from 'jquery';
import util from 'underscore';
import Model from '@dbiqe/glu-core/src/model';
import NestedModel from '@dbiqe/glu-core/src/nestedModel';
import appBus from 'system/gluOverride/appBus';

const root = (typeof window !== 'undefined') ? window : this;

let inputCallback = null;

function canBeChecked(el) {
    return el.tagName === 'INPUT' && (el.type === 'checkbox' || el.type === 'radio');
}

function isSelectElement(el) {
    return el.type === 'select-one' || el.type === 'select-multiple';
}

function isHiddenElement(el) {
    return el.type === 'hidden';
}

function isMaskedInputElement(el) {
    return el.tagName === 'INPUT' && el.className.includes('maskedInput');
}

function hasTextSelection(el) {
    // use try catch in order to see if feature is supported, suggested by stackoverflow:
    // http://stackoverflow.com/a/23704449
    try {
        return el.selectionEnd;
    } catch (e) {
        // Attempting to access element.selectionEnd will throw a DOMException if
        // not supported
        return false;
    }
}

function getDomValue($el) {
    let value = $el.val();

    //if we have an input mask, remove the mask before setting the model
    if ($el.inputmask("hasMaskedValue")) {
        // temp hack to prevent unmasking value of transfer time fields
        if ($el[0].className !== 'form-control input-time') {
            value = $el.inputmask('unmaskedvalue');
        }
    }

    if (canBeChecked($el.get(0))) {
        value = $el.prop('checked') ? value : undefined;
        // `on` is the value for a checked checkbox with no set value.
        value = value === 'on' ? true : value;
    }

    // Select2 uses the jQuery data object. Can't be converted to dataset.
    if ($el.data('select2')) {
        // select2/comboBox returns an array for multi-select vs. comma-separated
        // string from $.val
        value = $el.data('select2').val();
    }

    return value;
}

function setDomValue($el, modelValue, options, previousModelValue) {
    const el = $el.get(0);
    const hidden = isHiddenElement(el);
    const prevVal = hidden ? previousModelValue : $el.val();
    const selectElement = isSelectElement(el);
    const maskedElement = isMaskedInputElement(el);
    let localModelValue = modelValue;
    let selectionEnd;
    let selectionStart;

    if (canBeChecked(el)) {
        const domValue = el.checked;

        /**
         *  If dealing with radio buttons,
         *  since we already parse out the input with the current modelValue
         *  checking this will uncheck all others, and will not change unless the modelValue actually changes
         */
        if (el.type === 'radio') {
            if (domValue) {
                return; // don't check an already checked radio button
            }
            el.checked = true;
            $el.change();
        } else {
            /*
            * TODO
            * This will convert strings to numeric content before evaluating to boolean
            * when appropriate.
            * This will prevent the double inverted string value for zero ("0") from evaluating to true.
            * The double inversion should be replaced with a boolean utility for greater
            * consistency.
            */

            if (!Number.isNaN(Number(localModelValue))) {
                localModelValue = Number(localModelValue);
            }

            localModelValue = !!localModelValue;

            // trigger 'change' event only if initial value is different from a new one
            if (domValue !== localModelValue) {
                el.checked = localModelValue;
                $el.change();
            }
        }

        return;
    }

    if (hasTextSelection(el)) {
        ({ selectionStart } = el);
        ({ selectionEnd } = el);
    }

    // If this is a select element and, only attempt to set value if it's a valid option
    if (selectElement) {
        const isAvailableOption = ($el.find(`option[value="${localModelValue}"]`).length > 0);

        if (localModelValue != null && !isAvailableOption) {
            return;
        }
    }

    // don't re-update the same values, avoid infinite loops
    if (localModelValue === prevVal && !options.selectIfOnlyOne) {
        return false;
    }

    if (maskedElement) {
        /**
         * NH-150999
         * Addresses masked value being overwritten when
         * modelChangeHandler function has fired
         *
         * If this is a masked element we do not want to overwrite
         * the value since the masking event has already
         * produced correct value
         */
        return;
    }

    $el.val(localModelValue);

    /*
     * Change event has to be fired for `select` elements in order to re-draw any Glu.combobox
     * Without a `change` event, select2 will not re-draw the selected value
     */
    if (selectElement || hidden) {
        $el.trigger('change');
    }

    // Nicely place the cursor where it was before and avoid nasty jump
    if (selectionStart) {
        el.setSelectionRange(selectionStart, selectionEnd);
    }
}

function setModelValue(model, $el) {
    const el = $el.get(0);
    const key = el.name;

    // Handle checkbox fields differently, if there are more than one checkbox, multiple
    // values are stored in array
    if (el.type === 'checkbox' && el.dataset.nullable !== undefined) {
        return setModelValueCheckbox(model, $el);
    }

    return model.set(
        key,
        getDomValue($el),
        {
            changeFromBinding: true,
            source: $el,
        },
    );
}

function setModelValueCheckbox(model, $el) {
    const key = $el.attr('name');

    //check if set value is unique & add it as an array
    const currentVal = model.get(key);
    const domVal = getDomValue($el);
    let finalVal = null;

    // Current value may already be an array, if it is, return it otherwise make it one
    const currentArray = Array.isArray(currentVal) ? currentVal : [currentVal];

    //adding items
    if (domVal) {
        if (currentVal !== null && currentVal !== undefined) {
            /* Now check if the array includes the value we want to add,
             * if it does, we can return the original currentVal. It might be an array, or it
             * might not be. In either case, we still want to return the original currentVal
             */
            finalVal = currentArray.includes(domVal) ? currentArray : [...currentArray, domVal];
        } else {
            //if previously no item set, return single item in the array
            finalVal = [domVal];
        }
    } else {
        //get the value of unchecked item, then use that value to remove from array
        const uncheckedVal = $el.val();

        //remove selected item from array
        finalVal = util.reject(currentArray, val => val === uncheckedVal);
    }
    //if the array only has 1 item, do not use array to store value
    if (finalVal.length === 1) {
        [finalVal] = finalVal;
    } else if (finalVal.length === 0) {
        finalVal = null;
    }

    return model.set(
        key,
        finalVal,
        {
            changeFromBinding: true,
        },
    );
}

function listenToInput(view, hasAlt = false) {
    // If alternate container, we need to set up again
    if (view.viewBindingEnabled && !hasAlt) {
        return;
    }

    view.viewBindingEnabled = true;

    view.once('close', () => {
        view.viewBindingEnabled = false;
    });

    const $containers = view.boundContainers;

    // Only apply listener to elements that have *both* `data-bind` and `name` attributes
    $containers.on('input change blur', '[data-bind][name]', function(e) {
        if (inputCallback) {
            inputCallback();
        }

        const $el = $(e.target);
        const el = $el.get(0);
        const model = view[$el.data().bind];

        const isDateInput = !!(model?.fieldData?.[e.currentTarget.name]?.fieldType === 'DATE' || e.currentTarget.className.includes('input-date'));
        const isBlurEventOrNonFocusedDateInputEvent = e.type === 'focusout' || (isDateInput && el !== document.activeElement);

        /*
         * If (this is a date input) XOR (this is a blur event OR a non-browser focused date input
         * event), return early. This allows date inputs to ignore change events triggered by
         * the user manually typing and instead update the model only when the user moves focus out
         * of the input or the input has a programmatically triggered `change` event like in the
         * "updateElement" function of "daterangepicker.js"
         */
        if (isDateInput ^ isBlurEventOrNonFocusedDateInputEvent){
            return;
        }

        // Prevent parent view for from responding to the child view event
        e.stopPropagation();

        /*
         * if the model ALREADY has this inputs value, then this input has had a manually triggered
         * 'change' event from the `setDomValue` function. As such it DOES NOT need to update the
         * already existing attribute on the model and can short circuit here.
         */
        if(!model || model.get(el.name) === getDomValue($el)){
            return;
        }

        /*
         * HACK: we hopefully can find a way to not directly link view binding and
         * the beneWidget
         * But this is a small, clear solution from Raf.
         */
        if (view.useBeneWidget
            && view.beneWidget.$(el).length !== 0
            && view.beneWidget.model
            && view.beneWidget.model.has(el.name)) {
            setModelValue(view.beneWidget.model, $el);
        } else {
            setModelValue(model, $el);
        }
    });
}

function insertValues(attrs, bindings, options, model) {
    util.each(attrs, function(value, key) {
        let $inputs = bindings.filter(`[name="${key}"]`);

        // If this matches a set of radio buttons, only check the relevant button
        if ($inputs.length > 1 && ($inputs.prop('type') === 'radio' || $inputs.prop('type') === 'checkbox')) {
            $inputs = $inputs.filter(function() {
                return this.value === value;
            });
        }
        if (options.source) {
            $inputs = $inputs.filter(function() {
                return this !== options.source.get(0);
            });
        }
        $inputs.each(function() {
            setDomValue($(this), value, options, model.previous(key));
        });
        bindings.filter(`[data-text="${key}"]`).text(value);
    });
}

function listenToModel(view) {
    const modelChangeHandler = function(model, options) {
        const $containers = view.boundContainers;
        let localOptions = options;
        localOptions = localOptions || {};

        const error = view.model.get('error');
        const warning = view.model.get('warning');
        const attrs = view.model.changedAttributes();

        // don't attempt to toggle error/warning status if there are none
        if(!util.isEmpty(error) || !util.isEmpty(warning)){
            util.each(attrs, (value, key) => {
                if (error?.[key]) {
                    const $validateField = $containers.find(`[data-validate="${key}"]`);
                    const addOnFieldText = $validateField.closest('.has-error')
                        .find('.help-block[data-addonfield]').text();
                    $validateField.text('').closest('.has-error').removeClass('has-error');
                    $containers.find(`[name="${key}"]`).attr('aria-invalid', false);

                    if (!addOnFieldText) {
                        $validateField.text('').closest('.has-error').removeClass('has-addon-error');
                    }
                    delete error[key];
                }

                if (warning?.[key]) {
                    $containers.find(`[data-validate="${key}"]`).text('')
                        .closest('.has-warning').removeClass('has-warning');
                    delete warning[key];
                }
            });
        }

        if (localOptions.changeFromBinding) {
            const $texts = $containers.find('[data-text]');
            util.each(attrs, function(value, key) {
                const $tags = $texts.filter(`[data-text="${key}"]`);
                $tags.each(function() {
                    $(this).text(value);
                });
            });
        } else {
            /**
             * HACK
             * NH-169644
             * It was observed during the fix for this issue that for beneficiaryWidget view
             * passed here couldn't find the [data-bind] elements. The beneficiary widget
             * created in 8.3.3 uses some messy logic to mimic the original beneficiary childView
             * that appeared in a modal previously. The lookup is populating the original hidden view,
             * not the visible beneficiary widget. In order to work around this, we are attaching an "alternateView"
             * to the hidden childView. When viewBinding fires with a Model update, it will check for the existence
             * of the alternateView — which only exists in the case of the beneficiaryWidget — and update the DOM of
             * the widget, not the original hidden childView.
             */
            const bindings = (view.alternateView?.$el ?? $containers).find('[data-bind]');
            insertValues(attrs, bindings, localOptions, view.model);
        }

        handleElementsVisibility(view);
    };

    /*
     * These variables and the resultant conditional prevent views from applying the same listener
     * multiple times and cut down on repetition within view binding cycles
     */
    const doesntHaveAnyChangeListeners = (!view.model._events || !view.model._events.change);
    const doesntHaveThisChangeListener = !doesntHaveAnyChangeListeners && (Array.isArray(view.model._events.change) && !view.model._events.change.some(changeObj => changeObj.callback.name === 'modelChangeHandler'));
    if(doesntHaveAnyChangeListeners || doesntHaveThisChangeListener){
        view.listenTo(view.model, 'change', modelChangeHandler);
    }

    view.listenTo(view.model, 'request validate', () => {
        util.each(view.el.querySelectorAll('[data-disable-on]'), el => el.disabled = true);
    });

    view.listenTo(view.model, 'sync invalid', () => {
        util.each(view.el.querySelectorAll('[data-disable-on]'), el => el.disabled = false);
    });
}

function listenToValidation(view) {
    view.listenTo(view.model, 'validate', () => {
        const $containers = view.boundContainers;
        const $errorContainer = $containers.find('[data-hook="formErrors"]');
        $errorContainer.empty();
        const warnings = view.model.get('warning');
        const errors = view.model.get('error');
        const $bindings = $containers.find('[data-bind]');

        if (warnings) {
            util.each(warnings, (value, key) => {
                const errSpan = findValidateSpan($bindings, key, view.model.cid);
                errSpan.text('').closest('.has-warning').removeClass('has-warning');
            });
        }

        if (errors) {
            util.each(errors, (value, key) => {
                const errSpan = findValidateSpan($bindings, key, view.model.cid);
                errSpan.text('').closest('.has-error').removeClass('has-error');
                $bindings.filter((idx, el) => el.name === key).attr("aria-invalid", false);
            });
        }

        view.model.unset('warning');
        view.model.unset('error');
    });

    view.listenTo(view.model, 'invalid', () => {
        const $containers = view.boundContainers;
        const warnings = view.model.get('warning');
        const errors = view.model.get('error');
        const $bindings = $containers.find('[data-bind]');
        if (warnings) {
            util.each(warnings, (value, key) => {
                const $fieldValidate = findValidateSpan($bindings, key, view.model.cid);
                let $filteredValueContainer = $fieldValidate.closest('.form-group');
                // If no form-group is present, fallback to the closest div.  This allows
                // us to move toward semantic classes and not being tied to bootstrap's .form-group element.
                if ($filteredValueContainer.length === 0) {
                    $filteredValueContainer = $fieldValidate.closest('div');
                }

                $fieldValidate.text(value.join(', '));
                $filteredValueContainer.addClass('has-warning');
            });
        }
        if (errors) {
            util.each(errors, (value, key) => {
                const $fieldValidate = findValidateSpan($bindings, key, view.model.cid);
                let $filteredValueContainer = $fieldValidate.closest('.form-group');
                // If no form-group is present, fallback to the closest div.  This allows
                // us to move toward semantic classes and not being tied to bootstrap's .form-group element.
                if ($filteredValueContainer.length === 0) {
                    $filteredValueContainer = $fieldValidate.closest('div');
                }
                // if error is generated from add on field, use input-group-addon for container
                if ($fieldValidate.attr('data-addonField')) {
                    const $addOnGroup = $filteredValueContainer.find('.input-group-addon');
                    if ($addOnGroup.length) {
                        // allow label and error text to be styled as error still when main
                        // field is valid
                        $filteredValueContainer.addClass('has-addon-error');
                        $filteredValueContainer = $addOnGroup;
                    }
                    $filteredValueContainer = ($addOnGroup.length) ?
                        $addOnGroup : $filteredValueContainer;
                }

                $fieldValidate.text(value.join(', '));
                $filteredValueContainer.addClass('has-error');
                $bindings.filter((idx, el) => el.name === key).attr("aria-invalid", true);
            });
            const $errorContainer = $containers.find('[data-hook="formErrors"]');
            const ul = document.createElement('ul');
            ul.style.listStyle = 'none';
            Object.values(errors).map(arr => arr.join()).forEach( itm => {
                const li = document.createElement('li')
                const text = document.createTextNode(itm);
                li.appendChild(text)
                ul.appendChild(li);
            });
            $errorContainer.append(ul);
        }
    });
    view.listenTo(view.model, 'valid:attribute', (key) => {
        const $containers = view.boundContainers;
        const $bindings = $containers.find('[data-bind]');
        const errSpan = findValidateSpan($bindings, key, view.model.cid);
        errSpan.text('').closest('.has-error').removeClass('has-error');
        $bindings.filter((idx, el) => el.name === key).attr("aria-invalid", false);
    });
}

/**
 * @method findValidateSpan
 * @param {array} bindings -  an Array of elements in the view that contains a '[data-bind]' tag, i.e., $bindings = view.$('[data-bind]')
 * @param {string} key - the validator key
 * @param {string} [cid] - cid of the model that's being validated against
 * - This is added to address NH-45060: when there're multiple validators in a view with the same data-validate key,
 * - then the error/warning message for one of these validators will be written to
 * all of them.
 * - To fix the issue, an additional "data-view" filter is added here, but a complete solution include changes at the template side:
 * - 1) add the data-view="{{modelCid}}" tag to impacted validators, i.e., where he "data-validate" tag is located
 * - 2) a template helper also need to be added in corresponding JS file to hold the model's cid value.
 * - Without the above changes at template side, this funciton will still
 * return all validators with the same key as it previously did
 */
function findValidateSpan(bindings, key, cid) {
    const errSpan = bindings.filter(`[data-validate="${key}"]`).filter(`[data-view="${cid}"]`);
    if (errSpan && errSpan[0]) {
        return errSpan;
    } else {
        return bindings.filter(`[data-validate="${key}"]`);
    }
}

function setInitialValues(view) {
    const $containers = view.boundContainers;
    let { attributes } = view.model;
    const $bindings = $containers.find("[data-bind]");

    function getNestedAttributes(set, depth) {
        let localDepth = depth;
        localDepth = localDepth || '';
        // set param has a simple type i.e. set = array[0] = 'sample'
        // so attributes['array[0]'] = 'sample'
        if (!util.isObject(set) && !util.isArray(set)) {
            attributes[localDepth] = set;
            return;
        }
        util.each(set, (value, key) => {
            let localValue = value;
            if (util.isArray(localValue)) {
                util.each(localValue, (itemValue, i) => {
                    getNestedAttributes(itemValue, localDepth ? `${localDepth}.${key}[${i}]` : `${key}[${i}]`);
                });
                return;
            }
            if (util.isObject(localValue)) {
                // TODO Necessary PT-X hack: Account for an attribute being a Model
                // (This is only required until PT-X fully drops BackboneRelational)
                if (localValue && typeof localValue.toJSON === 'function') {
                    localValue = localValue.toJSON();
                }
                return getNestedAttributes(localValue, localDepth ? `${localDepth}.${key}` : key);
            }

            attributes[localDepth ? `${localDepth}.${key}` : key] = localValue;
        });
    }
    if (view.model instanceof NestedModel || view.model.delayedTrigger !== undefined) {
        attributes = {};
        getNestedAttributes(view.model.attributes);
    }

    // Render current attributes
    util.each(attributes, function(value, key) {
        $bindings.each(function(idx, el) {
            // Check for text first
            if (el.dataset.text === key) {
                $(el).text(value);
                return;
            }
            // Only process the current input to reduce.
            if (el.name !== key) {
                return;
            }
            const $el = $(el);
            const elVal = $el.val();
            if (canBeChecked(el)) {
                if (el.type === 'radio') {
                    // we should check name and value of the radio buttons
                    if (elVal === value) {
                        el.checked = true;
                    }
                } else {
                    const isNullable = el.dataset.nullable !== undefined;
                    if (util.isArray(value) && isNullable) {
                        el.checked = util.contains(value, elVal);
                    } else if (isNullable) {
                        if (elVal === value) {
                            el.checked = !!value;
                        }
                    } else {
                        el.checked = !!value;
                    }
                }
            } else {
                $el.val(value);
            }
        });
    });

    handleElementsVisibility(view);
}

function handleElementsVisibility(view) {
    const processor = (selector) => view.boundContainers.toArray()
        .map(el => [...el.querySelectorAll(selector)])
        .reduce((acc, nodeList) => [...acc, ...nodeList], []);

    const dataShow = processor('data-show-if');
    const dataHide = processor('[data-hide-if]');
    const dataDisable = processor('[data-disable-if]');
    const dataEnable = processor('[data-enable-if]');

    const { model } = view;
    util.each(dataShow, el => {
        const data = el.dataset;

        const show = util.isFunction(model[data.showIf]) ?
            model[data.showIf]() :
            !!model.get(data.showIf);
        el.classList.toggle('hidden', !show);
    });

    util.each(dataHide, el => {
        const data = el.dataset;

        const hide = util.isFunction(model[data.hideIf]) ?
            model[data.hideIf]() :
            !!model.get(data.hideIf);

        el.classList.toggle('hidden', !show);
    });

    util.each(dataDisable, el => {
        const data = el.dataset;
        const disable = util.isFunction(model[data.disableIf]) ?
            model[data.disableIf]() :
            !!model.get(data.disableIf);
        el.disabled = disable;
    });

    util.each(dataEnable, el => {
        const data = el.dataset;
        const enable = util.isFunction(model[data.enableIf]) ?
            model[data.enableIf]() :
            !!model.get(data.enableIf);
        el.disabled = !enable;
    });
}

Glu.templateBindings = {
    bind(view) {
        if (!view.model) {
            view.model = new Model();
        }
        if (!view.boundContainers) {
            view.boundContainers = view.$el || [];
        }

        if (!view.gridCid && !view.disableInitialViewBinding) {
            setInitialValues(view);
        }

        listenToModel(view);
        listenToInput(view);
        listenToValidation(view);
    },
    bindAdditional(view, $additionalContainer) {
        if (!view.boundContainers) {
            view.boundContainers = view.$el;
        }
        view.boundContainers = view.boundContainers.add($additionalContainer);

        // Add event listeners to new DOM container
        listenToInput(view, true);

        // Check for behaviors to connect.
        if (typeof view.behaviorEvents === 'function') {

            Object.entries(view.behaviorEvents()).forEach(([key, action]) => {
                const [eventName, element] = key.split(' ');
                $additionalContainer.on(eventName, element, action);
            });
        }
    },
};

Glu.templateBindings.configInputNotification = function(callback) {
    if (callback) {
        inputCallback = callback;
    } else {
        inputCallback = function() {
            appBus.trigger('viewBinding:input');
        };
    }
    appBus.on('unbound:input', inputCallback);
};

export default Glu.templateBindings;
export { setDomValue };
