import { appBus } from '@dbiqe/glu-core';
import {
    calculatePosition,
    getLastPositionData as lastPositionData,
    isDeepLinked as isDeepLink,
} from '@dbiqe/common';
import ErrorView from 'system/error/views/errorMessage';
import store from 'system/utilities/cache';
import portalInterface from 'system/notifications/interfaces/portal';

export const isDeepLinked = () => isDeepLink();
export const getLastPositionData = () => lastPositionData();

export const isEntitled = (url) => {
    if (store.get('entitledDeepLinks') === null || url === '') {
        return false;
    }
    return store.get('entitledDeepLinks')
        .get('deeplinks')
        .indexOf(url) !== -1;
};

export const getErrorView = key => new ErrorView({
    key: key || 'notAuthorized',
    noContainer: true,
});

/**
 * @method moveToTopCheck
 *
 * @param {object} [model] - an optional model parameter
 *
 * sends the message 'parentScrollTop' on the appBus if page is displayed
 * in an iFrame. If model is passed as a parameter a check is made on whether
 * model.isChild is defined
 *
 */
export const moveToTopCheck = (model) => {
    if (model && model.isChild) {
        return;
    }
    if (isDeepLinked()) {
        appBus.trigger('parentScrollTop');
    }
};

/**
 * Get the last fragment from the options passed
 * @param {Object} options
 * @param {Model} options.model
 * @param {Object} options.controller
 * @returns {String}
 */
export const getLastFragment = ({ model, controller = {} }) => (
    model?.get?.('lastFragment') || controller.lastFragment
);

/** ****** iframe position data logic ******* */

export const requestPosition = () => {
    /**
     * DeepLink in iFrame - send a message to retrieve the inner height
     * of the Portal's window content area.
     */
    if (isDeepLinked()) {
        portalInterface.send({
            type: 'getPositionData',
        });
    }
};

export const triggerPosition = (positionData) => {
    appBus.trigger('iframePosition', positionData);
};

export default {
    isDeepLinked,
    isEntitled,
    getErrorView,
    moveToTopCheck,
    getLastFragment,
    calculatePosition,
    getLastPositionData,
    requestPosition,
};
