var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"modal-header\">\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.showCloseButton : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    <h2 class=\"modal-title\" id=\"modal-title\">"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.dialogIcon : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<span data-hook=\"getTitle\">"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span></h2>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                        <button type=\"button\" class=\"close\" data-action=\"close\" aria-label=\""
    + container.escapeExpression(helpers.locale.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.close",{"name":"locale","hash":{},"data":data}))
    + "\"><span class=\"icon-close\"></span></button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return "<span class=\"icon-"
    + container.escapeExpression(((helper = (helper = helpers.dialogIcon || (depth0 != null ? depth0.dialogIcon : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dialogIcon","hash":{},"data":data}) : helper)))
    + "\"></span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<div class=\"modal-backdrop in\"></div>\n<div class=\"modal\">\n    <div class=\"modal-dialog glu-dialog dialog-"
    + alias5(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"type","hash":{},"data":data}) : helper)))
    + " "
    + alias5(((helper = (helper = helpers.modalClass || (depth0 != null ? depth0.modalClass : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"modalClass","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"modal-content\">\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <div class=\"modal-body\" id=\"modal-body\">\n                <p>"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"message","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n            <div class=\"modal-footer\">\n                "
    + ((stack1 = (helpers.footerButtons||(depth0 && depth0.footerButtons)||alias3).call(alias2,depth0,{"name":"footerButtons","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});