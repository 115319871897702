/*
 * Pendo Snippet Injection Integration Interface
 */
import serverConfigParams from 'system/webseries/models/configurationParameters';
import premiumAchPreferences from 'common/util/premiumAch';
import { isDeepLinked } from 'common/util/deeplinkUtil';
import userInfo from 'etc/userInfo';
import { isScriptInjected } from './util';

const isDebugEnabled = () => !!sessionStorage.getItem('pendo-app');

const setPendoDebugApp = () => {
    // check for pendo-app in search params and set it in local storage
    const urlParams = new URLSearchParams(window.location.search);
    const pendoApp = urlParams.get('pendo-app');
    if (pendoApp) {
        sessionStorage.setItem('pendo-app', pendoApp);
    }
};

/**
 * Get the config object for Dynatrace
 * @returns {Object} config object
 */
const getConfig = () => {
    const apiKey = serverConfigParams.get('pendoApiKey');
    return {
        apiKey: isDebugEnabled() ?
            (sessionStorage.getItem('pendo-app') || apiKey) : apiKey,
    };
};

/**
 * Get the snippet URL used for Pendo
 */
const getSnippetUrl = apiKey => (
    `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`
);

/**
 * Initialize Pendo after the script is loaded
 */
const initializePendo = () => {
    window.pendo.initialize({
        visitor: {
            id: `${userInfo.get('group')}/${userInfo.get('id')}`,
            locale: userInfo.get('locale'),
            name: userInfo.get('name'),
            permanent_user_id: userInfo.get('permanentUserId'),
            user_token: userInfo.get('token'),
            is_premium_ach_entitled: premiumAchPreferences.getPremiumAchPreferences('isPremiumAchEntitled'),
            is_entitled_for_ach_payment_types: premiumAchPreferences.getPremiumAchPreferences('isUserEntitledForACHPaymentTypes'),
            is_password_expired: userInfo.get('passwordExpired'),
        },
        account: {
            id: userInfo.get('group'),
            tenant_id: userInfo.get('tenantId'),
            is_deep_linked: isDeepLinked(),
            is_eligible_for_premium_ach: premiumAchPreferences.getPremiumAchPreferences('isEligibleForPremiumACH'),
            has_new_premium_ach_vendors: premiumAchPreferences.getPremiumAchPreferences('hasNewPremiumAchVendors'),
            has_completed_premium_ach_setup: premiumAchPreferences.getPremiumAchPreferences('hasCompletedEnrollment'),
        },
    });
};

/**
 * Installation from Pendo to use as "installation"
 * @returns {void}
 */
const injectPendo = () => {
    const { apiKey } = getConfig();
    const p = window;
    const e = document;
    const n = 'script';
    const d = 'pendo';
    let o;
    // Only the `onload` handler should be modified below, the rest is Pendo specific install code.
    /* eslint-disable */
    let v; let w; let x; let y; let
        z; o = p[d] = p[d] || {}; o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
            o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
        }(v[w]));
    }
    y = e.createElement(n); y.async = !0; y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
    // custom onload addition to allow initialization after script is loaded
    y.onload = initializePendo;
    z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
    /* eslint-enable */
};

/**
 * Append Pendo snippet to the page
 * @returns {void}

 */
const appendSnippet = () => {
    const { apiKey } = getConfig();
    const snippetUrl = getSnippetUrl(apiKey);
    if (!apiKey || isScriptInjected(snippetUrl)) {
        return;
    }
    injectPendo();
};

const pendo = {
    postAuth() {
        // don't need to set debug since refresh will maintain session, new tab will be new session
        appendSnippet();
    },
    preAuth() {
        setPendoDebugApp();
    },
};

export default pendo;
