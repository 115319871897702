import { actionTypes } from './actions';

export default (state = {}, action) => {
    switch (action.type) {
    case actionTypes.RESET_INTERACTIONS:
        return {};
    case actionTypes.UPDATE_INTERACTIONS:
        return Object.entries(action.payload).reduce((stateAcc, [formName, form]) => ({
            ...stateAcc,
            [formName]: {
                ...Object.entries(form).reduce((formAcc, [fieldName, field]) => ({
                    ...formAcc,
                    [fieldName]: {
                        ...Object.entries(field).reduce((fieldAcc, [interaction, value]) => ({
                            ...fieldAcc,
                            [interaction]: {
                                ...(fieldAcc[interaction] || {}),
                                ...value,
                            },
                        }), (formAcc[fieldName] || {})),
                    },
                }), (stateAcc[formName] || {})),
            },
        }), state);
    default:
        return state;
    }
};
