var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4=container.escapeExpression;

  return "<button type=\"button\" class=\"grid-manage-column-menu-trigger\" >\n    "
    + alias4((helpers.customizedIcon||(depth0 && depth0.customizedIcon)||alias3).call(alias2,{"name":"customizedIcon","hash":{"locale":"common.manage.columns","name":"gear"},"data":data}))
    + "\n</button>\n\n<div id=\"region-manage-columns-menu-"
    + alias4(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\"></div>";
},"useData":true});