var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"row\">\n            <div class=\"col-1\"></div>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <div class=\"col-1\"></div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "            <div class=\"col-2\">\n                <div class=\"checkbox ManageGridColumn\" data-cid=\""
    + alias5(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\">\n                    <input id=\"grid-show-column-"
    + alias5(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + alias5(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\" class=\"grid-show-column\" type=\"checkbox\"\n                        "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.isChecked : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias2,((stack1 = (data && data.root)) && stack1.canRenameColumn),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <label for=\"grid-show-column-"
    + container.escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\" class=\"ColumnLabel\">\n                        "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.label : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "\n                    </label>\n                    <span class=\"ColumnRenameToggle icon-pencil\" data-action=\"toggleRenameControls\"></span>\n                    <div class=\"ColumnRename\" data-hook=\"column-rename-form\"></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return container.escapeExpression(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <label for=\"grid-show-column-"
    + container.escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.label : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression;

  return "<div class=\"grid-manage-columns-menu\">\n    <div class=\"row\">\n        <div class=\"checkbox grid-show-all-columns-option col-3\">\n            <input id=\"grid-show-all-columns\" type=\"checkbox\" name=\"grid-show-all-columns\"\n                class=\"grid-show-all-columns\" />\n            <label for=\"grid-show-all-columns\">"
    + alias3(helpers.locale.call(alias2,"button.allColumns",{"name":"locale","hash":{},"data":data}))
    + "</label>\n        </div>\n    </div>\n    <div class=\"columns-checkboxes-container\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.rows : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"row\">\n        <div class=\"col-1\"></div>\n        <div class=\"col-11 grid-manage-columns-buttons\">\n            <button type=\"button\" class=\"btn btn-secondary js-update-columns\">"
    + alias3(helpers.locale.call(alias2,"update",{"name":"locale","hash":{},"data":data}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-tertiary js-cancel\">"
    + alias3(helpers.locale.call(alias2,"cancel",{"name":"locale","hash":{},"data":data}))
    + "</button>\n        </div>\n    </div>\n</div>\n\n<div class=\"grid-manage-columns-backdrop\"></div>\n";
},"useData":true});