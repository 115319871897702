import ManageColumnsMenu from 'no-override!@glu/grid/src/manageColumns/manageColumnsMenu';
import util from '@dbiqe/glu-core/src/util';
import $ from 'jquery';
import store from 'system/utilities/cache';
import RenameColumnForm from './renameColumnForm';
import MenuTemplate from './manageColumnsMenu.hbs';

export default ManageColumnsMenu.extend({
    template: MenuTemplate,

    /*
     * extend ui to include rename ui elements (done to rely on data hooks and not on the
     * html structure)
     */
    ui: util.extend(
        ManageColumnsMenu.prototype.ui,
        {
            $renameFormSection: '[data-hook="column-rename-form"]',
            $columnLabels: '.ColumnLabel',
        },
    ),

    initialize() {
        ManageColumnsMenu.prototype.initialize.call(this);

        // set configuration for Column rename functionality
        this.enableColumnRenameOption = (this.collection.isUserOwnedView
            && this.collection.enableColumnRenameControls);
        this.columnRenameQueue = {};
    },

    /**
     *  ========================================
     *  Rename Column Functionality
     *  ========================================
     */

    /**
     *  @method: toggleRenameControls
     *  - show rename form for specified column and clear column renaming queue for item
     */
    toggleRenameControls(e) {
        const cid = this.$(e.target).closest('[data-cid]').data('cid');
        const columnModel = this.findModelByCid(cid);
        const $columnRenameForm = this.findElByColumnID(cid, this.ui.$renameFormSection);

        // Only have one rename form open at a time
        if (this.currentRenameForm) {
            this.currentRenameForm.close();
        }

        // Render a New Form associated to the column id
        const currentRenameForm = new RenameColumnForm({
            model: columnModel,
            onSubmitCallback: this.submitRenameForQueue.bind(this),
            queuedRename: this.columnRenameQueue[cid],
        });

        currentRenameForm.render();
        $columnRenameForm.append(currentRenameForm.$el);
        this.currentRenameForm = currentRenameForm;
    },

    /**
     *  @method: submitRenameForQueue
     *  - Handler for rename done button (shown when rename mode for column is on)
     *  - Will queue the new columns name for saving and toggle off rename mode
     * on click of save btn
     */
    submitRenameForQueue(cid, columnNewName) {
        const $columnLabel = this.findElByColumnID(cid, this.ui.$columnLabels);
        // queue column's rename
        this.columnRenameQueue[cid] = columnNewName;
        // set column's label to show new name
        $columnLabel.text(columnNewName);

        this.currentRenameForm.close();
    },

    /**
     *  @method: findElByColumnID
     *  @param: {string} column id
     *  @param: {ui} uiElements Type
     *  @return: {jquery el} $element
     *  - Filter out and select specific ui element by type and column id its
     * associated with
     */
    findElByColumnID(cid, $uiElements) {
        return $uiElements.filter((i, el) => $(el).closest(`[data-cid=${cid}]`).length);
    },

    /**
     * @method adjustContainerMinHeight
     *
     * For portal environments.
     * Explicitly publish a resize message with the appropriate minimum height
     */
    adjustContainerMinHeight() {
        if (window.parent !== window) {
            store.unset('manage-columns-menu-event-clientY');
        }
    },

    /**
     *  ==========================
     *  OVERRIDDEN METHODS
     *  ==========================
     */

    onRender() {
        this.setBackdropHeight();
        this.adjustContainerMinHeight();
    },

    /**
     *  @method: handleSave
     *  - Override the handleSave method to incorporate column renaming to the update
     *  - on click of update button on menu
     */
    handleSave() {
        util.each(this.ui.$checkboxes, function (checkbox) {
            const cid = $(checkbox).closest('[data-cid]').data('cid');
            const columnModel = this.findModelByCid(cid);
            const showColumn = $(checkbox).prop('checked');
            const isColumnRenamable = this.enableColumnRenameOption && columnModel.get('enableRename');
            const newColumnName = this.columnRenameQueue[cid];

            if (columnModel) {
                columnModel.set(
                    'condition',
                    showColumn,
                    {
                        silent: true,
                    },
                );
                // rename option
                if (isColumnRenamable && newColumnName) {
                    columnModel.set(
                        'label',
                        newColumnName,
                        {
                            silent: true,
                        },
                    );
                    columnModel.set(
                        'userDefinedLabel',
                        newColumnName,
                        {
                            silent: true,
                        },
                    );
                }
                // remove filters on hidden columns
                if (this.filterProcessor && !showColumn && columnModel.has('field')) {
                    this.filterProcessor.removeFilters(columnModel.get('field'));
                }
            }
        }, this);
        this.close();

        // need to trigger change condition only after last element.
        this.collection.trigger('change:condition', this.collection);
    },

    close() {
        ManageColumnsMenu.prototype.close.call(this);
        // reset rename queue on menu close
        this.columnRenameQueue = {};
    },

    templateHelpers() {
        const self = this;
        return {
            canRenameColumn() {
                return self.enableColumnRenameOption && this.enableRename;
            },
        };
    },
});
