
import Marionette from 'backbone.marionette';
import $ from 'jquery';
import notificationManager from 'system/notifications/manager';
import viewMixins from '@dbiqe/glu-core/src/core/internal/viewMixins';
import { appBus } from '@dbiqe/glu-core';
import mobileUtil from 'mobile/util/mobileUtil';

const Mixin = viewMixins(Marionette.Layout);

export default Marionette.Layout.extend(Mixin).extend({
    constructor(...args) {
        this._notificationsInterface = notificationManager.notificationFunctions();
        Marionette.Layout.apply(this, args);
    },

    bindUIElements(options) {
        Marionette.Layout.prototype.bindUIElements.call(this, options);
        if (this.setupRegions) {
            this.setupRegions();
        }
    },

    navigateTo(route) {
        appBus.trigger('router:navigate:before');
        Mixin.navigateTo(route);
    },

    setupRegions() {
        const self = this;
        const cache = {};

        this.$('[data-region]').each(function() {

            const regionName = $(this).data('region');
            if (typeof self[regionName] === 'undefined') {
                self.addRegion(regionName, `[data-region="${regionName}"]`);
            } else if (!(self[regionName] instanceof Marionette.Region)) {
                throw new Error(`Cannot use region name "${regionName}". Already defined.`);
            } else if (cache[regionName]) {
                throw new Error(`Defined the region "${regionName}" Twice within the template`);
            }
            cache[regionName] = true;
        });
    },

    getTemplate() {
        if (this.loadingTemplate && this.hasLoadedRequiredData() === false) {
            return this.loadingTemplate;
        } else {
            return Marionette.getOption(this, 'template');
        }
    },

    onBeforeRender() {
        this._notificationsInterface.open(this._notificationData);
    },

    onBeforeClose() {
        if (mobileUtil.isMobileGridEnabled()) {
            const batchChildGridView = this?.parentView?.batchChildGridView;
            if (batchChildGridView) {
                batchChildGridView.close();
            }
        }
        this._notificationsInterface.close(this._notificationData);
    },

    setHasLoadedRequiredData(bool) {
        this.hasLoaded = bool;
    },

    notifyPageLoaded() {
        this._notificationsInterface.loaded(this._notificationData);
    },

    hasLoadedRequiredData() {
        return (this.hasLoaded ? this.hasLoaded : false);
    },

    setNotificationData(data) {
        this._notificationData = data;
    },

    /**
     * There are times when a view is shown and depending on if the view is switched away
     * from quickly, it can cause executing code to throw TypeError's as Promises are resolved
     * and callbacks invoked. This utility returns the proper function to be used based on if
     * a view `isClosed` (view property) before allowing things to continue.
     * @param {function} openMethod - Function to perform if the view is open.
     * @param {function} [closedMethod] - Optional. Used for possible cleanup.
     */
    ifNotClosed(openMethod, closedMethod = () => { }) {
        return (...args) => {
            const method = this.isClosed ? closedMethod : openMethod;
            return method.apply(this, args);
        };
    },
});
