/* eslint-disable no-useless-escape */
import util from '@dbiqe/glu-core/src/util';

const ALPHA = 'A-Za-z';
const NUMERIC = '0-9';
const PERIOD = '\\.';
const UNDERSCORE = '_';
const DASH = '\\-';

const validationPatterns = {
    _ALPHANUMS_PATTERN: '^[\\w\~\.\-\*]*$',
    ALPHA_PATTERN: '^[A-Za-z]*$',
    ALPHAUNSCORE_PATTERN: '^[A-Za-z_]*$',
    ALPHANUMERIC_PATTERN: '^[A-Za-z0-9]*$',
    ALPHANUMERICSPACE_PATTERN: '^[A-Za-z0-9\\s]*$',
    ALPHANUMERICUNSCORE_PATTERN: '^[A-Za-z0-9\\_]*$',
    ALPHANUMERICSPACEDASH_PATTERN: '^[A-Za-z0-9\\s\\-]*$',
    ALPHANUMERICSPACEPERIOD_PATTERN: '^[A-Za-z0-9\\s\\.]*$',
    ALPHANUMERICSPACEPERIODDASH_PATTERN: '^[A-Za-z0-9\\s\\.\\-]*$',
    ALPHANUMERICPERIODDASH_PATTERN: '^[A-Za-z0-9\\.\\-]*$',
    ALPHANUMERICPERIODDASHUNSCORE_PATTERN: `^[${ALPHA}${NUMERIC}${PERIOD}${DASH}${UNDERSCORE}]*$`,
    ALPHANUMERICSPACEPERIODUNSCORE_PATTERN: '^[A-Za-z0-9\\s\\._]*$',
    ALPHANUMERICSPACEWITHSPANISH_PATTERN: '^[A-Za-z0-9\\s\\-\\.\,\'\\áÁʹéÉ˜íÍ¡óÓ¿úÚªüÑºñ]*$',
    ISO_8859_1_PRINTABLECHARS_PATTERN: '^[\u0020-\u007e\u00a1\u00bf-\u00ff]*$',
    ALPHASPACESPECIAL_PATTERN: '^[A-Za-z\\/\\s\\-]*$',
    AUDE_PATTERN: '^[ -~\\n\\r]*$',
    CAEFT: '^[A-z0-9#\\$\,\\-\.\\r\\n\\s\'\&*\`\u00c0-\u00c1\u00c6-\u00cf\u00d2-\u00d4\u00d9-\u00dc\u00e0-\u00e2\u00e6-\u00ef\u00f2-\u00f4\u00f9-\u00fc\u0152\u0153\u000ab\u00c4\u00c4\u00d6\u00e4\u00f6]*$',
    DATE_PATTERN: '^[01]\\d\\/[0123]\\d\\/\\d{4}$',
    ENCA_DATE_PATTERN: '^[0123]\\d\\/[01]\\d\\/\\d{4}$',
    YYYY_MM_DD_PATTERN: '^\\d{4}\\-[01]\\d\\-[0123]\\d$',
    YYYY_MM_DD_ISO_8601_SLASHES_PATTERN: '^\\d{4}\\/[01]\\d\\/[0123]\\d$',
    DATETIME_PATTERN: '[0-3][0-9]\/[0-1][0-9]\/[0-9]{4} [0-1][0-9]:[0-5][0-9]:[0-5][0-9] [paPA][Mm]',
    TIME_PATTERN: '^(1[0-2]|0[1-9]):[0-5]\\d ([ap]m|[AP]M)$',
    DECIMAL_PATTERN: '^\\d+(\\.\\d+)?$',
    EMAIL_PATTERN: '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$',
    EMAILLIST_PATTERN: '^[\\w.+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,}(;([\\w.+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,})?)*$',
    FILENAME_PATTERN: '^[\\w,\\s#!\$%\\&\\(\\)+\,\\.\\-]+$',
    FILEPATH_PATTERN: '^[\\w\\./\\\:\\-]*$',
    NACHA_PATTERN: '^[ -~]*$',
    NACHAA_PATTERN: '^[ -~]*$',
    SWIFT_PATTERN: '^[\x0A\x0D\x20\x21\x27-\x29\x2B-\x3A\x3F\x41-\x5A\x61-\x7A\\r\\n]*$',
    SWIFTAMPERSAND_PATTERN: '^[\x0A\x0D\x20\x21\x26-\x29\x2B-\x3A\x3F\x41-\x5A\x61-\x7A\\r\\n]*$',
    UPPERALPHA_PATTERN: '^[A-Z]*$',
    UPPERALPHANUMERIC_PATTERN: '^[A-Z0-9]*$',
    UPPERALPHANUMERICSPECIAL_PATTERN: '^[A-Z0-9.@+-/{}|?!:$()*;,]*$',
    TEMPLATECODE_PATTERN: '^[A-Za-z0-9]+$',
    SSOID_PATTERN: '^[A-Za-z0-9\\_\\|]*$',

    /*
     * this is what is in the charactercodes table even though
     * it does not match the name uppernacha
     */
    UPPERNACHA_PATTERN: '^[ -~]*$',

    NOBADCHARS_PATTERN: '^[^;<>]+$',
    NOBADCHARSWITHSEMICOLON_PATTERN: '^[^<>]+$',
    NUMERIC_PATTERN: '^[0-9]*$',
    NUMERICLEADZERO_PATTERN: '^0[0-9].*$',
    _NUMERIC_PATTERN: '^[0-9]*$',
    NZBECS_PATTERN: '^[ -~\\n\\r]*$',
    PRINTABLECHAR_PATTERN: '[^\\&\\/;<>\\[\\]]',
    PRINTABLECHARPLUSSLASH_PATTERN: '[^\\&;<>\\[\\]]',
    PRINTABLECHARSLASHAMPERSAND_PATTERN: '[%_\\,\\[\\]\\/<>;]',

    /*
     * NOTE: for now allowing up to 2 decimal places,
     * this will be refined when we consider locale as well separators
     */
    AMOUNT_PATTERN: '^[+-]?((0*[.,]([1-9]\\d?\\d?|\\d\\d?[1-9]))|[1-9]\\d{0,2}((,\\d{3})*(\\.\\d{0,3})?|(\\.\\d{3})*(\\,\\d{0,3})?|( \\d{3})*(\\,\\d{0,3})?|(\\d{3})*([,.]\\d{0,3})?))$',
    SMBAMOUNT_PATTERN: '^((0*[.,]([1-9]\\d?\\d?|\\d\\d?[1-9]))|[1-9]\\d{0,2}((,\\d{3})*(\\.\\d{0,3})?|(\\.\\d{3})*(\\,\\d{0,3})?|( \\d{3})*(\\,\\d{0,3})?|(\\d{3})*([,.]\\d{0,3})?))$',
    PAMOUNT_PATTERN: '^((0*[.,]([1-9]\\d?\\d?|\\d\\d?[1-9]))|[1-9]\\d{0,2}((,\\d{3})*(\\.\\d{0,3})?|(\\.\\d{3})*(\\,\\d{0,3})?|( \\d{3})*(\\,\\d{0,3})?|(\\d{3})*([,.]\\d{0,3})?))$',
    PAMOUNT_ZERO_PATTERN: '^(0([.,]0{1,3})?|(0*[.,]([1-9]\\d?\\d?|\\d\\d?[1-9]))|[1-9]\\d{0,2}((,\\d{3})*(\\.\\d{0,3})?|(\\.\\d{3})*(\\,\\d{0,3})?|( \\d{3})*(\\,\\d{0,3})?|(\\d{3})*([,.]\\d{0,3})?))$',
    WHOLENUMBER_PATTERN: '[+,-]?[0-9]+',
    UKBACS_PATTERN: '^[A-z0-9\\/\\.\\&\'\\s-]*$',
    UKFP_PATTERN: '^[\x32-\x35\x37-\x64A-z\\r\\n\\{]*$',
    FEDWIRE_PATTERN: '^[\x0A\x0D\x20-\x29\x2B-\x5A\\x5C\x5F-\x7A\x7C\x7E\\r\\n]*$',
    URL_PATTERN: '^(?:(https):\\/\\/)([^:\\/\\s]+)\\.([^:\\/\\s]+)(:[0-9]+)?((?:\\/\\w+)*\\/*)([\\w\\-\\.]+[^#?\\s]+)([^#\\s]*)?(#[\\w\\-]+)?$',
    PHONE_PATTERN: '^\\(?(\\d{3})\\)?[- ]?(\\d{3})[- ]?(\\d{4})$',
    PHONEPLUSONE_PATTERN: '^(\\+?1[- ]?)?\\(?([2-9]\\d\\d)\\)?[- ]?([2-9]\\d\\d)[- ]?(\\d{4})$',
    INTERNATIONAL_PHONE_PATTERN: '^((\\+\\d+|\\+?\\(\\d+\\))[ .-]?)*\\d[\\d .-]{1,20}$',
    PHONE_NUMERIC_PATTERN: '^\\+?[1-9]\\d{1,14}$',
    CHECKFREE_PASSWORD_PATTERN: '^[^)(<>=\\s]+$',
    NOSPACE_PATTERN: '^\\S+$',
    NACHACTXADDENDA_PATTERN: '^[ -)+-.0-\\[\\]-}]*$',
    NOBADCHARS_REASON: '[<>&]',

    getBillPayAccountPattern(accountFormat) {
        const regExpMap = [{
            type: '9',
            expression: '\\d',
        }, {
            type: '\\?',
            expression: '\\w{0,1}',
        }, {
            type: 'X',
            expression: '\\w',
        }, {
            type: 'A',
            expression: '[A-Za-z]',
        }];

        let regExFormat = accountFormat.toUpperCase();

        util.each(regExpMap, (format) => {
            const regex = new RegExp(format.type, 'g');
            regExFormat = regExFormat.replace(regex, format.expression);
        });
        return regExFormat;
    },
};

export default validationPatterns;
