import Model from '@dbiqe/glu-core/src/model';
import $ from 'jquery';
import userInfo from 'etc/userInfo';

const Branding = Model.extend({
    apply(callback) {
        // check for product default
        const userTheme = userInfo.get('theme') || 'client';
        const theme = userTheme === 'bottomline' ? 'client' : userTheme;

        /**
         * values available on the userInfo object include:
         * tenantId: future concept; never implemented
         * marketSegment: intended for structural layout changes; not used
         * theme: configurable via "Visual Theme" in app admin;
         * currently used to display different themes
         * menuLayout: CSS for navigation; consider future refactor/removal
         */

        // Some config options may be changed on login + update styles
        $('head .branding').remove();

        // Attaches the CSS for Theme (from webpack.config)
        $('head').append(`<link rel="stylesheet" href="${window.Bottomline.contentHashes[theme.toLowerCase().split(' ').join('')]}" class="branding">`);

        (callback || $.noop)();
    },
});

export default new Branding();
