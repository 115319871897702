/*
 * For Pinpoint to work properly, there are a few server config params that need to be setup
 * for each client.
 * 1. TrusteerPinpointSnippetURL - a URL for the snippet
 * 2. TrusteerPinpointCallbackName - the name of a function that the snippet invokes
 * 3. TrusteerPinpointGlobalFunctionName - the name of a function we invoke exposed by the snippet
 *
 * Route Tags - branding override
 * Depending on the implementation, different route tags may need to be overridden by branding.
 * Overriding the string paris trusteerPinpointRoutContext will update the routes consumed by
 * routeChange
 */

import util from 'underscore';
import Glu from '@dbiqe/glu-core/src/glu';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { TrusteerPinpointRouteContext } from './trusteerPinpointRouteContext';
import getSession from './util';

/**
 * Get the trusteer tag name for a given route
 * @param {string} route | route to get trusteer tag name for
 * @returns string
 */
const getContext = route => TrusteerPinpointRouteContext[route] || 'other';

/**
 * Get the config object for Pinpoint
 * @returns {Object} config object
 */
const getConfig = () => ({
    pinpointIncludeGroup: serverConfigParams.get('pinpointIncludeGroup'),
    trusteerPinpointCallbackName: serverConfigParams.get('TrusteerPinpointCallbackName'),
    trusteerPinpointDefaultTag: serverConfigParams.get('TrusteerPinpointDefaultTag'),
    trusteerPinpointGlobalFunctionName: serverConfigParams.get('TrusteerPinpointGlobalFunctionName'),
    trusteerPinpointMode: serverConfigParams.get('TrusteerPinpointMode'),
    trusteerPinpointSessionCookieName: serverConfigParams.get('TrusteerPinpointSessionCookieName'),
    trusteerPinpointSnippetURL: serverConfigParams.get('TrusteerPinpointSnippetURL'),
});

/**
 * Get the URL for the Pinpoint snippet
 * @returns {string} URL for Pinpoint snippet
 */
const getSnippetUrl = () => {
    const {
        trusteerPinpointDefaultTag,
        trusteerPinpointSnippetURL,
    } = getConfig();
    return `${trusteerPinpointSnippetURL}?dt=${trusteerPinpointDefaultTag ||
        getContext(window.location.pathname)}&r=${Math.random()}`;
};

/**
 * Determine if the Pinpoint snippet has already been injected
 * @param {string} snippetUrl | string to use as URL for snippet
 * @returns
 */
const isScriptInjected = (snippetUrl) => {
    const scripts = document.getElementsByTagName('script');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src === snippetUrl) {
            return true;
        }
    }
    return false;
};

/**
 * TrusteerPinpointCallback for Pinpoint unified callback function
 * @param {Object} param0
 * @param {Object} param0.mode - whether in 'preauth' or 'postauth'
 * @returns
 */
const pinpointCallback = ({ mode, userInfo }) => () => {
    const {
        pinpointIncludeGroup,
        trusteerPinpointSessionCookieName,
    } = getConfig();
    return {
        // minimum required for Pinpoint is session info
        c: getSession(trusteerPinpointSessionCookieName),
        // if postauth, then include the user info
        ...(mode === 'postauth' ? {
            u: {
                p: [
                    ...(pinpointIncludeGroup === 'true' ? [userInfo.get('group')] : []),
                    userInfo.get('id'),
                ],
            },
        } : {}),
        // if we have a permanent user id, include it
        ...(userInfo.get('permanentUserId') ? {
            p: {
                p: userInfo.get('permanentUserId'),
            },
        } : {}),
    };
};

/**
 * Append the Pinpoint snippet to the head of the document
 * @returns {void}
 */
const appendTrusteerSnippet = () => {
    // if the script is already injected, don't inject it again
    const snippetUrl = getSnippetUrl();
    if (!isScriptInjected(snippetUrl)) {
        const f = document;
        const e = window;
        const i = e.location.protocol;
        const b = [
            ['src', [i === 'https:' ? 'https:/' : 'http:/', snippetUrl].join('/')],
            ['type', 'text/javascript'],
            ['async', true],
        ];
        const c = f.createElement('script');
        const h = f.getElementsByTagName('head')[0];
        setTimeout(() => {
            // eslint-disable-next-line no-plusplus
            for (let d = 0, l = b.length; d < l; d++) {
                c.setAttribute(b[d][0], b[d][1]);
            }
            h.appendChild(c);
        }, 0);
    }
};

const trusteerPinpoint = {
    postAuth(userInfo) {
        const {
            trusteerPinpointCallbackName,
            trusteerPinpointGlobalFunctionName,
            trusteerPinpointSnippetURL,
        } = getConfig();

        if (util.isEmpty(trusteerPinpointSnippetURL)) {
            return;
        }

        // Some Pinpoint integrations do not require this callback
        if (!util.isEmpty(trusteerPinpointCallbackName)) {
            // Make sure on postauth the callback includes user info
            window[trusteerPinpointCallbackName] = pinpointCallback({
                mode: 'postauth',
                userInfo,
            });
        }

        appendTrusteerSnippet();

        // fire off snippet for initial page load
        if (typeof window[trusteerPinpointGlobalFunctionName] === 'function') {
            window[trusteerPinpointGlobalFunctionName](getContext(document.location.pathname));
        }
    },

    preAuth() {
        const {
            trusteerPinpointCallbackName,
            trusteerPinpointGlobalFunctionName,
            trusteerPinpointMode,
            trusteerPinpointSnippetURL,
        } = getConfig();

        if (util.isEmpty(trusteerPinpointSnippetURL) || trusteerPinpointMode !== 'preauth') {
            return;
        }

        // Some Pinpoint integrations do not require this callback
        if (!util.isEmpty(trusteerPinpointCallbackName)) {
            window[trusteerPinpointCallbackName] = pinpointCallback({ mode: 'preauth' });
        }

        appendTrusteerSnippet();

        // fire off login snippet
        if (typeof window[trusteerPinpointGlobalFunctionName] === 'function') {
            window[trusteerPinpointGlobalFunctionName]('login');
        }
    },

    /**
     * When the route changes, invoke the global function created by the Pinpoint
     * snippet, passing in the route tag expected by Pinpoint
     * @param {*} route
     */
    routeChange(route) {
        const {
            trusteerPinpointGlobalFunctionName,
        } = getConfig();
        // NH-150501 Prevent callback from being invoked on logout
        if (route.endsWith('logout')) {
            return;
        }
        // Redirect is a route change that occurs when going from login screen to the application
        if (Glu.history.fragment === 'redirecting') {
            return;
        }
        if (typeof window[trusteerPinpointGlobalFunctionName] !== 'function') {
            return;
        }
        window[trusteerPinpointGlobalFunctionName](getContext(route));
    },
};

export default trusteerPinpoint;
