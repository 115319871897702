var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression;

  return "<form class=\"ColumnRename-form\">\n    <input\n        class=\"ColumnRename-formInput form-control\"\n        data-hook=\"column-rename-input\"\n        type=\"text\"\n        maxlength=\"64\"\n        name=\""
    + alias3(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n        value=\""
    + alias3(container.lambda((depth0 != null ? depth0.inputNameValue : depth0), depth0))
    + "\"\n    />\n    <span class=\"ColumnRename-formErrorMsg help-block\" data-bind=\"model\" data-validate=\"inputedRename\"></span>\n    <div class=\"ColumnRename-formControls\">\n        <button class=\"ColumnRename-formBtn btn btn-sm btn-secondary\" data-action=\"submitRename\">"
    + alias3(helpers.locale.call(alias2,"update",{"name":"locale","hash":{},"data":data}))
    + "</button>\n        <button class=\"ColumnRename-formBtn btn btn-sm btn-tertiary\" data-action=\"close\">"
    + alias3(helpers.locale.call(alias2,"common.cancel",{"name":"locale","hash":{},"data":data}))
    + "</button>\n    </div>\n</form>\n";
},"useData":true});