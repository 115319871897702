import serverConfigParams from 'system/webseries/models/configurationParameters';
import { appendScript } from '../interfaces/util';

const snowChat = {
    serverConfigParamsReady() {
        const existing = document.getElementById('snowchat');
        if (!existing) {
            const src = serverConfigParams.get('snowChatScriptUrl');
            const moduleID = serverConfigParams.get('snowChatModuleIdUrl');

            if (src && moduleID) {
                appendScript(
                    src,
                    {
                        async: false,
                        id: 'snowchat',
                        onLoad() {
                            window.SN_CSM_EC.init({
                                moduleID,
                                loadFeature: window.SN_CSM_EC.loadEMFeature(),
                            });
                        },
                    },
                );
            }
        }
    },

    postAuth() {
        window.SN_CSM_EC?.onLogin();
    },

    preLogout() {
        window.SN_CSM_EC?.onLogout();
    },
};

export default snowChat;
