import { formActions } from '@dbiqe/mdf';

/**
 * ACH Warehouse is unique in the fact that it is an MDF form that is rendered directly in a
 * workspace widget. Because of that, we can be in a state where we navigate directly into a second
 * form without being able to properly reset form state so you can end up with:
 *   1. The new form quickly rendering the previous ACH form state before rendering the proper form.
 *   2. The previous ACH Warehouse state causing improper setup due to lingering values.
 *
 * This middleware is designed to reset the form state when we navigate away from ACH Warehouse.
 * The checks below account for only resetting state when:
 *   1. It is the PAGECONTEXT_ADD_ACTION_DATE action.
 *   2. AND the action data is NOT for ACH Warehouse Inquiry
 *   3. AND the current state has ACH Warehouse data.
 *
 * @param storeApi
 */
const achWarehouseInquiry = storeApi => next => (action) => {
    const hasAchWarehouseData = storeApi.getState().pageContext?.actionData?.functionCode === 'ACHWHINQ';
    const isAchWarehouseActionData = action.payload?.actionData?.contextKey === 'achWarehouseInquiry';

    if (action.type !== 'PAGECONTEXT_ADD_ACTION_DATA' || isAchWarehouseActionData || !hasAchWarehouseData) {
        return next(action);
    }

    storeApi.dispatch(formActions.resetFormState({
        pageContextKeysToKeep: [
            'baseModuleRoute',
            'currentRoute',
            'entryRoute',
            'exitRoute',
            'previousRoute',
            /*
             * userCompany is a special key used by user-maintenance
             * Since ACH Warehouse is an MDF form rendered in a workspace widget
             * there is the chance that it can be alongside user-maintenance.
             * This makes sure that if someone used ACH Warehouse on the same screen
             * as the user-maintenance widget, the userCompany key is not removed.
             */
            'userCompany',
        ],
    }));
    return next(action);
};

export default achWarehouseInquiry;
