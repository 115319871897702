import { locale } from '@glu/core';

locale.set({
  // it was for datePicker1
  afterCutoff: 'Invalid date - after cutoff',
  blockedDate: 'Invalid date - blocked',
  dailyLimit: 'Daily view limited to 1 year',
  dateRequired: 'Date required',
  historyLimit: 'History is limited to 7 years',
  invalidDateFormat: 'Invalid date format',
  invalidDateRange: 'Start date must be before end date',
  tooFarFuture: 'The date selected is too far in the future',
  tooFarPast: 'The date selected is before the first allowed date',
  // it was for datePicker1

  // These items below, added after review of all possible ranges from old datePicker, and DGB LOB codebase.
  rangeToday: 'Today',
  rangeYesterday: 'Yesterday',
  rangeLast7Days: 'Last 7 Days',
  rangeLast30Days: 'Last 30 Days',
  rangeLast60Days: 'Last 60 Days',
  rangeLast90Days: 'Last 90 Days',
  rangeThisMonth: 'This Month',
  rangeMonthToDate: 'Month to date',
  rangeLastMonth: 'Last Month',
  rangeQuarterToDate: 'Quarter to date',
  rangeYearToDate: 'Year to date',
  rangeDateAndTime: 'Date and Time'
});

