import cookie from 'system/cookie';
import userInfo from 'etc/userInfo';

/**
 * Determine if a snippet has already been injected
 * @param {string} snippetUrl | string to use as URL for snippet
 * @returns
 */
export const isScriptInjected = (snippetUrl) => {
    const scripts = document.getElementsByTagName('script');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src === snippetUrl) {
            return true;
        }
    }
    return false;
};

export const appendScript = (
    path,
    {
        async = false,
        crossorigin = false,
        id,
        onLoad = () => {},
    } = {},
) => {
    const script = document.createElement('script');
    script.addEventListener('load', onLoad);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', path);
    if (async) {
        script.setAttribute('async', '');
    }
    if (crossorigin) {
        script.setAttribute('crossorigin', crossorigin);
    }
    if (id) {
        script.setAttribute('id', id);
    }
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
};

const getSession = cookieName => (cookieName && cookie.get(cookieName))
    || userInfo.get('token');

export default getSession;
