export const ACTIONS = {
    API_FAILURE: 'API_FAILURE',
    GET_ENTITLEMENT_SUCCESS: 'GET_ENTITLEMENT_SUCCESS',
    GET_VENDORS_SUCCESS: 'GET_VENDORS_SUCCESS',
    SET_LOADING: 'SET_LOADING',
    SET_OPEN_MODAL: 'SET_OPEN_MODAL',
    SET_UPDATING: 'SET_UPDATING',
};

export const ASCENDING = 'asc';
export const BENE_NAME = 'BENE_NAME';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const REPORTING_TAB_ANCHOR = 'a[id="gmp-REPORTING"]';
export const TNUM = 'TNUM';

export const USER_ENTITLEMENTS = {
    MODIFY: 'MODIFY',
    VIEW: 'VIEW',
};

export const VENDOR_STATUS = {
    ACTIVE: 'AT',
    INACTIVE: 'IN',
};
