import buttons from './buttons';
import datepickerReact from './datepickerReact';
import dbiqeMicrosite from './dbiqeMicrosite';
import dbiqeStopPayments from './dbiqeStopPayments';
import icons from './icons';
import modal from './modal';

export default {
    buttons,
    datepickerReact,
    dbiqeMicrosite,
    dbiqeStopPayments,
    icons,
    modal
};
