import Layout from '@dbiqe/glu-core/src/layout';
import util from 'underscore';

/**
 * Stack view
 *
 * Supports pushing and popping views, prevent reinstantiating parent views
 * after drilling down.
 */
export default Layout.extend({
    template: () => '',

    initialize(options) {
        this.stack = [];
        this.initItem = options.initItem;
        if (this.initItem) {
            this.initItem.stack = this;
        }
    },

    onRender() {
        if (this.initItem) {
            this.push(this.initItem);
        }
    },

    onClose() {
        this.stack.forEach((stackItem) => {
            stackItem.view.close();
        });
        this.stack = [];
    },

    getCurrentView() {
        return this.stack[this.stack.length - 1].view;
    },

    push(view) {
        // give views a pointer to the stack they are in
        const callingView = view;
        callingView.stack = this;

        // hide current top (if exists)
        if (this.stack.length > 0) {
            const current = this.stack[this.stack.length - 1];
            // Get current offsets before hiding
            current.scrollX = window.scrollX;
            current.scrollY = window.scrollY;
            current.div.hide();
        }

        // push to stack and show new view
        const $viewEl = view.render().$el.appendTo(this.$el);

        this.stack.push({
            div: $viewEl,
            view,
        });
    },

    /**
     * Adding where parameter to pop function to allow for popping to a specific view
     * @param {Object} [where] - properties to match against the stack item
     */
    pop(where) {
        this.stack.pop()?.view?.close();

        // pop until we find a match
        while (where && this.stack.length > 1 && !util.isMatch(this.stack.view, where)) {
            this.stack.pop()?.view?.close();
        }

        if (this.stack.length > 0) {
            const current = this.stack[this.stack.length - 1];
            current.div.show();
            if (current.scrollX !== undefined) {
                // Restore viewport offsets
                window.scrollTo({
                    left: current.scrollX,
                    top: current.scrollY,
                    behavior: 'instant',
                });
            }

            /*
             *  trigger event when redisplaying past view in stack
             *  passing the current view after popping.
             */
            this.appBus.trigger('stackView:pop', this.stack[this.stack.length - 1]);
        }
    },
});
