import { ItemView, $ } from '@dbiqe/glu-core';

export default ItemView.extend({
    tagName: 'td',

    /**
     * @param {Object} data
     * @returns {string}
     */
    template(data) {
        return data.value;
    },

    /**
     * @param {Object} options
     */
    initialize(options) {
        this.options = options;
        this.model = options.model;
        this.column = options.column;
        ItemView.prototype.initialize.call(this, options);
        this.el.dataset.title = this.options.value ? this.getDataTitle() : '';
    },

    /**
     * data title logic extracted from row.js
     * @returns {jQuery}
     */
    getDataTitle() {
        const val = this.options.value;
        let dataTitle = val || '';
        if (this.options.safe === false) {
            try {
                dataTitle = $(val).text();
            } catch (e) {
                dataTitle = val;
            }
        }
        return dataTitle;
    },

    /**
     * className passed in from row.js itemViewOptions
     * @returns {string}
     */
    className() {
        return this.options.className;
    },

    /**
     * @returns {{value: string}}
     */
    templateHelpers() {
        return {
            value: this.options.value,
        };
    },
});
