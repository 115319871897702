// TODO. No idea how? But jQuery UI 1.12.1 still the latest and has issues with ESM
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/data';
import 'jquery-ui/ui/plugin';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';

import { Layout, Collection, CollectionView, $document, locale, util } from '@glu/core';
import GluIcons from '@glu/icons';
import ListBuilderItemView from './listBuilderItemView';
import './localeStrings';
import template from './listBuilderLayout.hbs';

const fuzzyMatch = (haystack, needle) => {
  if (needle.length === 0) {
    return true;
  }
  const index = haystack.indexOf(needle[0]);
  if (index === -1) {
    return false;
  }
  return fuzzyMatch(haystack.slice(index + 1), needle.slice(1));
};

const exactMatch = (haystack, needle) => haystack.indexOf(needle) !== -1;

const matchers = {
  exact: exactMatch,
  fuzzy: fuzzyMatch
};

const gluIconsInstance = new GluIcons({});
gluIconsInstance.registerIconHelper();

export default Layout.extend({
  template,
  className: 'list-builder',

  ui: {
    sourceListSearchFilter: '.source-list-search-filter',
    sourceListSearchFilterToggle: '.source-list-search-filter-toggle',
    moveSelectedItems: '[data-action="move-selected-items"]',
    moveAllItems: '[data-action="move-all-items"]',
    removeSelectedItems: '[ data-action="remove-selected-items"]',
    removeAllItems: '[data-action="remove-all-items"]',
    clearSourceSelection: '[data-action="clear-source-selection"]',
    clearTargetSelection: '[data-action="clear-target-selection"]'
  },

  events: {
    'click @ui.sourceListSearchFilterToggle': 'toggleFilter',
    'keyup @ui.sourceListSearchFilter': 'applySearchFilter',
    'click @ui.moveSelectedItems': 'moveSelectedItems',
    'click @ui.moveAllItems': 'moveAllItems',
    'click @ui.removeSelectedItems': 'removeSelectedItems',
    'click @ui.removeAllItems': 'removeAllItems',
    'click @ui.clearSourceSelection': 'clearSourceSelection',
    'click @ui.clearTargetSelection': 'clearTargetSelection',
    'dblclick .list-builder-item': 'moveItemBetweenCollections'
  },

  initialize(options) {
    // 'fuzzy' (default), 'exact', or pass a custom filter.
    this.matchType = options.matchType || 'fuzzy';
    this.matcher = matchers[options.matchType] || options.matchType || matchers.fuzzy;
    this.labelView = options.labelView;
    this.sourceCollection = options.dataPersist ? options.sourceCollection : this.setLocalCollection(options.sourceCollection);
    this.targetCollection = options.dataPersist ? options.targetCollection : this.setLocalCollection(options.targetCollection);
    this.text = this.setText(options);

    util.bindAll(this, 'onDragAndDrop');

    this.setSourceCollectionIndices();

    this.listenTo(this.targetCollection, 'add remove reset', this.targetCollectionUpdated);
    this.listenTo(this.sourceCollection, 'add remove reset', this.sourceCollectionUpdated);

    this.sourceList = this.buildListCollectionView('source-list', this.sourceCollection);
    this.targetList = this.buildListCollectionView('target-list', this.targetCollection);
  },

  templateHelpers() {
    return {
      cid: this.cid,
      moveSelectedItems: this.text.moveSelectedItems,
      moveAllItems: this.text.moveAllItems,
      removeSelectedItems: this.text.removeSelectedItems,
      removeAllItems: this.text.removeAllItems,
      filter: this.text.filter,
      sourceListHeader: this.text.sourceListHeader,
      targetListHeader: this.text.targetListHeader,
      clearSourceSelection: this.text.clearSourceSelection,
      clearTargetSelection: this.text.clearTargetSelection,
      isFuzzy: this.matchType === 'fuzzy',
      isExact: this.matchType === 'exact'
    };
  },

  onRender() {
    this.sortLists();
  },

  setSourceCollectionIndices() {
    this.sourceCollection.each((model, i) => {
      model.set('sourceIndex', i);
    });

    if (!this.sourceCollection.comparator) {
      this.sourceCollection.comparator = 'sourceIndex';
    }
  },

  buildListCollectionView(className, collection) {
    return new CollectionView({
      tagName: 'ul',
      className,
      collection,
      itemView: ListBuilderItemView,
      itemViewOptions: {
        layout: this,
        labelView: this.labelView
      }
    });
  },

  targetCollectionUpdated() {
    this.trigger('targetListUpdated', this.targetCollection.toJSON());
  },

  sourceCollectionUpdated() {
    this.trigger('sourceListUpdated', this.sourceCollection.toJSON());
  },

  getSourceList() {
    return this.sourceCollection.toJSON();
  },

  getTargetList() {
    return this.targetCollection.toJSON();
  },

  /**
   * Merge in defaults for any unspecified values in options.text
   * @param {object} options
   */
  setText(options) {
    options.text = options.text || {};
    return util.extend(options.text, {
      moveSelectedItems: options.text.moveSelectedItems || locale.get('moveSelectedItems'),
      moveAllItems: options.text.moveAllItems || locale.get('moveAllItems'),
      removeSelectedItems: options.text.removeSelectedItems || locale.get('removeSelectedItems'),
      removeAllItems: options.text.removeAllItems || locale.get('removeAllItems'),
      filter: options.text.filter || locale.get('filter'),
      sourceListHeader: options.text.sourceListHeader || locale.get('sourceListHeader'),
      targetListHeader: options.text.targetListHeader || locale.get('targetListHeader'),
      clearSourceSelection: options.text.clearSourceSelection || locale.get('clearSourceSelection'),
      clearTargetSelection: options.text.clearTargetSelection || locale.get('clearTargetSelection')
    });
  },

  /**
   * Clone the passed collection,
   *  create a new one from the source array,
   *  or create a new one by explicitly passing undefined
   * @param {Collection|Array} [collection]
   * @return {*}
   */
  setLocalCollection(collection) {
    if (util.isUndefined(collection) || util.isArray(collection)) {
      return new Collection(collection || []);
    }
    return collection.clone();
  },

  initializeDragAndDrop() {
    const self = this; // todo research if we can avoid self, and use =>
    this.$('.list-builder-item').draggable({
      helper: 'clone',
      appendTo: this.options.id ? `#${this.options.id}` : '.list-builder', // to avoid issue with appending ListBuidler to wrong DOM element
      start(event, ui) {
        ui.helper.width(self.$(event.target).width());
        ui.helper.height(self.$(event.target).height());
        self.$(event.target).hide();
      },
      stop(event) {
        self.$(event.target).show();
      }
    });

    this.$('.target-list, .source-list').droppable({
      drop(event, ui) {
        self.onDragAndDrop(ui.draggable, self.$(this));
      },
      accept(draggable) {
        return self.isDropAccepted(draggable, self.$(this));
      },
      activeClass: 'ui-state-active',
      hoverClass: 'drop-hover',
      greedy: true
    });

    this.$('.target-list .list-builder-item').droppable({
      drop(event, ui) {
        self.onDragAndDrop(ui.draggable, self.$(this));
      },
      accept(draggable) {
        return self.isDropAccepted(draggable, self.$(this));
      },
      activeClass: 'ui-state-active',
      hoverClass: 'drop-hover',
      greedy: true
    });
  },

  /**
   * Move elements between or within lists.
   * @param {jQuery} draggable
   * @param {jQuery} droppable
   */
  onDragAndDrop(draggable, droppable) {
    // TODO: Switch to more concise function commented out below
    //  after making unit tests to ensure successful refactor
    // const fromSource =
    //   draggable.hasClass('source-list') ||
    //   draggable.parent().hasClass('source-list');
    // const toSource =
    //   droppable.hasClass('source-list') ||
    //   droppable.parent().hasClass('source-list');
    // const fromCollection = fromSource ? this.sourceCollection : this.targetCollection;
    // const toCollection = toSource ? this.sourceCollection : this.targetCollection;
    // const item = fromCollection.get(draggable.data('itemId'));
    // const options = toSource ? undefined : {
    //   at: droppable.index(),
    // };
    //
    // fromCollection.remove(item);
    // toCollection.add(item, options);
    // this.sortLists();

    let item;

    if (draggable.parent().hasClass('target-list')) {
      item = this.targetCollection.get({ cid: draggable.data('itemId') });
    } else {
      item = this.sourceCollection.get({ cid: draggable.data('itemId') });
    }

    if (droppable.is('ul')) {
      if (droppable.hasClass('target-list')) {
        if (draggable.parent().hasClass('source-list')) {
          this.sourceCollection.remove(item);
          this.targetCollection.add(item);
        } else {
          this.targetCollection.remove(item);
          this.targetCollection.add(item);
        }
      } else {
        this.targetCollection.remove(item);
        this.sourceCollection.add(item);
      }
    } else if (droppable.parent().hasClass('target-list')) {
      if (draggable.parent().hasClass('target-list')) {
        this.targetCollection.remove(item);
        this.targetCollection.add(item, {
          at: droppable.index()
        });
      } else {
        this.sourceCollection.remove(item);
        this.targetCollection.add(item, {
          at: droppable.index()
        });
      }
    } else if (draggable.parent().hasClass('source-list')) {
      this.sourceCollection.remove(item);
      this.sourceCollection.add(item);
    } else {
      this.targetCollection.remove(item);
      this.sourceCollection.add(item);
    }

    this.sortLists();
  },

  /**
   * TODO: Make this configurable
   * @return {boolean}
   */
  isDropAccepted() {
    return true;
  },

  /**
   *
   * @param {Event} e
   */
  moveItemBetweenCollections(e) {
    const item = $document.find(e.currentTarget);
    const itemId = item.data('itemId');
    let collectionItem;

    if (item.parent().hasClass('source-list')) {
      collectionItem = this.sourceCollection.get({ cid: itemId });
      this.sourceCollection.remove(collectionItem);
      this.targetCollection.add(collectionItem);
    } else {
      collectionItem = this.targetCollection.get({ cid: itemId });
      this.targetCollection.remove(collectionItem);
      this.sourceCollection.add(collectionItem);
    }

    this.sortLists();
  },

  /**
   * Open and close Source Filter
   * @param {Event} e
   * @return {boolean}
   */
  toggleFilter(e) {
    e.stopPropagation();
    this.ui.sourceListSearchFilter.toggleClass('is-open');
    this.applySearchFilter();
    return false;
  },

  applySearchFilter() {
    const $filter = this.ui.sourceListSearchFilter;
    const filterText = ($filter.hasClass('is-open') ? $filter.val().trim() : '').toLowerCase();

    /**
     * The matcher is passed element text and filterText.
     * Eventually, we may pass the entire model to allow
     *  custom matchers to access any attributes.
     */
    this.sourceList.$el.children().each((index, element) => {
      const $element = this.$(element);
      const matches = this.matcher($element.text().toLowerCase(), filterText);
      $element.toggleClass('is-filtered-out', !matches);
    });
  },

  clearSourceSelection() {
    this.sourceCollection.each((item) => {
      item.unset('listBuilder:isSelected');
    });
    this.toggleControls();
  },

  moveSelectedItems() {
    const items = this.sourceCollection.where({
      'listBuilder:isSelected': true
    });
    this.targetCollection.add(items);
    this.sourceCollection.remove(items);
    this.sortLists();
  },

  moveAllItems() {
    const items = this.sourceCollection.slice(0);
    this.targetCollection.add(items);
    this.sourceCollection.remove(items);
    this.sortLists();
  },

  clearTargetSelection() {
    this.targetCollection.each((item) => {
      item.unset('listBuilder:isSelected');
    });
    this.toggleControls();
  },

  removeSelectedItems() {
    const items = this.targetCollection.where({
      'listBuilder:isSelected': true
    });
    this.sourceCollection.add(items);
    this.targetCollection.remove(items);
    this.sortLists();
  },

  removeAllItems() {
    const items = this.targetCollection.slice(0);
    this.sourceCollection.add(items);
    this.targetCollection.remove(items);
    this.sortLists();
  },

  toggleControls() {
    const selectedSource = this.sourceCollection.where({
      'listBuilder:isSelected': true
    });

    const selectedTarget = this.targetCollection.where({
      'listBuilder:isSelected': true
    });

    const showSourceControls = !selectedSource.length;
    this.ui.clearSourceSelection.toggle(!showSourceControls);
    this.ui.moveSelectedItems.toggle(!showSourceControls);
    this.ui.moveAllItems.toggle(showSourceControls);

    const showTargetControls = !selectedTarget.length;
    this.ui.clearTargetSelection.toggle(!showTargetControls);
    this.ui.removeSelectedItems.toggle(!showTargetControls);
    this.ui.removeAllItems.toggle(showTargetControls);

    this.ui.moveAllItems.prop('disabled', !this.sourceCollection.length);
    this.ui.removeAllItems.prop('disabled', !this.targetCollection.length);
  },

  sortLists() {
    if (this.sourceCollection.comparator) {
      this.sourceCollection.sort();
    }
    if (this.targetCollection.comparator) {
      this.targetCollection.sort();
    }
    this.sourceListRegion.show(this.sourceList);
    this.targetListRegion.show(this.targetList);

    this.toggleControls();
    this.initializeDragAndDrop();
    this.applySearchFilter();
  }
});
