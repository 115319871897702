import services from 'system/servicesList';
import serviceUtil from 'common/util/services';

const getQueryResults = (options) => {
    const data = {
        queryCriteria: {
            subTypeCode: options.subTypeCode,
            fieldName: options.fieldName,
            entryClass: options.entryClass,
            allowDuplicates: options.allowDuplicates,

            action: {
                typeCode: options.typeCode,
                entryMethod: options.entryMethod,
                productCode: options.productCode,
                actionMode: options.actionMode,
                functionCode: options.functionCode,
            },
        },
    };

    return data;
};
const manipulateData = (result) => {
    const returnArray = result.queryResponse.QueryData.queryRows.map((rowItem) => {
        const fieldsToInclude = ['OPERATION', 'UNIT', 'DURATION_FROM_TODAY'];
        // May need these defaults in the destructuring.
        const {
            OPERATION: operation = [],
            UNIT: unit = [],
            DURATION_FROM_TODAY: duration = [],
        } = rowItem.mapDataList.reduce((acc, cur) => {
            // Early return for no match
            if (!fieldsToInclude.includes(cur.toField)) {
                return acc;
            }
            // Get existing or empty values array
            return {
                ...acc,
                [cur.toField]: [cur.value],
            };
        }, {});

        const obj = {
            id: rowItem.name,
            label: rowItem.label,
            operation: operation[0],
            unit: unit[0],
            duration: duration[0],
        };
        return obj;
    });
    return returnArray;
};
const getDateRanges = (options) => {
    const data = {
        includeMapData: 1,
        ...options,
    };

    return serviceUtil.postData(services.checkInquiryURL, getQueryResults(data))
        .then(response => manipulateData(response));
};

export default { getDateRanges, manipulateData };
