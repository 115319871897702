import CollectionView from 'no-override!@dbiqe/glu-core/src/collectionView';
import Marionette from 'backbone.marionette';
import notificationManager from 'system/notifications/manager';

export default CollectionView.extend({
    constructor(...args) {
        this._notificationsInterface = notificationManager.notificationFunctions();

        CollectionView.apply(this, args);
    },

    getTemplate() {
        if (this.loadingTemplate && this.hasLoadedRequiredData() === false) {
            return this.loadingTemplate;
        } else {
            return Marionette.getOption(this, 'template');
        }
    },

    onBeforeRender() {
        this._notificationsInterface.open(this._notificationData);
    },

    onBeforeClose() {
        this._notificationsInterface.close(this._notificationData);
    },

    setHasLoadedRequiredData(bool) {
        this.hasLoaded = bool;
    },

    hasLoadedRequiredData() {
        return (this.hasLoaded ? this.hasLoaded : false);
    },

    setNotificationData(data) {
        this._notificationData = data;
    },
});
