import ItemView from '@dbiqe/glu-core/src/itemView';
import store from 'system/utilities/cache';
import configuration from 'system/configuration';
import tmpl from './base.hbs';

export default ItemView.extend({
    template: tmpl,

    templateHelpers() {
        return {
            title() {
                const menuModel = store.get('menuModel');
                let path = `${window.location.href}`;
                path = path.slice(path.indexOf(configuration.appRoot)
                    + configuration.appRoot.length);
                const [primaryKey] = path.split('/');
                const secondaryKey = path.slice(path.indexOf('/') + 1);

                const menu = menuModel.get('modules');

                for (let i = 0; i < menu.length; i += 1) {
                    const { components } = menu[i];
                    for (let j = 0; j < components.length; j += 1) {
                        if (components[j].key.toUpperCase() === primaryKey.toUpperCase()) {
                            const { actions } = components[j];
                            for (let k = 0; k < actions.length; k += 1) {
                                if (actions[k].key.toUpperCase()
                                    === secondaryKey.toUpperCase()) {
                                    return actions[k].name;
                                }
                            }
                        }
                    }
                }

                return '404';
            },
        };
    },
});
