// TODO: remove the comment below once latest is in GLU 3.X
// enableStickySelectedRows, preSelectedRowIds, rowIdColumn props is only supported together
// with GLU 1.8.59 since this version has latest Grid updates

import { appBus, Collection } from '@glu/core';
import Backbone from 'backbone';
import GridView from '@glu/grid';
import React, { Component } from 'react';
import gridPropTypes from './gridPropTypes';
import ViewWrapper from './ViewWrapper';

const ExternalPagingCollection = Collection.extend({
  gridFilter(filters, callback, filterOptions) {
    this.onPagingChange({ filters, filterOptions });
  },

  gridPaginate(pageNumber, pageSize) {
    this.onPagingChange({ pageNumber, pageSize });
  },

  gridSort(sortField, sortOrder) {
    this.onPagingChange({ sortField, sortOrder });
  }
});

const createGridView = (props, state, resetCollection) => {
  if (resetCollection) {
    state.collection.reset(props.data);
  }

  const gridOptions = Object.keys(props).reduce((memo, key) => {
    if (key === 'initialUserState' || key === 'userState') {
      return { ...memo, state: props[key], trackState: true };
    }

    return { ...memo, [key]: props[key] };
  }, { collection: state.collection });

  return {
    gridView: new GridView(gridOptions),
    columnJSON: JSON.stringify(props.columns),
    rowSubViewOptionsJSON: JSON.stringify(props.rowSubViewOptions)
  };
};

function onBeforeGridUnmount(gridView) {
  gridView.off('change:state');
}

function initCollection(props) {
  if (!props.onPagingChange) {
    return new Collection(props.data);
  }

  const collection = new ExternalPagingCollection(props.data);
  collection.onPagingChange = props.onPagingChange;

  return collection;
}

/**
 * This is a wrapper for the Glu Grid that is intended to provide a solid React API
 *
 * Please reach out to Ben Valyou and Allen Gevry if your use case isn't covered.
 *
 * See gridPropTypes for details of the API
 */
class Grid extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const rowSubViewOptionsChanged = (
      JSON.stringify(nextProps.rowSubViewOptions) !== prevState.rowSubViewOptionsJSON
    );
    const remakeGrid = (
      JSON.stringify(nextProps.columns) !== prevState.columnJSON || rowSubViewOptionsChanged
    );

    if (remakeGrid) {
      const {
        gridView, columnJSON, rowSubViewOptionsJSON
      } = createGridView(nextProps, prevState, true);
      return { gridView, columnJSON, rowSubViewOptionsJSON };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const collection = initCollection(props);
    const { gridView, columnJSON, rowSubViewOptionsJSON } = createGridView(props, { collection });

    if (gridView.selectMatchedRows) {
      gridView.selectMatchedRows(props.preSelectedRowIds, props.rowIdColumn);
    }

    this.state = {
      collection,
      columnJSON,
      rowSubViewOptionsJSON,
      gridView
    };

    this.onGridMount = this.onGridMount.bind(this);
  }

  componentDidMount() {
    this.listenTo(appBus, 'grid:selectRow', this.onRowSelected);
    this.listenTo(appBus, 'grid:selectAllRows', this.onAllRowsSelected);
  }

  componentDidUpdate(prevProps) {
    Object.keys(this.props).forEach(key => {
      if (this.props[key] !== prevProps[key]) {
        this.updateGridView(key, this.props, prevProps);
      }
    });

    if (this.updateSelections) {
      this.state.gridView.updateSelections();
      this.updateSelections = false;
    }
  }

  componentWillUnmount() {
    this.stopListening();
  }

  onAllRowsSelected(isSelected, gridCid) {
    const rows = [];
    const grid = this.state.gridView;

    if (gridCid === grid.cid) {
      grid.collection.each(model => {
        if (model.gridRowSelected) {
          rows.push(model);
        }
      });
      this.props.onAllRowsSelected(rows);
    }
  }

  onRowSelected(row, selected) {
    if (row.gridCid && row.gridCid === this.state.gridView.cid) {
      this.props.onRowSelected(row, selected);
    } else if (!row.gridCid) {
      this.props.onRowSelected(this.state.gridView.getSelectedRowModels()[0]);
    }
  }

  onGridMount(gridView) {
    if (this.props.onUserStateChange) {
      gridView.on('change:state', this.props.onUserStateChange);
    }

    if (this.props.isLoading) {
      gridView.tableView.showLoadingView();
    }
  }

  updateGridView(key, nextProps) {
    switch (key) {
      // Handle updates to the data, this will cause the grid to rerender and display the new data
      case 'data':
        this.state.collection.reset(nextProps[key]);
        break;

      case 'preSelectedRowIds':
        this.updateSelections = true;
        if (nextProps[key] && nextProps[key].length) {
          // eslint-disable-next-line
          this.state.gridView.processPreSelections(nextProps[key], nextProps.rowIdColumn);
        } else {
          // eslint-disable-next-line
          this.state.gridView.clearSelectedRows();
        }
        break;

        // Updates the text displayed when no entries are present
      case 'emptyViewText':
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.gridView.tableView.options.emptyViewText = nextProps[key];
        this.state.gridView.tableView.toggleEmptyView();
        break;

        /*
         * When loading data, use this to display the spinner.
         * Couple with an empty data prop to show ONLY the spinner.
         * The docs recommend using events on the collection for this logic,
         * but they are unreliable, so using straight function calls
         */
      case 'isLoading':
        if (nextProps[key]) {
          this.state.gridView.tableView.showLoadingView();
        } else {
          this.state.gridView.tableView.hideInfoView();
          this.state.gridView.tableView.toggleEmptyView();
        }
        break;

      case 'userState':
        this.state.gridView.loadStateObject(nextProps[key]);
        break;
      default:
    }
  }

  render() {
    return (
      <ViewWrapper
        view={this.state.gridView}
        onMount={this.onGridMount}
        onBeforeUnmount={onBeforeGridUnmount}
      />
    );
  }
}

Grid.propTypes = gridPropTypes;

Grid.defaultProps = {
  onRowSelected: () => {},
  onAllRowsSelected: () => {}
};

// appBus events are necessary here, but should not be used anywhere else. Do not replicate
Object.assign(Grid.prototype, Backbone.Events);

export default Grid;
