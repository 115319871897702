import GluFlexDropdown from '@glu/flex-dropdown';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Region } from 'backbone.marionette';
import { withStyles } from '@glu/theming';
import { CaretDownIcon } from '@glu/icons-react';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import validatedComponentPropTypes from './validatedComponentPropTypes';
import withNewValidation from './withNewValidation';

const styles = ({ palette, typography }) => ({
  root: {
    '& svg.icon-caret-down': {
      fill: palette.text.dark,
      pointerEvents: 'none',
      position: 'absolute',
      right: 10,
      bottom: ({ options }) => (!options ? 13 : 8)
    },
    '& .flex-dropdown-wrapper .flex-dropdown': {
      width: '100%'
    },
    '& .flex-dropdown-wrapper label': {
      display: 'block'
    },
    '& .flex-dropdown-wrapper .flex-dropdown .selection .caret': {
      display: 'none'
    },
    '& .flex-dropdown-wrapper .flex-dropdown .selection': {
      boxShadow: 'none',
      background: palette.background,
      border: `1px solid ${palette.accent}`,
      borderRadius: 2,
      color: palette.text.dark,
      fontFamily: typography.fontFamily,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1,
      padding: [6, 30, 6, 10],
      minHeight: 34,

      '& .flex-dropdown-wrapper .flex-dropdown .list .item-list li': {
        color: palette.text.dark
      }
    },
    '& .flex-dropdown-wrapper .flex-dropdown .selection ul.selected-items': {
      lineHeight: '1rem'
    },
    '& .flex-dropdown-wrapper .flex-dropdown .selection .selected': {
      margin: 0
    }
  },
  iconWrapper: {
    position: 'relative'
  },
  disabled: {
    '& svg': {
      fill: `${palette.accent} !important`
    }
  }
});

class FlexDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.currentSelection = Array.isArray(this.props.value) ? this.props.value.slice() :
      this.props.value;
    this.onChange = this.onChange.bind(this);
    this.initDropdown(props);

    this.assignDropdownContainer = this.assignDropdownContainer.bind(this);
    this.dropdownChanged = this.dropdownChanged.bind(this);
    this.dropdownLoaded = this.dropdownLoaded.bind(this);
  }

  componentDidMount() {
    this.dropdownRegion = new Region({ el: this.dropdownContainer });

    this.dropdownRegion.show(this.dropdownView);

    this.dropdownView.on('dataLoaded', this.dropdownLoaded);
    this.dropdownView.on('selectionChanged:id', this.dropdownChanged);
  }

  componentDidUpdate(prevProps) {
    Object.keys(this.props).forEach(key => {
      if (!isEqual(this.props[key], prevProps[key])) {
        this.updateDropdownView(key, this.props);
      }
    });
  }

  componentWillUnmount() {
    this.dropdownView.off('dataLoaded', this.dropdownLoaded);
    this.dropdownView.off('selectionChanged:id', this.dropdownChanged);

    this.dropdownRegion.reset();
    this.dropdownRegion.close();
  }

  onChange(name, value, initial) {
    if (!isEqual(this.currentSelection, value)) {
      this.currentSelection = Array.isArray(value) ? value.slice() : value;
      this.props.onChange(name, value);

      if (!initial) {
        this.props.onBlur(name, value);
      }
    }
  }

  initDropdown(props) {
    const dropdownProps = Object.keys(props).filter(key => ['error', 'onChange', 'onBlur'].indexOf(key) < 0)
      .reduce((memo, key) => {
        if (key === 'data') {
          return memo;
        }

        if (key === 'options') {
          const data = cloneDeep(props.options);
          return { ...memo, data };
        }

        if (key === 'clearButton') {
          return { ...memo, clearBtn: props.clearButton, [key]: props[key] };
        }

        if (key === 'className') {
          return memo;
        }

        return { ...memo, [key]: props[key] };
      }, { preSelectedIds: props.value || props.defaultValue });

    this.dropdownView = new GluFlexDropdown(dropdownProps);
  }

  assignDropdownContainer(element) {
    this.dropdownContainer = element;
  }

  dropdownLoaded(dropdown) {
    if (this.ignoreDataLoaded) {
      this.ignoreDataLoaded = false;
      return;
    }

    this.onChange(this.props.name, this.props.multiSelect ? dropdown.value('id') : dropdown.value('id')[0], true);
  }

  dropdownChanged(selection) {
    this.onChange(this.props.name, this.props.multiSelect ? selection : selection[0]);
  }

  updateDropdownView(key, nextProps) {
    switch (key) {
      case 'value':
        this.dropdownView.setSelectedIds(nextProps[key]);
        break;

      case 'options':
        this.ignoreDataLoaded = true;
        this.dropdownView.setData(nextProps[key]);
        break;

        // These should not change, and changing is not supported currently.
      case 'label':
      case 'allowConsecutiveSelections':
      case 'clearButton':
      case 'customParse':
      case 'customView':
      case 'defaultSelectMessage':
      case 'disableClear':
      case 'disabled':
      case 'disableMultiButton':
      case 'dynamicUrl':
      case 'filter':
      case 'filterMethod':
      case 'model':
      case 'multiSelect':
      case 'preSelected':
      case 'preSelectedIds':
      case 'preSelectedNames':
      case 'preSelectedNewItems':
      case 'preSelectFirstItem':
      case 'selectAllButton':
      case 'showCancelIcon':
      case 'showGroups':
      case 'showSelected':
      case 'showTooltip':
      case 'tableView':
      case 'url':
        throw new Error(`Invalid prop changed: \`${key}\``);

      default:
    }
  }

  render() {
    const {
      error, validators, classes, disabled, options
    } = this.props;
    const hasError = error && error.length;
    const noOptionsOrDisabled = disabled || !options || !options.length;

    return (
      <div className={[
        'form-group',
        classes.root,
        this.props.className && this.props.className,
        validators && validators.required && 'required',
        hasError && 'has-error'
      ].filter(Boolean).join(' ')}
      >
        <div className={`${classes.iconWrapper} ${noOptionsOrDisabled ? classes.disabled : ''}`}>
          <div ref={this.assignDropdownContainer} />
          { classes.root && <CaretDownIcon noIconWrapper focusable={false} /> }
        </div>
        {hasError ? <span className="help-block">{error}</span> : ''}
      </div>
    );
  }
}

FlexDropdown.propTypes = Object.assign({}, {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  onChange: PropTypes.func,

  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.any]),
  value: PropTypes.any,

  allowConsecutiveSelections: PropTypes.bool,
  className: PropTypes.string,
  clearButton: PropTypes.bool,

  // Converts the service data into the proper format for the dropdown's data prop
  customParse: PropTypes.func,

  // Glu View class
  customView: PropTypes.func,

  defaultSelectMessage: PropTypes.string,
  disableClear: PropTypes.bool,
  disabled: PropTypes.bool,

  // This guy should really have a better name
  disableMultiButton: PropTypes.bool,

  /*
   * Loads data in batches of 100
   * passes the typeahead content, current page, and page size in the query string
   */
  dynamicUrl: PropTypes.string,

  filter: PropTypes.bool,
  filterMethod: PropTypes.oneOf(['contains', 'startsWith']),

  // Glu Model class
  model: PropTypes.func,

  multiSelect: PropTypes.bool,

  // Replaces flex dropdown data option due to form prop collisions
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    highlight: PropTypes.bool,

    // Only use name as an array when you use tableView
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    selected: PropTypes.bool,

    // each child has the same format as data itself
    child: PropTypes.arrayOf(PropTypes.object)
  })),

  // Matches options
  preSelected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

  preSelectedIds: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.any]),
  preSelectedNames: PropTypes.any,

  // Matches options
  preSelectedNewItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

  preSelectFirstItem: PropTypes.bool,
  selectAllButton: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
  showGroups: PropTypes.bool,
  showSelected: PropTypes.number,
  showTooltip: PropTypes.bool,
  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string),
  tableView: PropTypes.bool,
  url: PropTypes.string
}, validatedComponentPropTypes);

FlexDropdown.defaultProps = {
  classes: { root: '' },
  filterMethod: 'contains',
  showCancelIcon: true,
  showTooltip: true,
  injectValidation: true,
  onBlur() {},
  onChange() {}
};

export const FlexDropdownBase = FlexDropdown;
export const ValidatedFlexDropdownNonStyled = withNewValidation(FlexDropdown);
export const ValidatedFlexDropdown = withNewValidation(withStyles(styles)(FlexDropdown));
export default FlexDropdown;
