import { actionTypes } from './actions';

export default (state = {}, action) => {
    switch (action.type) {
    case actionTypes.SET_PREMIUM_ACH_PREFERENCES:
        return {
            ...state,
            ...action.payload.premiumAchPreferences,
        };
    case actionTypes.SET_PREMIUM_ACH_NEW_VENDOR_PRODDING_FLAG:
        return {
            ...state,
            hasNewPremiumAchVendors: action.payload,
        };
    default:
        return state;
    }
};
