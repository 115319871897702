import palette from '../palette';

export default {
    fontFamily: 'SourceSansPro, Helvetica, Arial, sans-serif',

    primary: {
        backgroundColor: palette.bilbao,
        borderColor: palette.bilbao,
        focusColor: palette.verdunGreen,
        hoverColor: palette.verdunGreen,
        textColor: palette.white,
        disabledBackground: palette.mercury,
        disabledBorder: palette.mercury,
        disabledColor: palette.birdGray,
        '&:hover': {
            color: palette.white
        }
    },

    secondary: {
        backgroundColor: palette.white,
        borderColor: palette.surfGreen,
        focusColor: palette.surfGreen,
        hoverColor: palette.surfGreen,
        textColor: palette.surfGreen,
        disabledBackground: palette.mercury,
        disabledBorder: palette.mercury,
        disabledColor: palette.doveGray,
        '&:hover': {
            color: palette.white
        },
    },

    tertiary: {
        textColor: palette.blueLagoon,
        '&:hover': {
            color: palette.astronautBlue,
        },
        '&.disabled': {
            color: palette.gray
        }
    },

    extraSmall: {
        borderRadius: 20
    },

    normal: {
        borderRadius: 20
    }
};