import Model from '@dbiqe/glu-core/src/model';
import appBus from 'system/gluOverride/appBus';
import util from 'underscore';
import PortalInterface from 'system/notifications/interfaces/portal';
import TrustedIframeWidgetInterface from 'system/notifications/interfaces/trustedIframeWidget';
import WKScriptMessageInterface from 'system/notifications/interfaces/wkScriptMessage';

const NotificationManager = Model.extend({
    initialize() {
        this.interfaces = [
            PortalInterface,
            TrustedIframeWidgetInterface,
            WKScriptMessageInterface,
        ];
        window.addEventListener('message', this.routeMessages, false);
        this.listenTo(appBus, 'bottomline.portal.message', this.notifyFromAppBus);
        this.listenTo(appBus, 'notification:child:keepSessionAlive', this.handleKeepSessionAlive);
    },

    handleKeepSessionAlive(evt) {
        appBus.trigger('keepSessionAlive', evt);
    },

    notificationFunctions() {
        const self = this;

        return {
            open: util.once((data) => {
                self.notify('page:open', data);
            }),

            close: util.once((data) => {
                self.notify('page:close', data);
            }),

            loaded: util.once((data) => {
                self.notify('page:loaded', data);
            }),
        };
    },

    notify(type, dataParam) {
        const data = dataParam;
        if (data !== undefined) {
            // hook into the parent portal here
            data.type = type;

            util.each(this.interfaces, (intrfc) => {
                intrfc.send(data);
            });
        }
    },

    notifyFromAppBus(message) {
        this.notify('bottomline', message);
    },

    routeMessages(e) {
        if (e.data.route) {
            switch (e.data.route) {
            case 'com.bottomline.childMessage':
                appBus.trigger((`notification:child:${e.data.key}`), e.data.message);
                break;
            case 'com.bottomline.trustedIframe':
                TrustedIframeWidgetInterface.parseReceivedMessage(e);
                break;
            default:
            }
        }
    },
});

export default new NotificationManager();
