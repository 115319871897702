import Model from '@dbiqe/glu-core/src/model';

const WKScriptMessageModel = Model.extend({
    send(data) {
        if (!window.webkit) {
            return;
        }
        try {
            window.webkit.messageHandlers.bottomline.postMessage(data);
        } catch (err) {
            // don't do anything right now
        }
    },
});

export default new WKScriptMessageModel();
