/*
 * Dynatrace Snippet Injection Integration Interface
 */
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { appendScript, isScriptInjected } from './util';

/**
 * Get the config object for Dynatrace
 * @returns {Object} config object
 */
const getConfig = () => ({
    mode: serverConfigParams.get('DynatraceMode'),
    snippetUrl: serverConfigParams.get('DynatraceSnippetUrl'),
});

const appendSnippet = () => {
    const { snippetUrl } = getConfig();
    if (!snippetUrl || isScriptInjected(snippetUrl)) {
        return;
    }
    appendScript(snippetUrl, {
        crossorigin: 'anonymous',
    });
};

const dynatrace = {
    postAuth() {
        appendSnippet();
    },

    preAuth() {
        const { mode } = getConfig();
        if (mode !== 'preauth') {
            return;
        }
        appendSnippet();
    },
};

export default dynatrace;
