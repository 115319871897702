import palette from '../palette';

export default {
    colorbar: {
        background: palette.cardinal,
        height: 0
    },

    pageHeaderTitle: {
        color: palette.black
    }, 

    headerTitleDark: {
        background: palette.white,
        color: palette.black
    },

    headerTotalsDark: {
        background: palette.white,
        color: palette.black
    },

    headerTotalsValue: {
        fontWeight: 'normal'
    },

    backNavBackground: {
        background: palette.white,
    },

    backNavBackgroundDark: {
        background: palette.white,
    },

    backNavIconColorDark: palette.black,

    summaryFooterSubject: {
        fontWeight: 'normal'
    },

    summaryFooter: {
        backgroundColor: palette.wildSand,
        color: palette.wildSand
    },

    footerButtonPrimary: {
        background: palette.bilbao,
        borderColor: palette.bilbao,
        color: palette.white,
        '&:hover, &:focus': {
            background: palette.verdunGreen,
            borderColor: palette.verdunGreen,
            color: palette.white
        },
        '&.disabled': {
            background: palette.mercury,
            borderColor: palette.mercury,
            color: palette.doveGray
        }
    },

    footerButtonSecondary: {
        background: palette.white,
        borderColor: palette.surfieGreen,
        color: palette.surfieGreen,
        '&:hover, &:focus': {
            background: palette.surfieGreen,
            borderColor: palette.surfieGreen,
            color: palette.white
        },
        '&.disabled': {
            background: palette.gallery,
            color: palette.doveGray
        }
    }
};