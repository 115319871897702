import { appBus } from '@dbiqe/glu-core';
import util from '@dbiqe/glu-core/src/util';
import locale from '@glu/locale';
import modalUtil from 'common/util/modal';
import $ from 'jquery';
import ModalView from 'no-override!@glu/dialog/src/views/modalView';
import portalInterface from 'system/notifications/interfaces/portal';
import template from './modal.hbs';
import buttonTemplate from './modalViewButtons.hbs';
import mobileUtil from 'mobile/util/mobileUtil';
import { isDeepLinked } from 'common/util/deeplinkUtil';

// Short 1/20 second throttle to reduce thrashing.
const LISTBUILDER_THROTTLE_DURATION = 50;

export default ModalView.extend({
    template,
    ui: {
         mask: '> .modal-backdrop',
         close: 'button.close',
         dialog: '> div > .modal-dialog',
         body: '> div > div > div > .modal-body',
         header: '> div > div > div > .modal-header',
         footer: '> div > div > div > .modal-footer',
         modal: '> .modal',
         $title: '[data-hook="getTitle"]',
    },

    regions: {
        header: '> div > div > div > .modal-header',
        body: '> div > div > div > .modal-body',
        footer: '> div > div > div > .modal-footer',
    },

    initialize(options) {
        ModalView.prototype.initialize.call(this, options);
        this.showCloseButton = util.has(options, 'showCloseButton') ? options.showCloseButton : true;
        // add event listener when the page is loaded in an iFrame
        this.throttledListBuilderChange = util.throttle(
            this.reportListBuilderChange,
            LISTBUILDER_THROTTLE_DURATION,
        );
        if (isDeepLinked()) {
            this.listenTo(appBus, 'iframePosition', this.updateModal);
            this.listenToOnce(this.appBus, ('notification:child:modal:close'), () => {
                this.close();
            });
        }
    },

    /**
     * Collects the operations needed to updated modals
     * @param {object} data
     */
    updateModal({data}) {
        // TODO remove the need for { message: data } wrapper
        this.positionModal(modalUtil.calculateTopEdge({ message:data }));
        this.sizeModal(data);
        this.moveOpenDropdowns();
    },

    /**
     * This function may later incorporate more types other than just select2,
     * but the majority of our dropdowns are select2 based.
     */
    moveOpenDropdowns() {
        // check for open select2 and position if needed.
        this.$('select,input')
            .filter((idx, el) => $(el).data('select2'))
            .each((idx, el) => {
                const $el = $(el);
                const $drop = $el.data('select2').dropdown;
                if ($drop.hasClass('select2-drop-active')) {
                    $el.select2('positionDropdown');
                }
            });
    },

    /**
     * @method positionModal
     * @param {number} modalTop - number of pixels for the top edge of the modal
     *
     * Place the modal at a given number of pixels (specified by modalTop) below the top
     * edge of the containing positioned element (iFrame)
     */
    positionModal(modalTop) {
        if (modalTop === undefined) {
            return;
        }
        const $modal = $('.modal-dialog');
        if ($modal.length > 0) {
            $modal.css({
                top: `${modalTop}px`,
            });
            this.updateFooterPosition();
        }
    },

    sizeModal(data) {
        const $modal = $('.modal-dialog .modal-content');
        if ($modal.length === 0) {
            return;
        }
        const maxHeight = modalUtil.isInIframe()
            ? modalUtil.calculateUsableHeight(data)
            : $(window).innerHeight();

        $modal.css({
            'max-height': `${maxHeight}px`,
        });
        const $modalBody = this.ui.body;
        const modalBodyMargin = $modalBody.outerHeight() - $modalBody.height();
        const modalHeaderHeight = this.ui.header?.height() ?? 0;
        const modalFooterHeight = this.ui.footer.outerHeight();
        const modalBodyHeight = maxHeight
            - modalHeaderHeight
            - modalBodyMargin
            - modalFooterHeight;
        this.ui.body.css({
            'max-height': modalBodyHeight,
        });

        const isListBuilder = $modal.find('.list-builder').length > 0;

        if (isListBuilder) {
            this.sizeModalListBuilder($modal, maxHeight, data);
        }
    },

    reportListBuilderChange() {
        this.appBus.trigger('listBuilderModalChange');
    },

    /**
     * Logic explicitly for ListBuilder modals
     * ListBuilder was forcibly set to 400px tall,
     * which doesn't work with an adjustable window.
     * @param {jQuery} $modal - modal content element
     * @param {number} maxHeight - Max available height
     * @param {Object} data - sizing data
     */
    sizeModalListBuilder($modal, maxHeight, data) {
        const modalCurrentHeight = $modal.height();
        const usableHeight = modalUtil.calculateUsableHeight(data);

        if (modalCurrentHeight < usableHeight) {
            return;
        }

        const modalHeaderHeight = $modal.find('.modal-header').outerHeight();
        const modalFooterHeight = $modal.find('.modal-footer').outerHeight();
        const usableModalHeight = maxHeight - (2 * (data?.options?.position?.topMargin || 0));
        const $lists = $modal.find('.source-list, .target-list');

        // Get the header and footer height
        const filterHeight = $modal.find('.filter.form-inline').outerHeight() || 0;
        const toggleHeight = $modal.find('.manage-accounts.list-builder>div').first().outerHeight() || 0;
        const moveAccountsHeight = $modal.find('.move-accounts-control, .source-list-controls').outerHeight() || 0
        const nonListHeight = toggleHeight
            + moveAccountsHeight
            + modalHeaderHeight
            + modalFooterHeight
            + filterHeight
            + 10; // Margin in listBuilder we can't easily compute
        const bestListHeight = usableModalHeight - nonListHeight;

        // Figure out if the list can be enlarged to the full 400px
        // 450 provides some buffer for bottom margin, etc.
        // const hasSpaceForFullList = (usableModalHeight - nonListHeight) > 450;
        // const newListHeight = (hasSpaceForFullList || bestListHeight > 400) ? 400 : bestListHeight;
        // Ensure some content is visible if the space is tiny
        const usableListHeight = Math.max(bestListHeight, 100);

        $modal.find('.modal-body').height(usableModalHeight);
        $lists.height(usableListHeight);
        this.throttledListBuilderChange();
    },

    /**
     * update sticky footer position in modals with scrolling content
     * @method updateFooterPosition
     */
    updateFooterPosition() {
        const $modalDialog = this.$('.modal-dialog');
        const $stickyFooter = $modalDialog.find('.sticky-footer');
        const modalFooterHeight = $modalDialog.find('.modal-footer').innerHeight();
        const modalBottomOffset = window.innerHeight -
            ($modalDialog[0].offsetTop + $modalDialog[0].offsetHeight);

        $stickyFooter.css({
            bottom: modalFooterHeight + modalBottomOffset,
        });
    },

    /**
     * @method handleResize
     * Override of the handleResize from glu's modalView
     * For DeepLinks use the window's outerHeight to set the max-height of the modal
     */
    handleResize() {
        // Only call when we are on mobile, not in an iframe
        if (!modalUtil.isInIframe() && mobileUtil.isMobileScreen()) {
            this.sizeModal();
            return;
        }

        // Extracted from the original with optional chaining added to resolve errors
        // in the case where our ui.body isn't set the offset is undefined. (ie. grid manage columns has this issue)
        if (this.ui.body?.offset?.() !== undefined) {
            const offset = this.ui.body.offset().top - parseInt($(window).scrollTop(), 10);
            const footerHeight = this.ui.footer.outerHeight();
            const dialogBottomMargin = parseInt(this.ui.dialog.css('margin-bottom'), 10);
            const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            const maxHeight = h - (offset + footerHeight + dialogBottomMargin);
            this.ui.body.css('max-height', `${maxHeight}px`);
        }
    },

    onRender() {
        this.appBus.trigger(
            'glu:modal:open',
            {
                title: this.title,
            },
        );
        if (this.bodyView) {
            this.setBodyViewListeners();
        }

        ModalView.prototype.onRender.call(this);
    },

    templateHelpers() {
        return {
            showCloseButton: this.showCloseButton,
            type: this.type || 'standard',
            message: this.message,
            title: this.title,
            dialogIcon: this.icon,
            modalClass: this.modalClass,
            buttons: this.buttons,
            footerButtons: buttonTemplate,
        };
    },

    setBodyViewListeners() {
        this.listenTo(this.bodyView, 'load:error', this.replaceButtonsForLoadError);
        this.listenTo(this.bodyView, 'dialog:title:change', this.changeTitle);
        this.listenTo(this.bodyView, 'dialog:view:change', this.changeView);
        this.listenTo(this.bodyView, 'dialog:buttons:change', this.changeButtons);
        this.listenTo(this.bodyView, 'dialog:buttons:disableAll', this.disableAllButtons);
        this.listenTo(this.bodyView, 'dialog:buttons:enableAll', this.enableAllButtons);
    },

    close() {
        this.appBus.trigger(
            'glu:modal:close',
            {
                title: this.title,
            },
        );
        if (modalUtil.isInIframe()) {
            // TODO - move this call should to portalInterface from appBus glu:modal:close
            portalInterface.send({
                title: 'modal:close',
            });
        }
        ModalView.prototype.close.call(this);
    },

    changeTitle(title) {
        this.ui.$title.text(title);
        this.handleResize();
    },

    changeView(view) {
        this.bodyView = view;
        this.setBodyViewListeners();
        this.body.show(this.bodyView);
    },

    changeButtons(buttons) {
        this.buttons = buttons;
        // Injecting HBS template
        this.ui.footer.html(buttonTemplate(this));
        this.handleResize();
    },

    replaceButtonsForLoadError() {
        this.changeButtons(
            [{
                className: 'btn-primary',
                text: locale.get('common.close'),
            }],
        );
    },

    disableAllButtons(selector) {
        // use jquery since buttons might have changed since rendering
        this.$(selector || '.modal-footer button').prop('disabled', true);
    },

    enableAllButtons(selector) {
        // use jquery since buttons might have changed since rendering
        this.$(selector || '.modal-footer button').prop('disabled', false);
    },
});
