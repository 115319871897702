import { request } from '@dbiqe/common';

import transform from 'common/util/transform';

import { ASCENDING, BENE_NAME } from './constants';

export const getVendors = ({ signal }) => {
    const payload = {
        startRow: 1,
        disableDrillDown: false,
        dataOnly: 0,
        searchFields: [],
        sortFields: [
            { fieldName: BENE_NAME, sortOrder: ASCENDING },
        ],
    };
    return request
        .post('/banking-services/api/premiumACH/vendors/getListView', payload, { signal })
        .then(({ data }) =>
            (data.rows || []).map((row) => {
                const vendor = transform.pairsToHashUnescape(
                    row.columns,
                    'fieldName',
                    'fieldValue',
                );

                return {
                    id: vendor.HEADER_TNUM,
                    vendorLabel: vendor.BENE_NAME,
                    address: [
                        vendor.BENE_BANK_ADDRESS_1,
                        vendor.BENE_BANK_ADDRESS_2,
                        vendor.BENE_BANK_ADDRESS_3,
                        vendor.BENE_CITY,
                        vendor.BENE_STATE,
                        vendor.BENE_POSTALCODE,
                    ]
                        .filter(Boolean)
                        .join(', '),
                    name: vendor.BENE_NAME,
                    status: vendor.VENDOR_STATUS,
                };
            }))
        .catch(() => []);
};

export const getEntitledActions = ({ signal }) =>
    request
        .post('/banking-services/api/premiumACH/vendors/getEntitledActions', {}, { signal })
        .then(({ data }) => data.actionModes);

export const activateVendors = ({ payload, signal }) =>
    request
        .post('/banking-services/api/premiumACH/vendors/activateVendors', payload, { signal })
        .then(({ data }) => data);
