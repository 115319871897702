import util from '@dbiqe/glu-core/src/util';
import ItemView from '@dbiqe/glu-core/src/itemView';
import Model from '@dbiqe/glu-core/src/model';
import locale from '@glu/locale';
import savedViewFiltersTmpl from './savedViewFilters.hbs';

export default ItemView.extend({
    template: savedViewFiltersTmpl,

    ui: {
        $clearFilter: '.remove-filter',
    },

    initialize(options) {
        this.model = new Model({
            filters: options.filters,
        });
    },

    clearSavedGridFilter(event) {
        this.trigger('delete', event.currentTarget?.parentNode?.dataset?.filterId);
    },

    /**
     * @param {array} userFilterCodes
     * Will check for overrided filters and rerender the view to display accordingly
     */
    checkOverlapFilters(userFilterCodes) {
        this.model.set('filters', this.setOverrideOnFilters(this.model.get('filters'), userFilterCodes));
        this.render();
    },

    /**
     * @param {array} viewFilters - array of filters on the saved view
     * @param {array} userFilterCodes - array of filter fields that the user has applied
     * @return {array}
     * Checks if any of the view's filters have been overrided by the user's applied filters,
     * by looking for matching filter field codes.
     */
    setOverrideOnFilters(viewFilters, userFilterCodes) {
        return util.forEach(viewFilters, (vf) => {
            const viewFilter = vf;
            viewFilter.overridden = util.contains(userFilterCodes, vf.fieldCode);
        });
    },

    templateHelpers() {
        const filters = this.model.get('filters');
        if (filters != null && filters.length === 1 && filters[0].fieldCode === 'SURROGATETNUM') {
            filters[0].fieldCode = '';
            filters[0].title = '';
            filters[0].label = locale.get('PAY.allPayments');
            filters.allSavedViewFilters = true;
            this.options.allSavedViewFilters = true;
        }
        return {
            viewFilters: filters,
            allSavedViewFilters: this.options.allSavedViewFilters,
        };
    },

});
