import { addModuleStyles } from '@glu/theming/glu-css-override';

const themeKey = 'flexDropdown';

const defaultTheme = ({ legacy, palette }) => (legacy ? undefined : {
  border: palette.form.border,
  labelColor: palette.text.midGray,
  placeholderColor: palette.text.midGray,
  btnPrimary: palette.primary.normal,
  focusColor: palette.focus.normal
});

const styles = ({ flexDropdown }) => (flexDropdown ? {
  root: {
    '& .flex-dropdown-wrapper': {
      border: 20,
      '& .flex-dropdown': {
        '&:focus': {
          '& .selection': {
            borderColor: flexDropdown.focusColor,
            boxShadow: `0px 0px 8px ${flexDropdown.focusColor}`
          }
        }
      },
      '& .selection': {
        border: `1px solid ${flexDropdown.border}`,
        minHeight: 34,
        boxShadow: 'none',
        '& .caret': {
          position: 'absolute',
          top: 8,
          right: 8,
          border: 0,
          width: 'auto',
          height: 'auto',
          '& .icon-wrapper': {
            display: 'block'
          }
        }
      },
      '& .form-control': {
        '&:focus': {
          borderColor: flexDropdown.focusColor,
          boxShadow: `0px 0px 8px ${flexDropdown.focusColor}`
        }
      },
      '& label': {
        color: flexDropdown.labelColor,
        fontSize: 12,
        fontWeight: 700,
        paddingRight: 5,
        textTransform: 'uppercase',
        margin: [0, 0, 5],
        display: 'block'
      },
      '& .not-selected': {
        color: flexDropdown.placeholderColor
      },
      '& .items': {
        '& input': {
          '&:focus': {
            outlineColor: flexDropdown.focusColor
          }
        },
        '& a': {
          '&:focus': {
            outlineColor: flexDropdown.focusColor
          }
        },
        '& .button-holder': {
          width: 'auto',
          float: 'left',
          padding: [3, 7]
        },
        '& .btn': {
          borderRadius: 100,
          fontSize: 14,
          fontFamily: '"Roboto Condensed", Roboto, Helvetica, Arial, sans-serif'
        },
        '& .btn-primary': {
          background: flexDropdown.btnPrimary
        },
        '& .btn-secondary': {
          borderColor: flexDropdown.btnPrimary
        }
      }
    }
  }
} : {});

addModuleStyles(styles, themeKey, defaultTheme);
