import serverConfigParams from 'system/webseries/models/configurationParameters';
import position from '@dbiqe/positioning';
import $ from 'jquery';

export default {
    /**
     * Reusable function for iframe test
     * @returns {boolean}
     */
    isInIframe() {
        return window !== window.parent;
    },

    /**
     * @param {object} passedData - the data from an event
     * @returns {number}
     *
     * Calculate the top edge of the absolute position of a modal.
     * Used in an iframe. The data lets us determin any occlusion of the frame
     * so we can position to the onscreen, visible portion.
     */
    calculateTopEdge(passedData) {
        const data = passedData?.message ?? passedData;

        // TODO - bad input. What should happen here?
        if (!data) {
            return 0;
        }

        /*
         * Check for "legacy" positioning.
         * Remains in case clients do not update their parentFrameController
         */
        if (data.scrollTop) {
            return this.retrieveTopEdge(data);
        }

        // Looks odd, but data contains calculations AND the source data.
        const sourceData = data.data ?? data;

        return position.calculateTopEdge(sourceData);
    },

    /**
     * Passthrough to positioning library
     * @param {object} data - from getPositionData
     * @returns {number}
     */
    calculateUsableHeight(data) {
        return position.calculateUsableHeight(data);
    },

    /**
     * TODO: DEPRECATED but may still be called by older parentFrameController.
     * @param {object} data - the data from an event
     * @returns {number}
     *
     * Method returns the top edge of the absolute position of a modal to a number in pixels
     * below the top edge of the modal nearest positioned ancestor
     *
     */
    retrieveTopEdge(data) {
        /*
         * scrollTop - value representing what is scrolled vertically
         * offsetTop - offset Top relative to the document
         * scrollY - value representing what is scrolled vertically
         * pageYOffset - value representing what is scrolled vertically
         */
        const {
            top,
            scrollTop,
            scrollY,
            pageYOffset,
        } = data.message;
        let NumOfPixelsScrolledVert;

        if (scrollTop > 0) {
            NumOfPixelsScrolledVert = scrollTop;
        } else if (pageYOffset > 0) {
            /** for IE */
            NumOfPixelsScrolledVert = pageYOffset;
        } else {
            NumOfPixelsScrolledVert = scrollY;
        }

        let modalTop = NumOfPixelsScrolledVert;

        /*
         * If the portal has a header, use the value that the page has scrolled vertically.
         * Otherwise calculate by taking into account the distance between the top border
         * of the iFrame and the top of the inner height of the browser and the value that
         * the page has scrolled vertically.
         */
        const portalHeader = serverConfigParams.get('portalHeader');
        if (!portalHeader) {
            modalTop -= top;
            if (top >= NumOfPixelsScrolledVert) {
                modalTop = 0;
            }
        }
        modalTop += 30;
        return modalTop;
    },

    sizeModal(data) {
        const $modal = $('.modal-dialog .modal-content');
        if ($modal.length === 0) {
            return;
        }
        const maxHeight = this.isInIframe()
            ? this.calculateUsableHeight(data)
            : $(window).innerHeight();

        $modal.css({
            'max-height': `${maxHeight}px`,
        });
        const $modalBody = $('.modal-body');
        const modalBodyMargin = $modalBody.outerHeight() - $modalBody.height();
        const modalHeaderHeight = $modal.find('.modal-header')?.height() ?? 0;
        const modalFooterHeight = $modal.find('.modal-footer').outerHeight();
        const modalBodyHeight = maxHeight
            - modalHeaderHeight
            - modalBodyMargin
            - modalFooterHeight;
        $modalBody.css({
            'max-height': modalBodyHeight,
        });

        const isListBuilder = $modal.find('.list-builder').length > 0;

        if (isListBuilder) {
            this.sizeModalListBuilder($modal, maxHeight, data);
        }
    },
    /**
     * @method positionModal
     * @param {number} modalTop - number of pixels for the top edge of the modal
     *
     * Place the modal at a given number of pixels (specified by modalTop) below the top
     * edge of the containing positioned element (iFrame)
     */
    positionModal(modalTop) {
        if (modalTop === undefined) {
            return;
        }
        const $modal = $('.modal-dialog');
        if ($modal.length > 0) {
            $modal.css({
                top: `${modalTop}px`,
            });
            this.updateFooterPosition();
        }
    },
    /**
     * update sticky footer position in modals with scrolling content
     * @method updateFooterPosition
     */
    updateFooterPosition() {
        const $modalDialog = $('.modal-dialog');
        const $stickyFooter = $modalDialog.find('.sticky-footer');
        const modalFooterHeight = $modalDialog.find('.modal-footer').innerHeight();
        const modalBottomOffset = window.innerHeight -
            ($modalDialog[0].offsetTop + $modalDialog[0].offsetHeight);

        $stickyFooter.css({
            bottom: modalFooterHeight + modalBottomOffset,
        });
    },

    /**
     * This function may later incorporate more types other than just select2,
     * but the majority of our dropdowns are select2 based.
     */
    moveOpenDropdowns(view) {
        // check for open select2 and position if needed.
        const activeSelect = $('.select2-drop').filter((idx, el) => el.style.display !== 'none').length > 0;

        if (activeSelect) {
            view.$('select,input').filter((idx, el) => $(el).data('select2')).select2('positionDropdown');
        }
    },

    updateModal(data, view) {
        // We may get the original or the computed wrapper
        const useData = data.data ?? data;

        this.positionModal(this.calculateTopEdge(useData));
        this.sizeModal(useData);
        this.moveOpenDropdowns(view);
    },
};
