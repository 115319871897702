import serverConfigParams from 'system/webseries/models/configurationParameters';
import http from '@dbiqe/glu-core/src/http';
import httpSetup from 'system/httpSetup';
import { appendScript } from './util';

const addRSA = () => {
    appendScript(`${window.Bottomline.assetRoot}/global-scripts/rsa/rsa.js`, {
        async: false,
        onLoad: () => {
            if (typeof window.encode_deviceprint === 'function') {
                const { getHeaders } = httpSetup;
                const headers = {
                    ...getHeaders(),
                    devicePrint: window.encode_deviceprint(),
                };
                http.setRequestHeaders(headers);
            }
        },
    });
};

export default {
    preAuth() {
        const enabled = serverConfigParams.get('TransmitDeviceFingerprintEnabled') === 'true';
        if (!enabled) {
            return;
        }

        try {
            appendScript(`${window.Bottomline.assetRoot}/global-scripts/rsa/hashtable.js`, {
                onLoad: addRSA,
            });
        } catch (e) {
            window.console.error(e);
        }
    },
};
