import Model from '@dbiqe/glu-core/src/model';
import http from '@dbiqe/glu-core/src/http';
import util from '@dbiqe/glu-core/src/util';
import userInfo from 'etc/userInfo';
import services from 'services';

const InquiryModel = Model.extend({
    requestId: 1,

    initialize() {
        this.searchType = 0;
        this.inquiryId = 0;
        this.searchOrOperator = false;
    },

    sync(method, model, options) {
        const data = util.extend({}, this.generateCoreData(), this.generateSpecificData());
        const url = services.generateUrl(this.inquiryUrl());
        http.post(url, data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    inquiryUrl() {
        return '/inquiry/getData';
    },

    generateCoreData() {
        const retObj = {
            inquiryRequest: {
                searchCriteria: {
                    searchType: this.searchType,
                    operationalData: false,
                    inquiryId: this.inquiryId,
                    searchOrOperator: this.searchOrOperator,
                },
            },

            requestHeader: {
                requestUser: {
                    data: {
                        item: [{
                            name: 'usergroup',
                            value: userInfo.get('group'),
                        }],
                    },

                    userId: userInfo.get('id'),
                },

                requestId: this.requestId,
                channelId: '',
            },
        };

        this.requestId += 1;

        if (this.searchFields) {
            retObj.inquiryRequest.searchCriteria.searchFields = this.searchFields;
        }

        return retObj;
    },

    generateSpecificData() {
        return {};
    },

    /**
     * Gets the value associated with the given key from
     * the response of the inquiry service.
     *
     */
    parseValue(columns, key) {
        for (let i = 0; i < columns.length; i += 1) {
            if (columns[i].fieldName.toUpperCase() === key.toUpperCase()) {
                return columns[i].fieldValue;
            }
        }
        return undefined;
    },
});

export default InquiryModel;
