import util from 'underscore';
import locale from '@glu/locale';
import validators from 'system/gluOverride/core/internal/validators';

import BaseModel from 'no-override!@dbiqe/glu-core/src/model';

/**
 * HACK NH-149784 React does not expect underscore templates, like <%= value %>,
 * and uses tokens instead, {0}. This objecvt provides a way to override any keys that
 * uses tokens. Note that the default value in validationMessages, located in
 * gluOverride/core/internal/validator.js, must be updated as well. For example, if
 * the override value is 'legacy.emailAddress', then the property in validationMessages
 * should be 'legacy.emailAddress' as well.
 */
const validationKeyOverride = {
    emailAddress: 'legacy.emailAddress',
};

const Model = BaseModel.extend({
    getValidationMessage(inputKey) {
        const key = validationKeyOverride[inputKey] || inputKey;
        return locale.getDefault(`validator.${key}`, validators.validationMessages[key]);
    },

    getValidationResultsByFieldName(name) {
        const validationResults = {};

        if (util.contains(util.keys(this.validators), name)) {
            const validator = this.validators[name];

            // mandatory set from table, or set when shouldberequire(true), adds the
            // exists validator: true
            const isMandatory = validator?.exists === true;

            const tests = util.omit(validator, 'description', 'warning', 'overrideError', 'otherDescription', 'limitValue', 'customString');

            if (!tests.disabled) {
                Object.entries(tests).forEach(([testName, param]) => {
                    /* validators should be run if the field is mandatory
                    * validators should be run if the field is not mandatory and there
                    * is data to validate
                    * validators should not be run if the field is not mandatory and
                    * there is no data to validate
                    * this checks to determine if the fieldData exists for the validator
                    * being named, if the field is not mandatory
                    * and if the fieldData actually has a value.  If no value and not
                    * mandatory, then it won't execute the validator.
                    */
                    if ((this.fieldData?.[name] && !isMandatory && !this.get(name)) || testName === 'disabled') {
                        return validationResults;
                    }

                    // avoid: method = validators[testName]; method();
                    // runs validator in global scope ('this' undefined)
                    // OverrideError applies to 'matches' test, and should only be used when test is for 'matches' and an override error exists
                    const valid = (param === true) ? validators[testName](name, this)
                        : validators[testName](name, param, this);

                    const validationMessage = this.getValidationMessage(validator.overrideError && 'matches' === testName ? validator.overrideError : testName);
                    const message = util.template(validationMessage)(validator);

                    if (!valid) {
                        // TODO: this format does not work for all messages.
                        if (validator.warning) {
                            validationResults.warnings = validationResults.warnings || [];
                            validationResults.warnings.push(message);
                        } else {
                            validationResults.errors = validationResults.errors || [];
                            validationResults.errors.push(message);
                        }

                    }
                });
            }
        }

        return validationResults;
    },

    /**
     * Call the base validateField function and then evaluate the results.
     * When there are valid validators set and there aren't any error for this field, trigger event 'valid:attribute'
     * @param {String} attribute
     */
    validateField(attribute) {
        // don't perform validation for this attribute if no corresponding validator exists
        if(!this.validators?.[attribute]){
            return;
        }

        BaseModel.prototype.validateField.call(this, attribute);
        const results = this.getValidationResultsByFieldName(attribute);
        if (this.validators && util.isEmpty(results)) {
            this.trigger('valid:attribute', attribute);
        }
    },

    /**
     *
     * @param {String} key - attribute on the model
     * @param {Object} value - Object representing the specific validations
     * for the specified attribute of the model
     * @param {Object} options - additional options
     */
    addValidator(key, value, options = {}) {
        BaseModel.prototype.addValidator.call(this, key, value);
        if (!options.silent) {
            this.trigger('validator:added', key, value);
        }
    },

    /**
     *
     * @param {String} key - attribute on the model
     * @param {String} prop - property to add to the validator
     * @param {*} value - value for the prop
     * @param {Object} options - additional options
     */
    addValidatorProp(key, prop, value, options) {
        const fieldValidators = this.validators[key] || {};
        const newValidators = {
            ...fieldValidators,
            ...{
                [prop]: value,
            },
        };
        this.addValidator(key, newValidators, options);
    },

    /**
     * Remove all of the validation for the specified attribute
     * @param {String} key - attribute on the model
     */
    removeValidator(key) {
        BaseModel.prototype.removeValidator.call(this, key);
        this.trigger('validator:removed', key);
    },

    /**
     *
     * @param {String} key - attribute on the model
     * @param {String} prop - property removed from the validator
     * @param {Object} [options]
     * object for the specified attribute of the model
     */
    removeValidatorProp(key, prop, options = {}) {
        if (!this.validators) {
            return this;
        }
        const fieldValidators = this.validators[key] || {};

        delete fieldValidators[prop];

        this.validators[key] = fieldValidators;
        if (!options.silent) {
            this.trigger('validator:prop:removed', key, prop, fieldValidators);
        }
    }
});

export default Model;
