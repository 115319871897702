import { actionTypes } from './actions';

export default (state = {}, action) => {
    switch (action.type) {
    case actionTypes.ADD_COMBO_WRAPPERS:
        return Object.keys(action.payload).reduce((acc, formName) => ({
            ...acc,
            [formName]: {
                ...(state[formName] || {}),
                ...action.payload[formName],
            },
        }), state);
    case actionTypes.RESET_COMBO_WRAPPERS:
        return {};
    case actionTypes.UPDATE_COMBO_WRAPPERS:
        return Object.keys(action.payload).reduce((acc, formName) => ({
            ...acc,
            [formName]: {
                ...(state[formName] || {}),
                ...Object.keys(action.payload[formName]).reduce((acc2, fieldName) => ({
                    ...acc2,
                    [fieldName]: {
                        ...(state[formName]?.[fieldName] || {}),
                        ...action.payload[formName][fieldName],
                    },
                }), {}),
            },
        }), state);
    default:
        return state;
    }
};
