import Controller from '@dbiqe/glu-core/src/controller';
import appBus from 'system/gluOverride/appBus';

export default Controller.extend({
    initialize(options) {
        // Tracking in case we need to store a message before the router is ready.
        this.appReady = false;
        this.options = options || {
            domain: '*',
        };
        window.addEventListener('message', this.handleMessage.bind(this));
        // TODO Throw error if parent isn't available.
        this.parent = window.parent;

        this.listenTo(appBus, 'keepalive', this.postFromAppBus);
    },

    /**
     * Listener for window's message event.
     * @param  {MessageEvent} evt
     */
    handleMessage(evt) {
        switch (evt.data.type) {
        case 'register':
            this.finishRegistration(evt);
            break;
        case 'router:navigate':
            if (!this.appReady) {
                // Store the message for later
                this.lastMessage = evt;
                break;
            }
            // Fall through
        default:
            this.trigger(evt.data.type, evt);
            // Communicate calls to the appBus for other consumers (scroll)
            appBus.trigger(`postMessage:${evt.data.type}`, evt);
            break;
        }
    },

    /**
     * Sends register message to parent window.
     * This is a handshake style process.
     */
    register() {
        this.postMessage('register');
    },

    /**
     * Set ready, message parent, & navigate if necessary.
     */
    setReady() {
        this.appReady = true;
        this.postMessage('ready');

        // if we received a router:navigate event before ready, we need to send it again;
        if (this.lastMessage) {
            this.handleMessage(this.lastMessage);
            this.lastMessage = null;
        }
    },

    /**
     * @param  {Event} evt
     */
    finishRegistration(evt) {
        this.setId(evt.data.message.id);
        this.postMessage('registered', evt.data.message);
        this.trigger('registered', evt);
    },

    /**
     * Tells parent window that this IFRAME wants to subscribe to the eventName.
     * Event type is specified as subscribe and evtName is put within the message
     * data to specify the class event name that will be used.
     * @param  {[type]}   evtName  [description]
     * @param  {Function} callback [description]
     * @return {[type]}            [description]
     */
    subscribe(evtName, callback) {
        this.postMessage(
            'subscribe',
            {
                eventName: evtName,
                callback,
                scope: this,
                id: this.getId(),
            },
        );
    },

    /**
     * Push message to parent window.
     * @param  {String} evtName - Serves as the type of message, used to delegate to
     * right method.
     * @param  {Object} data    [Mixed object]
     */
    publish(evtName, data) {
        this.postMessage(evtName, data);
    },

    /**
     * Primary method to communicate to parent window.
     * @param  {String} evtName - Event type, will be used by parent to delegate.
     * @param  {Object} data - Mixed object
     */
    postMessage(evtName, data) {
        this.parent.postMessage({
            route: window.location.href,
            type: evtName,
            message: data || {},
            id: this.getId(),
        }, this.options.domain);
    },

    postFromAppBus(type, message) {
        this.postMessage(type, message);
    },

    getId() {
        return this.id || 0;
    },

    setId(id) {
        this.id = id;
    },
});
