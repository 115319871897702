import util from '@dbiqe/glu-core/src/util';
import locale from '@glu/locale';

const operatorLocales = {
    EQ: 'common.equal',
    LT: 'common.less',
    LTEQ: 'common.less.or.equal',
    GT: 'common.greater',
    GTEQ: 'common.greater.or.equal',
    BETWEEN: 'common.between',
    IN: 'common.in',
    NOTIN: 'common.isnot',
    NOTEQ: 'common.isnot',
    CONTAINS: 'common.in',
};

export default {
    convert4Label(op) {
        const operators = [{
            operator: 'equal',
            symbol: '=',
        }, {
            operator: 'LT',
            symbol: '<',
        }, {
            operator: 'LTEQ',
            symbol: '<=',
        }, {
            operator: 'GT',
            symbol: '>',
        }, {
            operator: 'GTEQ',
            symbol: '>=',
        }, {
            operator: 'BETWEEN',
            symbol: locale.get('common.between'),
        }, {
            operator: 'NOTIN',
            symbol: locale.get('common.isnot'),
        }, {
            operator: 'NOTEQ',
            symbol: locale.get('common.isnot'),
        }];

        let label = '';
        const found = util.findWhere(
            operators,
            {
                operator: op,
            },
        );

        if (found) {
            label = found.symbol;
        }
        return label;
    },

    /**
     * Get the human readable string for an operator
     * @param {String} operator - operator key
     * @returns {String} localized string
     */
    convertToHumanReadable(operator) {
        return locale.get(operatorLocales[operator]);
    },
};
