import {
    log, Collection, CollectionView, util,
} from '@dbiqe/glu-core';
import RowSelectorCell from '@glu/grid/src/cell/rowSelectorCell';
import SingleRowSelectorCell from '@glu/grid/src/cell/singleRowSelectorCell';
import RowView from 'no-override!@glu/grid/src/row/row';

import SimpleCell from 'system/gridOverride/cell/simpleCell';
import AnchorCell from 'system/gridOverride/cell/anchorCell';
import RowButtonCell from 'system/gridOverride/cell/rowButtonsCell';
import ImageCell from 'system/gridOverride/cell/imageCell';

function getClassName(column, model, val) {
    if (column.has('rowButtons')) {
        return 'grid-row-buttons';
    }

    let className = column.get('className');
    if (util.isFunction(className)) {
        className = className(val, model);
    }
    return className;
}

/*
 * TODO Items
 * 1. Switch away from jQuery $td logic
 * 2. Create proper Views for each column type
 * 3. Switch to using a CollectionView
 */

export default CollectionView.extend(RowView).extend({
    modelEvents: {
        change: 'renderAndRestoreFocus',
    },

    tagName: 'tr',

    constructor(options) {
        CollectionView.prototype.constructor.call(this, options);
    },

    initialize(options) {
        CollectionView.prototype.initialize.call(this, options);

        // convert data for models.
        const data = this.options.columns.filter((column) => {
            if (column.has('condition')) {
                const condition = column.get('condition');
                const isFunction = util.isFunction(condition) && !condition(this.model, column);
                const isBoolean = util.isBoolean(condition) && !condition;
                if (isFunction || isBoolean) {
                    return false;
                }
            }
            return true;
        }).map((column) => {
            const value = this.getValue(column);
            const className = getClassName(column, this.model, value);
            return {
                column,
                className,
                value,
            };
        });

        this.collection = new Collection(data);
        this.el.dataset.modelCid = this.model.cid;
    },

    /**
     * @param {Backbone.Model} item
     * @returns {boolean}
     */
    isCustomView(item) {
        return item.get('column').has('cellView');
    },

    onAfterItemAdded(view) {
        if (view.options.isCustom) {
            this.listenTo(view, 'all', this.handleCellEvent);
        }
    },

    /**
     * Identify cell types that consume server-provided options.
     * @param {Backbone.Model} item
     * @returns {boolean}
     */
    useCellViewOptions(item) {
        const column = item.get('column');
        const colType = column.get('type');

        return column.has('cellView') || util.isFunction(colType);
    },

    /**
     * @param {Backbone.Model} column
     * @returns {string|*}
     */
    getValue(column) {
        if (!column.has('field')) {
            return '';
        }

        const baseValue = this.model.get(column.get('field'));

        if (baseValue == null) {
            return baseValue;
        }

        let str = baseValue.toString();
        let val = baseValue.valueOf();

        // values are safe unless explicitly set to unsafe
        if (column.has('safe') && column.get('safe') === false) {
            val = util.escape(val);
            str = util.escape(str);
        }

        if (!this.model.editRow && column.has('format') && util.isFunction(column.get('format'))) {
            str = column.get('format')(val, this.model);
        }

        return str;
    },

    onRender() {
        if (util.isFunction(this.options.calculateWidth)) {
            this.options.calculateWidth();
        }
    },

    onBeforeClose() {
        // Intentional no-op
    },

    /**
     * @param {Backbone.Model} item
     * @returns {Object}
     */
    itemViewOptions(item) {
        const useCellViewOptions = this.useCellViewOptions(item);
        const column = item.get('column');
        const cellViewOptions = (useCellViewOptions ? column.get('cellViewOptions') : null) || {};
        const value = this.getValue(column);
        const isCustom = this.isCustomView(item);

        return {
            tagName: 'td',
            isCustom,
            parentModel: this.model,
            model: this.model, // item,
            column,
            value: this.getValue(column),
            grid: this.options.grid,
            gridCid: this.options.gridCid,
            safe: column.get('safe'),
            theme: this.options.theme,
            ...(useCellViewOptions ? {} : {
                className: getClassName(column, this.model, value),
            }),
            ...cellViewOptions,
        };
    },

    /**
     * @param {Backbone.Model} item
     * @returns {Backbone.View}
     */
    getItemView(item) {
        const column = item.get('column');
        const colType = column.get('type');

        if (column.has('rowButtons')) {
            return RowButtonCell;
        }
        if (this.isCustomView(item)) {
            return column.get('cellView');
        }

        if (util.isFunction(colType)) {
            log.warn('Passing a function to column.type in the grid is deprecated. Change "type" to "cellView".');
            return colType;
        }

        if (column.has('url')) {
            return AnchorCell;
        }

        if (colType === 'rowSelector') {
            return RowSelectorCell;
        }

        if (colType === 'singleRowSelector') {
            return SingleRowSelectorCell;
        }

        if (colType === 'image') {
            return ImageCell;
        }

        return SimpleCell;
    },
});
