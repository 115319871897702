import DatePicker from 'system/gluOverride/datepicker/datepicker-glu-1.8';
import $ from 'jquery';
import util from '@dbiqe/glu-core/src/util';
import moment from 'moment';
// FIXME: Switch back to @glu/datepicker when we have the override solved

export default DatePicker.extend({
    /**
     * Parses a single date obtained from the date range using moment and the
     * configured allowed formats.
     * Overriding this function so that the datePicker provided format will be
     * used when user provided data is being parsed.
     *
     * @param dateInput {string} a date to parse using moment.js
     * @returns {moment}
     *        A parsed moment or null if null was input.  Note that the whether
     * the date is
     *        valid or not is indicated on the dates isValid() function.
     */
    parseDate(dateInput) {
        const format = (!this.view || this.dtPickerFormat) ? this.dtPickerFormat.moment : util.pluck(this.dateFormats, 'moment');

        return dateInput ? moment($.trim(dateInput), format, true) : null;
    },
});
