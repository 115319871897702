var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "        <div class=\"ViewFilter label label-filter "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.overridden : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <span class=\"ViewFilter-title filter-title-text\">"
    + alias5(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"filter-value-text "
    + alias5(((helper = (helper = helpers.labelClass || (depth0 != null ? depth0.labelClass : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"labelClass","hash":{},"data":data}) : helper)))
    + "\">"
    + alias5(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"label","hash":{},"data":data}) : helper)))
    + "</span>\n\n"
    + ((stack1 = helpers.unless.call(alias2,((stack1 = (data && data.root)) && stack1.allSavedViewFilters),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "ViewFilter--overridden";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4=container.escapeExpression;

  return "                <span class=\"remove-filter "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.savedViewFilter : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-filter-id=\""
    + alias4(((helper = (helper = helpers.fieldCode || (depth0 != null ? depth0.fieldCode : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"fieldCode","hash":{},"data":data}) : helper)))
    + "\">\n                    "
    + alias4((helpers.customizedIcon||(depth0 && depth0.customizedIcon)||alias3).call(alias2,{"name":"customizedIcon","hash":{"locale":"clearFilter","action":"clearSavedGridFilter","name":"circle-close-solid"},"data":data}))
    + "\n                </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.viewFilters : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression;

  return "            <button data-hook=\"clearAllFilters\"\n                class=\"btn-tertiary\"\n                aria-label=\""
    + alias3(helpers.locale.call(alias2,"button.clear",{"name":"locale","hash":{},"data":data}))
    + "\">\n                "
    + alias3(helpers.locale.call(alias2,"button.clear",{"name":"locale","hash":{},"data":data}))
    + "\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"SavedViewFilters\" data-hook=\"getSavedViewFilters\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.viewFilters : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias2,((stack1 = (data && data.root)) && stack1.allSavedViewFilters),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});