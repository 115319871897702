import ItemView from 'no-override!@dbiqe/glu-core/src/itemView';
import validatorPatterns from 'system/validatorPatterns';
import RenameFormTemplate from './renameColumnForm.hbs';

export default ItemView.extend({
    template: RenameFormTemplate,

    /*
     * extend ui to include rename ui elements (done to rely on data hooks and not on the
     * html structure)
     */
    ui: {
        $renameInput: '[data-hook="column-rename-input"]',
    },

    initialize(options) {
        this.model = options.model;
        this.onSubmitCallback = options.onSubmitCallback;
        // set validator
        this.model.validators = {
            inputedRename: {
                description: 'Column Name',
                exists: true,
                matches: validatorPatterns.ISO_8859_1_PRINTABLECHARS_PATTERN,
            },
        };
    },

    /**
     *  @method: getInputColumnName
     *  @param: {string} cid
     *  @return: {string} Column Name
     *  - Check associated input for column name, parse, and return it
     */
    getInputColumnName() {
        return this.ui.$renameInput
            .val()
            .trim();
    },

    /**
     *  @method: submitRename
     *  @return: {string} submitCallback - from managecolumn menu
     *  - Check associated input for column name, parse, and return it
     */
    submitRename() {
        const newColumnName = this.getInputColumnName();

        // set new name for queue
        this.model.set(
            'inputedRename',
            newColumnName,
            {
                silent: true,
            },
        );

        // Check if new name passes validation before submitting
        if (this.model.isValid()) {
            return this.onSubmitCallback(this.model.cid, newColumnName);
        }
        return undefined;
    },

    // Override ItemView's Close Method
    close() {
        // clear cached values
        this.model.unset(
            'inputedRename',
            {
                silent: true,
            },
        );
        ItemView.prototype.close.call(this);
    },

    templateHelpers() {
        const self = this;
        return {
            // For dynamically getting value for name input
            inputNameValue() {
                return self.options.queuedRename || this.label || this.title;
            },
        };
    },
});
