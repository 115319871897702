/*
 * copied from webseries documentation
 * https://confluence.us-bottomline.root.bottomline.com/display/WS/JavaScript+API+for+Portals
 * var targetOrigin = window.location.protocol + '/' + '/' + window.location.host;
 */
export const PortalInterface = {
    /**
     * postMessage the data when in an iframe
     * @param {Object} data
     */
    send(data) {
        try {
            const message = data;
            // removing leading forward slash that some contexts are adding
            if (message.title?.startsWith('/')) {
                message.title = message.title.slice(1);
            }

            window.parent.postMessage(message, '*');
        } catch (err) {
            // don't do anything right now
        }
    },
};

export default PortalInterface;
