/* eslint-disable import/first */
// Single SPA should be imported before other modules
import '../single-spa-root';
import 'jquery';
import 'jquery-migrate/dist/jquery-migrate.min';
import axios from 'axios';
import { generateUrl, navigate } from '@dbiqe/common';
import MainFrameController from 'common/frame/mainFrameController';
import integrationManager from 'app/integrationManager/integrationManager';
import httpSetup from 'system/httpSetup';
import gluLocale from '@glu/locale';
import appBus from 'system/gluOverride/appBus';
import requireForNative, { modules } from 'system/utilities/requireForNative';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import 'common/util/featureUtil';
import { setConfigParams } from 'common/util/reduxUtil';

// expose a require function for native
requireForNative.setup();

// Handle application in iframe
const mainFrame = new MainFrameController({
    mutationSelector: 'body',
});

mainFrame.register();

window.Bottomline.extMethods.rsaSubmitToken = (callBackToken) => {
    appBus.trigger('extChallenge:rsa:challenge:complete', callBackToken);
};

/*
 * Making these methods available to integrations.
 * Originally these were only exposed when Tealium was enabled.
 * However, other integrations (e.g. Pendo) make use of these same methods.
 */
window.Bottomline.extMethods.axios = axios;
window.Bottomline.extMethods.generateUrl = generateUrl;
window.Bottomline.extMethods.navigate = navigate;
window.Bottomline.extMethods.locale = gluLocale;

httpSetup.config();

let loadingErrorMessage = gluLocale.get('workspace.banking.load.failure');
if (loadingErrorMessage.indexOf('workspace.banking.load.failure') >= 0) {
    loadingErrorMessage = `The ${window.Bottomline.appTitle || ''} application did not load successfully. Please try again in a few minutes. If you continue to receive this message please contact your financial institution.`;
}

// Add uiStartPromise logic for hybrid mobile
if (window.Bottomline?.uiStartResolver) {
    // Pass modules through the promise
    window.Bottomline.uiStartResolver(modules);
}

// Moved most startup logic to second file.
const part2 = import('./main');

const configParamFetch = new Promise((resolve) => {
    serverConfigParams.fetch({
        success(result) {
            integrationManager.callEvent('serverConfigParamsReady', serverConfigParams);
            setConfigParams({ ...serverConfigParams.attributes });
            resolve(result);
        },
        error() {
            // proceed to instantiate host, even if error w/ rest (/getPrelogin)
            resolve();
        },
        isPreLoginFetch: true,
    });
});

Promise.all([part2, configParamFetch]).then(([module, result]) => {
    module.default.initHost(result, mainFrame);
});
export default {};
